import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useCore } from "../../hooks/useCore";
import { useSocial } from "../../hooks/useSocial";
import { useDex } from "../../hooks/useDex";
import { useWallet } from "../../hooks/useWallet";

import { IconActionButton } from "../IconActionButton";
import ReactTooltip from 'react-tooltip';

import classNames from "classnames";

import { ReactComponent as Newspaper} from "../../icons/svgs/newspaper.svg";
import { ReactComponent as Seedling} from "../../icons/svgs/seedling.svg";
import { ReactComponent as Wallet} from '../../icons/svgs/wallet.svg';
import { ReactComponent as Rocket} from "../../icons/svgs/rocket.svg";
import { ReactComponent as Megaphone} from "../../icons/svgs/megaphone.svg";
import { ReactComponent as Flame} from "../../icons/svgs/flame2.svg";
import { ReactComponent as SettingsGear} from "../../icons/svgs/settings.svg";

export function SideBarLeft() {

    let navigate = useNavigate();

    let { sidebarLeft, handleSidebarLeft, settingsPanel, handleSettingsPanel, basePage, listPage } = useCore();
    let { user, adminWallet, walletAddress, dashboardAccess } = useWallet();

    let { twitterUserLists, listSlug } = useSocial();
    let { tokenLists, listView, handleTokenList } = useDex();

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [] );

    const toggleSidebar = () => {
		handleSidebarLeft(!sidebarLeft);
	}

    const toggleSettings = () => {
        handleSettingsPanel(!settingsPanel);
    }

    const switchTokenList = (l) => {
        handleTokenList(l);
    }

    const avatarSrc = user && user.avatarFile ? `${process.env.REACT_APP_MEDIA_URL}${user.avatarFile}?t=${Math.floor(new Date().getTime() / (1000 * 60 * 60))}` : require('../../images/default_profile_avatar.jpg');

    return (
        <div style={{"&::WebkitScrollbar": { display: "none" }}} className={classNames('sidebar left', !sidebarLeft && 'hide')}>

            <IconActionButton
                passStyle={ sidebarLeft ? { top: '5px',right: '5px', transform: 'scale(.9)'} : { background: 'transparent',top: '3px',left: '3px', transform: 'scale(.9)'} }
                type={ sidebarLeft ? "arrow-left" : "arrow-right" }
                passClick={toggleSidebar}
            />

            <div className="main-menu">

                <NavLink to="/" exact="true"  data-tip="Home" data-tip-disable={sidebarLeft} className="main-menu-btn">
                    <div className="main-menu-title">
                        <div style={{fontSize:14}} className="icon-button-home"/>
                        Home
                    </div>
                </NavLink>

                { user && user.username && user.username.length > 2 ? (
                    <NavLink
                    to={`/${user.username}`}
                    exact="true"
                    data-tip="My Profile Page"
                    data-tip-disable={sidebarLeft}
                    style={{paddingLeft:5}}
                    className="main-menu-btn">
                        <div className="main-menu-title">
                            <div style={{marginRight:5}} className="user-avatar"><img src={avatarSrc} /></div>
                            { sidebarLeft ? (
                                <>
                                    {user.name.length > 2 ? user.name : `@${user.username}`}
                                </>
                            ):null}
                            

                        </div>

                    </NavLink>
                ):null}
                

                { walletAddress ? (
                    <NavLink to="/portfolio" exact="true" end data-tip="Portfolio" data-tip-disable={sidebarLeft} className={classNames("main-menu-btn", basePage && listView == "portfolio" && "active")}>
                        <div className="main-menu-title">
                            <div className="svg-icon">
                                <Wallet/>
                            </div>
                            Portfolio

                        </div>
                        { user && user.portfolioTokens !== undefined && (
                            <div className="count-circle" style={user?.portfolioTokens?.length < 1 ? {  filter: 'saturate(0)', opacity: .3 } : {}}>
                                {user.portfolioTokens.length}
                            </div>
                        )}

                    </NavLink>
                ):null}

                <NavLink to="/explore" exact="true"  data-tip="Explore" data-tip-disable={sidebarLeft} className="main-menu-btn">

                    <div className="main-menu-title">
                        {/*<div className="svg-icon">
                            <svg viewBox="0 0 1024 1024" style={{maxHeight:25}} version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <path d="M252 462h524c19.9 0 36-16.1 36-36s-16.1-36-36-36H339.4l59-59c14.1-14.1 14.1-36.9 0-50.9-14.1-14.1-36.9-14.1-50.9 0L230.3 397.2c-8.7 6.6-14.3 17-14.3 28.8 0 19.9 16.1 36 36 36zM802 564c-8.5-8.5-20.3-11.9-31.4-10H252c-19.9 0-36 16.1-36 36s16.1 36 36 36h437.1l-58.5 58.5c-14.1 14.1-14.1 36.9 0 50.9 7 7 16.2 10.5 25.5 10.5s18.4-3.5 25.5-10.5L802 615c14-14.1 14-36.9 0-51z"  />
                            </svg>
                        </div>*/}
                        <div style={{fontSize:14}} className="icon-button-globe"/>
                        Explore
                    </div>

                    {/* <div className="icon-plus">+</div> */}

                    {/*<div className="count-circle">
                        {tokenLists.length}
                    </div>*/}
                </NavLink>

                <NavLink to="/trending" exact="true" end data-tip="Trending" data-tip-disable={sidebarLeft} className={classNames("main-menu-btn", basePage && listView == "trending" && "active")}>
                    <div className="main-menu-title">
                        <div style={{transform:'scale(1.2)'}}className="svg-icon large">
                            <Flame/>
                        </div>
                         Trending

                    </div>
                </NavLink>


                
                <NavLink to="/news" exact="true" end data-tip="News" data-tip-disable={sidebarLeft} className={classNames("main-menu-btn", basePage && listView == "news" && "active")}>
                    <div className="main-menu-title">
                        <div className="svg-icon large">
                            <Newspaper/>
                        </div>
                         News
                    </div>
                </NavLink>

                
                <NavLink to="/calls" exact="true" end data-tip="TG Calls" data-tip-disable={sidebarLeft} className={classNames("main-menu-btn", basePage && listView == "calls" && "active")}>
                    <div className="main-menu-title">
                        <div style={{transform: 'scaleX(-1)'}} className="svg-icon large no-fill">
                            <Megaphone/>
                        </div>
                         Calls

                    </div>
                </NavLink>

                { walletAddress ? (
                    <NavLink to="/newpairs" exact="true" end data-tip="New Pairs" data-tip-disable={sidebarLeft} className={classNames("main-menu-btn", basePage && listView == "newpairs" && "active", dashboardAccess && !dashboardAccess.newpairs && "disabled")}>
                        <div className="main-menu-title">
                            <div className="svg-icon desaturate">
                                <Seedling/>
                            </div>

                            { sidebarLeft ? "New Pairs" : ""}
                            
                        </div>
                    </NavLink>
                ):null}

                
                
               
                { walletAddress ? (
                    <NavLink to="/lists" exact="true"  data-tip="Lists" data-tip-disable={sidebarLeft} className="main-menu-btn">

                        <div className="main-menu-title">
                           
                            <div style={{fontSize:14}} className="icon-button-list"/>
                            Lists
                        </div>

                        {/* <div className="icon-plus">+</div> */}
                        { tokenLists ? (
                            <div className="count-circle">
                                {tokenLists.length}
                            </div>
                        ):null}
                        
                    </NavLink>
                ):null}
                

                

                { tokenLists.map((l,i) => (
                    <NavLink onClick={() => switchTokenList(l)} data-tip={`${l.name} (${l.tokens.length})`} key={l.slug} to={`/list/${l.slug}`} className={classNames("main-menu-btn no-space sub-menu-btn", (basePage=="list" || listView=="list") && listPage == l.slug && "active")}>
                        <div className="flex-row align center">
                            <div className="color-box" style={{marginRight: 7,backgroundColor: l.color,borderColor: l.color}}/>
                            { sidebarLeft && (
                                <>
                                    {l.name}
                                </>
                            )}
                        </div>
                        

                        { sidebarLeft && (
                            <>
                              
                                <div className="count-circle" style={l.tokens.length < 1 ? {  filter: 'saturate(0)', opacity: .3 } : {}}>
                                    {l.tokens.length}
                                </div>
                            </>
                        )}
                    </NavLink>
                ))}

                {/*<NavLink to="/social" exact="true"  data-tip="Social Dashboard" data-tip-disable={sidebarLeft} className="main-menu-btn no-space">

                        <div className="main-menu-title">
                            <div className="icon-button-users"/>
                             Alpha
                        </div>

                </NavLink>

                { Object.keys(twitterUserLists).map((key,i) => (
                    <NavLink data-tip={`${twitterUserLists[key].name}`} key={twitterUserLists[key].slug} to={`/social/list/${twitterUserLists[key].slug}`} className="main-menu-btn no-space sub-menu-btn">
                        <div className="color-box" style={{backgroundColor: twitterUserLists[key].color,borderColor: twitterUserLists[key].color}}/>
                        { sidebarLeft && (
                            <>
                                {twitterUserLists[key].name}
                                <div className="count-circle">
                                    {twitterUserLists[key].twitterUsers.length ? twitterUserLists[key].twitterUsers.length : ""}
                                </div>
                            </>
                        )}

                    </NavLink>
                ))}*/}


                {/*<div onClick={toggleSettings} data-tip="Settings" data-tip-disable={sidebarLeft} className={classNames("main-menu-btn", settingsPanel && "active")}>
                    <div className="main-menu-title">
                        <div className="icon-button-settings"/>

                         Settings
                    </div>
                </div>
*/}
                
                { walletAddress ? (
                    <NavLink to="/settings" exact="true"  data-tip="Settings" data-tip-disable={sidebarLeft} className="main-menu-btn">
                        <div className="main-menu-title">
                            <div className="icon-button-user"/>

                            Settings
                        </div>
                    </NavLink>
                ):null}
                
                

                { adminWallet ? (
                    <NavLink to="/admin" exact="true" end data-tip="Admin" data-tip-disable={sidebarLeft} className={classNames("main-menu-btn", basePage && listView == "admin" && "active")}>
                        <div className="main-menu-title">
                            <div className="svg-icon desaturate large">
                                <SettingsGear/>
                            </div>

                            { sidebarLeft ? "Admin" : ""}
                            
                        </div>
                    </NavLink>
                ):null}
                

                {/*<NavLink to="/tools" exact="true"  data-tip="Tools" data-tip-disable={sidebarLeft} className="main-menu-btn">

                    <div className="main-menu-title">
                        <div className="svg-icon">
                            <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                                <path d="m6.99976 5c0-2.20914 1.79087-4 4.00004-4 .5087 0 .9965.09524 1.4455.26931.1576.06109.2736.19767.3085.36304.0348.16538-.0162.33718-.1357.45669l-1.911 1.91108 1.2929 1.2929 1.9109-1.91095c.1195-.11953.2913-.17057.4567-.1357.1654.03488.3019.15095.363.30856.174.44887.2692.93645.2692 1.44507 0 2.20914-1.7909 4-4 4-.3801 0-.7485-.05316-1.09778-.15264l-5.00648 5.07184c-.71395.7233-1.85874.7909-2.65278.1565-.91412-.7302-1.002-2.0885-.18961-2.9304l5.05084-5.23469c-.06823-.2929-.10423-.59782-.10423-.91061zm4.00004-3c-1.65689 0-3.00004 1.34315-3.00004 3 0 .31548.04853.61868.13817.90305.05508.17476.01016.36564-.11706.49749l-5.24809 5.43906c-.40331.418-.35968 1.0923.09413 1.4548.39419.3149.96252.2814 1.31695-.0777l5.22419-5.2924c.13885-.14066.34752-.18633.53243-.11653.32872.12409.68542.19223 1.05932.19223 1.6568 0 3-1.34315 3-3 0-.0944-.0044-.18772-.0129-.27977l-1.6334 1.63345c-.0937.09377-.2209.14645-.3535.14645s-.2598-.05268-.3536-.14645l-1.99997-2c-.19526-.19526-.19526-.51183-.00001-.7071l1.63368-1.63369c-.0923-.00853-.1858-.01289-.2803-.01289z"/>
                            </svg>
                        </div>
                         Tools
                    </div>
                </NavLink>
*/}




{/*

                <NavLink to="/market" exact="true" data-tip="Market" data-tip-disable={sidebarLeft} className="main-menu-btn no-space">
                    <div className="main-menu-title">
                        <div className="svg-icon">
                            <svg style={{maxHeight:'16px'}} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <path d="M511.97 876.564c-201.007 0-364.534-163.527-364.534-364.538 0-151.06 90.849-284.309 231.454-339.467a25.907 25.907 0 0 1 9.288-1.725 26.225 26.225 0 0 1 24.33 16.727c5.074 13.215-1.49 28.252-14.628 33.52-120.468 47.253-198.338 161.465-198.338 290.945 0 172.277 140.155 312.432 312.427 312.432 129.557-0.005 247.148-81.464 292.608-202.7a26.214 26.214 0 0 1 24.305-16.65 25.9 25.9 0 0 1 9.354 1.745c13.174 5.08 19.953 19.994 15.11 33.255-53.029 141.414-190.23 236.456-341.377 236.456z"/>
                            <path d="M511.97 538.081a26.097 26.097 0 0 1-26.056-26.055V122.609a26.076 26.076 0 0 1 26.05-26.05c229.095 0 415.473 186.377 415.473 415.467a26.086 26.086 0 0 1-26.061 26.055H511.969z m26.055-52.111h336.44l-0.578-6.477c-15.627-175.232-154.148-313.759-329.38-329.385l-6.482-0.578v336.44z" />
                            </svg>

                        </div>
                         Market
                    </div>


                </NavLink>



                <NavLink to="/chart" exact="true"  data-tip="Chart" data-tip-disable={sidebarLeft} className="main-menu-btn no-space">
                    <div className="main-menu-title">
                        <div className="svg-icon">
                            <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M143.06 875.725h782.919c24.3 0 44 19.7 44 44s-19.7 44-44 44H99.059c-24.3 0-44-19.7-44-44V107.303c0-24.3 19.7-44 44-44 24.301 0 44 19.7 44 44v768.422z m344.207-383.337L422.44 707.446c-10.815 35.877-58.663 42.679-79.048 11.237L192.08 485.288c-13.22-20.39-7.406-47.636 12.984-60.856 20.39-13.219 47.637-7.406 60.856 12.985l99.666 153.731 42.953-142.495a44 44 0 0 1 22.714-26.786l179.456-88.232c19.512-9.593 43.106-3.496 55.527 14.35l85.234 122.458 123.205-358.735c7.893-22.983 32.923-35.215 55.906-27.322 22.983 7.893 35.215 32.923 27.322 55.906l-151.38 440.772c-11.701 34.07-57.148 40.41-77.728 10.844l-113.188-162.62-128.34 63.1z"  />
                            </svg>

                        </div>
                         Chart
                    </div>

                </NavLink>



                <NavLink to="/dashboard" exact="true"  data-tip="Dashboard" data-tip-disable={sidebarLeft} className="main-menu-btn no-space">
                    <>
                        <div className="main-menu-title">
                            <div className="icon-button-bar-chart-2"/>
                             Dashboard
                        </div>
                    </>

                </NavLink>

                <NavLink to="/exchange" exact="true"  data-tip="Exchange" data-tip-disable={sidebarLeft} className="main-menu-btn no-space">
                    <div className="main-menu-title">
                        <div className="svg-icon">
                            <svg viewBox="0 0 1024 1024" style={{maxHeight:25}} version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <path d="M252 462h524c19.9 0 36-16.1 36-36s-16.1-36-36-36H339.4l59-59c14.1-14.1 14.1-36.9 0-50.9-14.1-14.1-36.9-14.1-50.9 0L230.3 397.2c-8.7 6.6-14.3 17-14.3 28.8 0 19.9 16.1 36 36 36zM802 564c-8.5-8.5-20.3-11.9-31.4-10H252c-19.9 0-36 16.1-36 36s16.1 36 36 36h437.1l-58.5 58.5c-14.1 14.1-14.1 36.9 0 50.9 7 7 16.2 10.5 25.5 10.5s18.4-3.5 25.5-10.5L802 615c14-14.1 14-36.9 0-51z"  />
                            </svg>
                        </div>
                         Exchange
                    </div>

                </NavLink>



                <NavLink to="/portfolio/assets" exact="true"  data-tip="NFTs" data-tip-disable={sidebarLeft} className="main-menu-btn">
                    <div className="main-menu-title">
                        <div className="svg-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M460.038 4.877h-408.076c-25.995 0-47.086 21.083-47.086 47.086v408.075c0 26.002 21.09 47.086 47.086 47.086h408.075c26.01 0 47.086-21.083 47.086-47.086v-408.076c0-26.003-21.075-47.085-47.085-47.085zm-408.076 31.39h408.075c8.66 0 15.695 7.042 15.695 15.695v321.744h-52.696l-55.606-116.112c-2.33-4.874-7.005-8.208-12.385-8.821-5.318-.583-10.667 1.594-14.039 5.817l-35.866 44.993-84.883-138.192c-2.989-4.858-8.476-7.664-14.117-7.457-5.717.268-10.836 3.633-13.35 8.775l-103.384 210.997h-53.139v-321.744c0-8.652 7.05-15.695 15.695-15.695zm72.437 337.378l84.04-171.528 81.665 132.956c2.667 4.361 7.311 7.135 12.415 7.45 5.196.314 10.039-1.894 13.227-5.879l34.196-42.901 38.272 79.902h-263.815zm335.639 102.088h-408.076c-8.645 0-15.695-7.043-15.695-15.695v-54.941h439.466v54.941c0 8.652-7.036 15.695-15.695 15.695zm-94.141-266.819c34.67 0 62.781-28.111 62.781-62.781 0-34.671-28.111-62.781-62.781-62.781-34.671 0-62.781 28.11-62.781 62.781s28.11 62.781 62.781 62.781zm0-94.171c17.304 0 31.39 14.078 31.39 31.39s-14.086 31.39-31.39 31.39c-17.32 0-31.39-14.079-31.39-31.39 0-17.312 14.07-31.39 31.39-31.39z"/>
                            </svg>

                        </div>
                         NFTs
                    </div>
                </NavLink>




                <NavLink to="/collections" exact="true"  data-tip="Collections" data-tip-disable={sidebarLeft} className="main-menu-btn">
                    <div className="main-menu-title">
                        <div className="icon-button-list"/>
                         Collections
                    </div>
                </NavLink>

                <NavLink to="/experiments" exact="true"  data-tip="Experiments" data-tip-disable={sidebarLeft} className="main-menu-btn">
                <div className="main-menu-title">
                    <div style={{transform: 'rotate(180deg)'}} className="svg-icon">
                        <svg  viewBox="0 0 1600 1600" fill="none" xmlns="http://www.w3.org/2000/svg">

                            <path d="M 1527,88 Q 1583,-1 1548.5,-64.5 1514,-128 1408,-128 H 256 Q 150,-128 115.5,-64.5 81,-1 137,88 l 503,793 v 399 h -64 q -26,0 -45,19 -19,19 -19,45 0,26 19,45 19,19 45,19 h 512 q 26,0 45,-19 19,-19 19,-45 0,-26 -19,-45 -19,-19 -45,-19 h -64 V 881 z M 748,813 476,384 h 712 l -272,429 -20,31 v 37 399 H 768 V 881 844 z" />
                        </svg>

                    </div>
                     Experiments
                </div>
                </NavLink>



*/}




                {/*<NavLink to="/farm" exact className="main-menu-btn">
                    <div className="main-menu-title">
                        <div className="icon-button-file"/>
                         Farm
                    </div>
                    <div className="count-circle">
                        3
                    </div>
                </NavLink>


                <NavLink to="/watch" exact className="main-menu-btn">
                    <div className="main-menu-title">
                        <div className="icon-button-clock"/>
                         Watchlist
                    </div>
                    <div className="count-circle">
                        3
                    </div>
                </NavLink>



                <NavLink to="/misc" exact className="main-menu-btn">
                <div className="icon-code"/>
                <div className="icon-check"/>
                <div className="icon-hamburger"/>
                <div className="icon-message"/>
                <div className="icon-reopen"/>
                <div className="icon-settings"/>
                 Routes
                </NavLink>



                <NavLink to="/communities" exact className="main-menu-btn">
                <div className="icon-button-book"/>
                <div className="icon-button-book-open"/>
                <div className="icon-button-bar-chart"/>
                <div className="icon-button-bar-chart-2"/>
                <div className="icon-button-list"/>
                <div className="icon-button-bell"/>
                 Communities
                </NavLink>

                <NavLink to="/communities" exact className="main-menu-btn">
                <div className="icon-button-bookmark"/>
                <div className="icon-button-briefcase"/>
                <div className="icon-button-calendar"/>
                <div className="icon-button-check"/>
                <div className="icon-button-clock"/>
                 Communities
                </NavLink>*/}

                {/* <NavLink to="/notifications" className="main-menu-btn">
                    <i className="icon-alert"></i> Notifications
                </NavLink>

                <NavLink to="/schedule" className="main-menu-btn">
                    <i className="icon-calendar"></i> Schedule
                </NavLink> */}

                {/*<hr />*/}

                {/*<a
                    href="https://blog.zipcan.com/knowledge-base"
                    target="_blank"
                    className="main-menu-btn"
                >
                    <i className="icon-help"></i> Help
                </a>

                <a
                    href="https://www.zipcan.com/contact"
                    target="_blank"
                    className="main-menu-btn"
                >
                    <i className="icon-message"></i> Contact Us
                </a>

                <hr />*/}

            </div>
        </div>
    );
}
