import React, { useEffect, useState, useRef } from "react";
import { useWeb3React } from '@web3-react/core';

import { useCore } from "../../hooks/useCore";
import { useSocial } from "../../hooks/useSocial";
import { useMarket } from "../../hooks/useMarket";
import { useDex } from "../../hooks/useDex";

import classNames from "classnames";

import * as tools from "../../utils/tools";

import { IconActionButton } from "../IconActionButton";
import { TokenDataTabs } from "./TokenDataTabs";
import { LoaderSpinner } from "../Layout/LoaderSpinner";
import { SwapIcon } from "./SwapIcon";

import Blockies from 'react-blockies';
import { QRCodeSVG } from 'qrcode.react';

import Draggable from "react-draggable";
import { Resizable, ResizableBox } from 'react-resizable';

import { chainMeta, chains, toHex, chainData, swaps } from "../../utils/data";

import sushilogo from "../../icons/logo_sushi.png";
import otsealogo from "../../icons/swaps/logo_otsea.png";
import x7logo from "../../icons/logo_x7.png";
import raydiumlogo from "../../icons/logo_raydium.png";
import jupiterlogo from "../../icons/logo_jupiter.png";
import baseswaplogo from "../../icons/logo_baseswap.png";

import {ReactComponent as UniswapLogoWide} from "../../icons/svgs/logo_uniswap_wide.svg";
import {ReactComponent as UniswapLogo} from '../../icons/svgs/logo_uniswap.svg';
import {ReactComponent as ApeswapLogo} from '../../icons/svgs/logo_apeswap.svg';
import {ReactComponent as BiswapLogo} from '../../icons/svgs/logo_biswap.svg';
import  {ReactComponent as KibaswapLogo} from '../../icons/svgs/logo_kibaswap.svg';

// import { SupportedLocale, SUPPORTED_LOCALES, SwapWidget } from '@uniswap/widgets';
// import '@uniswap/widgets/fonts.css';


// import { SwapWidget } from '@uniswap/widgets/index.js'
// import '@uniswap/widgets/dist/fonts.css';

const TOKEN_LIST = 'https://gateway.ipfs.io/ipns/tokens.uniswap.org';
const UNI = '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984';

export function DexSwap({ token, visible }) {


	let {
		swapVisible,
		handleSwapVisible,
		tbx,
		tby,
		apeSettings,
		loading,
		dexView,
		chainId,
		handleDexView,
		swapExchange,
		swapUrl,
		handleSwapUrl,
		handleSwapExchange
	} = useDex();

	let { coins, coinSymbols } = useMarket();


	const [showQr, setShowQr] = useState();
	const [loadingIframe, setLoadingIframe] = useState(false);
	

	const tb = useRef(null);


	useEffect(() => {

		if(token){
			setLoadingIframe(true);
			let su = swaps[swapExchange].url;

			console.log(swaps[swapExchange])
			if(swaps[swapExchange].custom !== undefined){
				if(swaps[swapExchange].custom == "eth"){
					su += `?inputCurrency=eth&outputCurrency=${token.address}&slippage=${apeSettings.slippage*100}&exactAmount=${apeSettings.amount}&exactField=input`;
				}else{

				}
				su += token.address;
			}else{
				su += `?inputCurrency=eth&outputCurrency=${token.address}&slippage=${apeSettings.slippage*100}&exactAmount=${apeSettings.amount}&exactField=input`;
			}
			
			
			if(swaps[swapExchange].urlTail !== undefined){
				su += swaps[swapExchange].urlTail;
			}

			console.log(su, swaps[swapExchange]);

			handleSwapUrl(su);

		}
		
		
		
	}, [ swapExchange ]);


	useEffect(() => {
		
		
		if(token && !chainMeta[token.chainId].swaps.includes(swapExchange)){
			handleSwapExchange(chainMeta[token.chainId].swaps[0]);
		}
		
		
	}, [ token ]);



	const closeToolbar = () => {
		handleSwapVisible(false);
		handleDexView("list")
	}

	const handleStart = (e) => {

	}

	const handleDrag = (e) => {

	}

	const handleStop = (e) => {

	}

	const changeSwapExchange = (s) => {
		handleSwapExchange(s);
	}

	const toggleQr = () => {
		setShowQr(!showQr);
	}

	// const popOutSwap = () => {
	// 	window.open(`${process.env.REACT_APP_ARTEMIS_URL_IP}/swap/${swapExchange}/test`,"_blank");
	// }

	const popOutSwap = () => {
		window.open(swapUrl,"_blank");
	}

	const handleOnLoad = () => {
		setLoadingIframe(false);
	}

	const pairData = token && token.pair.data !== undefined ? {...token.pair.data} : null;

    const tokenImageUrl = (token && coins[(coinSymbols[token.symbol.toLowerCase()])] !== undefined)
        ? coins[(coinSymbols[token.symbol.toLowerCase()])].image : token && token.image && token.image !== undefined ? token.image : '';
        // : require(`../../icons/logo_chain_${chain.image}.png`);


    return (

		<div className="dex-swap" style={{display: visible ? 'flex' : 'none'}}>

			{ loadingIframe && (
				<LoaderSpinner/>
			)}

			<div className="dex-swap-header token-toolbar-header">
				<div className="coin-links">
					
					{ chainMeta[token.pair.chainId].swaps.map((s,i) => (
							<div onClick={() => changeSwapExchange(s)} data-tip={chainMeta[token.pair.chainId].swapNames[i]} className={classNames("coin-link active-border", s, swapExchange == s && "active")}>
								<div className={classNames("svg-icon",s)}>
									<SwapIcon swap={s}/>
									
								</div>
							</div>
							
					))}
						
				</div>

				<IconActionButton buttonposition="center-right" type="close" passClick={() => closeToolbar()}/>
			</div>

			


			{token && token.pair.data !== undefined && (

				<>
					{swapUrl ? (
						<iframe onLoad={handleOnLoad} src={`${swapUrl}${token.address}`}/>
					):null}
				</>

			)}

			{/* { token && token.pair?.data !== undefined ? (
				<div className="flex-row full">
					<SwapWidget
						// jsonRpcEndpoint={JSON_RPC_URL}
						// tokenList={TOKEN_LIST}
						// provider={provider}
						// locale={locale}
						// onConnectWallet={focusConnectors}
						defaultInputTokenAddress="NATIVE"
						defaultInputAmount="1"
						defaultOutputTokenAddress={UNI}
					/>
				</div>
			):null} */}

			
		


		</div>

    );
}











