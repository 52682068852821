import React, { useEffect, useRef } from "react";

import { useDex } from "../../hooks/useDex";

import classNames from "classnames";

import toast from 'react-simple-toasts';

import { CopyToClipboard } from "react-copy-to-clipboard";

import { chainMeta } from "../../utils/data";

var parse = require('html-react-parser');

export function ContractAddressToolbar({ ca, type="address", subtype=null, chain="ethereum",label="", title=null, addStyle, layout=null, small=null, walletAddress=false }) {



    const copyCa = () => {
        toast(parse('<span className="mute">address</span> copied'));
    }
    const openPage = async () => {

        let t = null;
        if(type == "pair"){
            t = "address";
            if(chainMeta[chain].explorerPairType !== undefined){
                t = chainMeta[chain].explorerPairType;
            }
        }

        if(type == "token"){
            t = "token";
            if(chainMeta[chain].explorerTokenType !== undefined){
                t = chainMeta[chain].explorerTokenType;
            }
        }

        if(type == "address"){
            t = "address";
            if(chainMeta[chain].explorerAddressType !== undefined){
                t = chainMeta[chain].explorerAddressType;
            }
        }
        window.open(
            `${chainMeta[chain].blockExplorer}${t}${t.length ? "/" : ""}${ca}`,
            "_blank"
        )
    }


    const caTruncated = type=="xspace" ? `https://x.com/i/spaces/${ca}` : ca == "nofunction" ? "no owner function" : layout == "full" ? ca : ca?.length ? ca.substring(0,7) + '...' + ca.substring(ca.length - 7) : '';


    return (
        <div style={ addStyle ? addStyle : {}} className={classNames("token-copy-container", ca=="nofunction" && "no-click")}>
            <div className="flex-column">
                {title ? (
                    <h6>{title}</h6>
                ):null}

                <div style={{height:33,maxHeight:33}} className="flex-row align center justify space-between">
                    <CopyToClipboard
                                text={ca}
                                onCopy={copyCa}
                    >
                        <div data-tip={`copy ${label} address`} style={{cursor: "pointer"}} className={classNames("flex-row address", walletAddress && "wallet-address")}>
                            <p style={ label.length ? {} : {width:11,minWidth:11}}className="data-title">{label}</p>
                            <p style={{fontSize:11, color: ca=="nofunction" ? "orange" : "inherit"}} className="data-value currency contract">{caTruncated}</p>
                        </div>
                    </CopyToClipboard>


                        <div className="coin-links small">
                            { ca !== "nofunction" && (
                                <>
                                    <CopyToClipboard
                                                text={ca}
                                                onCopy={copyCa}
                                    >
                                        <div onClick={null} data-tip={`copy ${label} address`} className="coin-link">

                                            <div style={small ? {fontSize:14} : {}} className="icon-button-copy"/>

                                        </div>
                                    </CopyToClipboard>
                                   
                                    <div onClick={openPage} data-tip={`open ${chainMeta[chain].blockExplorerName}`} className="coin-link">
                                        <div style={small ? {fontSize:14} : {}} className="icon-button-external-link"/>
                                    </div>
                                </>
                            )}
                        </div>




                </div>
            </div>


        </div>
    );
}
