import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';

import { ProvideAuth } from "./hooks/useAuth";
import { ProvideCore } from "./hooks/useCore";
import { ProvideWallet } from './hooks/useWallet';

import AppWalletFoundation from "./AppWalletFoundation";

import { CookiesProvider } from 'react-cookie';

import { WagmiProvider, useAccount, useConnect, useDisconnect } from 'wagmi';
import {
    wagmiConfig,
    rainbowConfig,
    authenticationAdapter
} from './utils/wagmi-config';

import {
    QueryClientProvider,
    QueryClient,
} from "@tanstack/react-query";

import {
    RainbowKitProvider,
    RainbowKitAuthenticationProvider,
    darkTheme,
    midnightTheme,
} from '@rainbow-me/rainbowkit';






const queryClient = new QueryClient();

export default function AppFoundation() {
    

    return (
		
        <CookiesProvider>
            <WagmiProvider config={wagmiConfig}>
                <QueryClientProvider client={queryClient}>
                    <ProvideAuth>
                        <ProvideCore>
                            <ProvideWallet>
                                <AppWalletFoundation/>
                            </ProvideWallet>
                        </ProvideCore>
                    </ProvideAuth>
                    
                </QueryClientProvider>
            </WagmiProvider>
        </CookiesProvider>
    );
}
