import React, { useState, useEffect, useContext, createContext } from "react";
import Cookies from "js-cookie";

import { client } from "../../services/api";

import { useWallet } from "../useWallet";
import { useCore } from "../useCore";
import { useDex } from "../useDex";

import { isInArray } from "../../utils/tools";

import toast from 'react-simple-toasts';

import useSocketContext from '../useSocketContext';

var parse = require('html-react-parser');
const socialContext = createContext();

function useProvideSocial() {

    const { walletAddress, user, handleUser } = useWallet();
    const {
        sidebarRight,
        handleSidebarRight,
        handleModalVisible,
        dexSearchModal,
        handleDexSearchModal
    } = useCore();

    const { handleDexSearchPairs, dexSearchPairs, handleScreenClickDims } = useDex();

    const { emit } = useSocketContext();

    // const [loading, setLoading] = useState(!!authToken);
    const [loadingSocial, setLoadingSocial] = useState(false);

    const [socialSearch, setSocialSearch] = useState({
        users: [],
        tokenLists: []
    });

    const [ socialView, setSocialView ] = useState("list");

    const [ cashtags, setCashTags] = useState([]);
    const [ tagRoll, setTagRoll] = useState(null);

    const [ timeline, setTimeline] = useState(null);
    const [ timeFilter, setTimeFilter ] = useState('3');

    const [ follows, setFollows ] = useState({
        lists: [],
        users: []
    });

    const [ followedUsers, setFollowedUsers ] = useState([]);
    const [ followedTokenLists, setFollowedTokenLists ] = useState([]);

    const [ userPage, setUserPage] = useState(null);

    const [ userView, setUserView ] = useState(null);
    const [ userSubView, setUserSubView] = useState(null);
    

    const [exploreUsers, setExploreUsers] = useState([]);

    useEffect(() => {
        if(user && !followedUsers.length){
            fetchFollows(user.walletAddress);
           
            
        }
        
    }, [ user ]);


    async function fetchFollows(wa=null){
        if(wa){
            let tls = await client.getFollows(wa);
            let f = tls.data;
            if(f){
                setFollows({
                    users: f.users !== undefined ? [...f.users] : [],
                    lists: f.lists !== undefined ? [...f.lists] : [],
                })
            }
            return true;
        }else{
            return false;
        }
      
       
    }

    async function fetchExploreUsers(){

        // console.log('get explore users',walletAddress)
        let el = await client.getExploreUsers(walletAddress);

        // console.log(el.data);
        if(el.data && el.ok){
            setExploreUsers(el.data);
        }
        

    }

    async function fetchSocialSearch(str){
        
        

        if(str.length > 0){
            setLoadingSocial(true);
            let s = await client.searchSocial(str);
            console.log(s);

            if(s.ok && s.data){
                setSocialSearch(s.data);
            }
        }else{
            setSocialSearch({
                lists: [],
                users: []
            });
        }

        setLoadingSocial(false);

    
        
    }


    async function searchUsers(q){
        // setLoadingSocial(true);

        // const userSearch = await client.searchTwitterUsers(q);
        // console.log(userSearch)
        // if(userSearch.data.error !== undefined){
        //     toast(userSearch.data.error.detail)
        // }else{
        //     setTwitterSearchedUser(userSearch.data);
        // }
        // // console.log(userSearch);


        // // setTwitterUser(twuser);
        // setLoadingSocial(false);

    }

    async function fetchUserPage(un){
        setLoadingSocial(true);
        console.log('lets load user page', un)
        let up = await client.getUserPage(un);
        console.log(up);
        if(up.ok && up.data){
            if(userPage && userPage.username == "data"){

            }
            setUserPage(up.data);
        }
        
        // const convo = await client.getTwitterPostConversation(id);

        setLoadingSocial(false);
    }

    async function fetchConversation(id){
        setLoadingSocial(true);

        const convo = await client.getTwitterPostConversation(id);

        setLoadingSocial(false);
    }


    async function handleAddTwitterUsers(u){
        setLoadingSocial(true);
        let d = {
            wa: walletAddress,
            un: u,
            addToList: true
        }

        const addu = await client.addTwitterUsers(d);

        if(!addu.data){

        }else{
            if(addu.data.status == 500){
                toast('user has no tweets');
            }else if(addu.data.success){
                handleUser(addu.data.user);
                console.log(addu.data.user);
                for(var i=0;i<addu.data.usernames.length;i++){
                    toast(addu.data.usernames[i] + ' added');
                }

            }else{
                toast(addu.data.msg);
            }
        }

        console.log(addu)
        setLoadingSocial(false);
        // setProfile(p.data);
    }

    async function handleAddToTwitterUserList(l, twu){
        let d = {
            wa: walletAddress,
            lid: l._id,
            twuid: twu._id,
        }
        // let listupdate = await client.addTwitterUserToList(d);
        // console.log(listupdate);
        // toast(`added to ${listupdate.data.name}`);
        // fetchTwitterUserLists(walletAddress);

    }

    async function removeFromTwitterUserList(twu){
        let d = {
            wa: walletAddress,
            // lslug: listSlug,
            twuid: twu._id,
        }
        let twua = [];
        // for(var i=0;i<twitterUsers.length;i++){
        //     if(twitterUsers[i]._id !== twu._id){
        //         twua.push(twitterUsers[i])
        //     }
        // }
        // setTwitterUsers(twua);
        // let listupdate = await client.removeTwitterUserFromList(d);
        // console.log(listupdate);
        // toast(`removed from ${listupdate.data.name}`);
        // fetchTwitterUserLists(walletAddress);

    }


    async function updateTwitterUserList(ud){
        let lu = await client.updateTwitterUserList(ud);
        console.log(lu);
    }



    const handleTagRoll = (v) => {
        setTagRoll(v);
    }



    const handleTimeFilter = (v) => {
        setTimeFilter(v);
    }

    const handleTimeline = (v) => {
        setTimeline(v);
    }

    const handleUserView = (v) => {
        setUserView(v);
    }

    const handleUserSubView = (v) => {
        setUserSubView(v);
    }

    


    const toggleFollow = async (d) => {
        let af = await client.editFollows(d);

        let ftype = d.ftype;
        if(ftype == "tokenList"){
            ftype = "list";
        }


        if(d.atype == "add"){
            toast(parse(`<span className="mute">${ftype}</span> followed`));
        }else if(d.atype == "remove"){
            toast(parse(`<span className="mute">${ftype}</span> unfollowed`));
        }
        // console.log(d)
        fetchFollows(user.walletAddress);
    }




    // Return the user object and auth methods
    return {

        toggleFollow,
        follows,


        socialView,
        cashtags,
        tagRoll,
        handleTagRoll,
        timeline,
        handleTimeline,
        timeFilter,
        handleTimeFilter,
        searchUsers,

        socialSearch,
        fetchSocialSearch,
        loadingSocial,

        exploreUsers,
        fetchExploreUsers,

        fetchUserPage,
        userPage,
        userView,
        handleUserView,
        userSubView,
        handleUserSubView
        
  
    };
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideSocial({ children }) {
    const social = useProvideSocial();
    return <socialContext.Provider value={social}>{children}</socialContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useSocial = () => {
    return useContext(socialContext);
};
