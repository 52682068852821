import { usdcAddress, zeroxdexAddress } from "./tools";

export const listViews = [
	"trending",
	"news",
	"calls",
	"portfolio",
	"newpairs",
	"settings",
	"lists",
	"explore"
]

export const restrictedUsernames = [
    'account',
    'activity',
    'admin',
    'analytics',
    'app',
    'archive',
    'auth',
    'avatar',
    'billing',
    'blog',
    'bot',
    'browse',
    'cart',
    'channel',
    'chat',
    'comment',
    'community',
    'config',
    'connect',
    'contact',
    'dashboard',
    'data',
    'delete',
    'details',
    'discuss',
    'docs',
    'email',
    'explore',
    'feed',
    'file',
    'group',
    'help',
    'home',
    'index',
    'info',
    'information',
    'invite',
    'list',
    'login',
    'logout',
    'media',
    'message',
    'metrics',
    'moderator',
    'notifications',
    'page',
    'password',
    'post',
    'profile',
    'search',
    'settings',
    'share',
    'signup',
    'status',
    'support',
    'token',
    'upload',
    'user',
    'verify',
    'welcome',
    'widget'
];


export const fishEmojis = ['🦐','🐟','🐬','🦈','🐋'];

export const heroImages = [
	'iceland_mars.jpg',
	'iceland_glacier.jpg',
	'iceland_glacier2.jpg',
	'iceland_mirror.jpg',
	'himalayan_heaven.jpg',
	'himalayan_cauldron.jpg',
	'himalayan_glaciers.jpg',
	'himalayan_kniferidge.jpg',
	'himalayan_towers.jpg',
	'himalayan_ridge.jpg',
	'himalayan_peak.jpg',
	// 'iceland_road.jpg',
	'iceland_ice.jpg',
	// 'iceland_scape.jpg',
	// 'craters_moon.jpg',
	'wallowas.jpg',
	// 'easternoregon.jpg'
]


export const tgSnipersLogos = [
	"sigma",
	"banana",
	"uni",
	"maestro",
	"bullx",
	"trojan",
	"mevx",
	"ton",
	"sui",
	"tronsnipe"
];

export const walletSignMessage = `Welcome to 0xdex!\n
By signing this message, you accept the 0xDex Terms of Service at (https://www.0xdex.ai/tos). We are big believers in protecting your data, assets and privacy.

Wallet Address:
[wa]

Nonce:
[nonce]`;


export const dashboardAccessTiers = {
	"0":{
		threshold: 0,
		id: "free",
		name: "Free",
		lists: 1,
		newpairs: false,
		notifications: false,
		speed: 1,
		staging: false,
		verified: false
	},
	"1":{
		threshold: .01,
		id: "degen",
		name: "Degen",
		lists: 3,
		newpairs: true,
		notifications: false,
		speed: 2,
		staging: false,
		verified: true
	},
	"2":{
		threshold: .1,
		id: "degenpro",
		name: "Degen Pro",
		lists: 10,
		newpairs: true,
		notifications: true,
		speed: 3,
		staging: true,
		verified: true
	},
	"3":{
		threshold: .5,
		id: "gigachad",
		name: "Gigachad Pro +",
		lists: 50,
		newpairs: true,
		notifications: true,
		speed: 4,
		staging: true,
		verified: true
	},
}

export const dashboardFeatures = [
    { name: "Trending", free: "check",degen:"check", degenpro: "check", gigachad: "check" },
    { name: "News", free: "check",degen:"check", degenpro: "check", gigachad: "check" },
    { name: "Calls", free: "check",degen:"check", degenpro: "check", gigachad: "check" },
    { name: "Explore", free: "check",degen:"check", degenpro: "check", gigachad: "check" },
	{ name: "Transact", free: "check",degen:"check", degenpro: "check", gigachad: "check" },
    { name: "Portfolio", free: false, degen:"check", degenpro: "check", gigachad: "check" },
	{ name: "Publish", free: false, degen:"check", degenpro: "check", gigachad: "check" },
    { name: "Portfolio", free: false, degen:"check", degenpro: "check", gigachad: "check" },
    { name: "Lists", free: false, degen:"3", degenpro: "10", gigachad: "unlimited" },
	{ name: "New Pairs", free: false, degen:"check", degenpro: "check", gigachad: "check" },
    { name: "Notifications", free: false, degen:false, degenpro: "check", gigachad: "check" },
    { name: "Promotions", free: false, degen:false, degenpro: false, gigachad: "check" },
    { name: "API Access", free: false, degen:false, degenpro: false, gigachad: "check" },
    { name: "Staging Access", free: false, degen:false, degenpro: false, gigachad: "check" },
    { name: "Update Speed", free: "🐢", degen:"🐈", degenpro: "🏎️", gigachad: "🚀" },
	{ name: "Verified", free: false, degen:"bluecheck", degenpro: "bluecheck", gigachad: "bluecheck" },
];

export const bridges = {
	"allbridge":{
		name:"Allbridge",
		description:"Transfer stablecoins between EVM & non-EVM chains.",
		url:"https://core.allbridge.io/",
		docsUrl:"https://docs-core.allbridge.io/",
		webUrl:"https://allbridge.io/",
		colorPrimary:"rgb(90,172,143)",
		chains: ["all"],
		types: ["bridge"]
	},
	"orbiter":{
		name:"Orbiter Finance",
		description:"Orbiter Finance enables cross-rollup transactions of Ethereum/Bitcoin native assets.",
		url:"https://www.orbiter.finance/",
		docsUrl:"https://docs.orbiter.finance/welcome/bridge-protocol",
		webUrl:"https://www.orbiter.finance/",
		colorPrimary:"#df2e2d",
		colorSecondary:"#222222",
		chains: ["all"],
		types: ["bridge"]
	},
	"debridge":{
		name:"deBridge",
		description: "Bridging, at lightspeed. Because DeFi doesn't wait.",
		url:"https://app.debridge.finance/?address=&inputChain={inputChain}&outputChain={outputChain}&inputCurrency={inputCa}&outputCurrency={outputCa}&amount={amount}&dlnMode=simple",
		docsUrl:"https://docs.debridge.finance/",
		webUrl:"https://debridge.finance/",
		colorPrimary:"rgb(251,255,58)",
		chains: ["all"],
		types: ["bridge"]
	},
	"mayan":{
		name:"Mayan",
		description: "Cross-chain Auction Protocol. Swap with one click at the best rate via Solana.",
		url:"https://swap.mayan.finance/",
		docsUrl:"https://docs.mayan.finance/",
		webUrl:"https://mayan.finance/",
		colorPrimary:"rgb(9,39,64)",
		chains: ["all"],
		types: ["bridge"]
	}
}

export const scanners = {
	

	// soul scanner
	// pirb view bot
	// devselling bot
	// syrax token scanner
	// callanalyserbot
}

export const swaps = {

	"chart":{
		
		"dexscreener":{
			name:"DexScreener",
			url: `https://dexscreener.com/{chain}/{ta}?theme={theme}&embed=1&info=0`,
			chains:["all"],
			types:["chart"]
		},
		"birdeye":{
			name:"birdeye",
			url:"https://www.birdeye.so/tv-widget/{ta}?chain={chain}&viewMode=pair&chartInterval=15&chartType=Candle&chartLeftToolbar=show&theme={theme}",
			noAppendToken: true,
			docsUrl:"",
			webUrl:"",
			chains: ["ethereum","base","solana","sui"],
			types:["chart"]
		},
		"dextools":{
			name:"DexTools",
			url:"https://www.dextools.io/app/{chain}/pair-explorer/",
			docsUrl:"",
			webUrl:"",
			chains: ["ethereum","solana","base"],
			types:["chart"]
		},
		"defined":{
			name: "Defined",
			url: "https://www.defined.fi/{chainShort}/{ta}?quoteToken=token0",
			noAppendToken: true,
			docsUrl:"",
			webUrl:"",
			chains: ["ethereum","solana","base","tron"],
			types:["chart"]
		},
		"geckoterminal":{
			name:"GeckoTerminal",
			url:"https://www.geckoterminal.com/{chain}/pools/",
			docsUrl:"",
			webUrl:"",
			chains:["all"],
			types:["chart"]
		}

	},

	// scan

	"scan": {
		"etherscan":{
			name: "etherscan",
			url: "https://etherscan.io/",
			docsUrl:"",
			webUrl:"",
			x:"",
			chains:["ethereum"],
			nativeScan: true,
			types:["scan"]
		},
		"basescan":{
			name: "basescan",
			url: "https://basescan.org/",
			docsUrl:"",
			webUrl:"",
			x:"",
			chains:["base"],
			nativeScan: true,
			types:["scan"]
		},
		"solscan":{
			name: "solscan",
			url:"https://solscan.io/",
			docsUrl:"",
			webUrl:"",
			x:"",
			chains:["solana"],
			nativeScan: true,
			types:["scan"]
		},
		"solanafm":{
			name:"SolanaFM",
			url:"https://solana.fm/address/{ta}",
			noAppendToken: true,
			docsUrl:"",
			webUrl:"",
			x:"",
			chains:["solana"],
			// nativeScan: true,
			types:["scan"]
		},
		"pumpfun":{
			name:"pump.fun",
			url:"https://pump.fun/coin/",
			docsUrl:"",
			webUrl:"",
			x:"",
			chains:["solana"],
			types:["scan"]
		},
		"tronscan":{
			name: "tronscan",
			url: "https://tronscan.org/#/",
			docsUrl:"",
			webUrl:"",
			x:"",
			chains:["tron"],
			nativeScan: true,
			types:["scan"]
		},
		"tonviewer":{
			name: "tonviewer",
			url: "https://tonviewer.com/",
			docsUrl:"",
			webUrl:"",
			x:"",
			chains:["ton"],
			nativeScan: true,
			types:["scan"]
		},
		"suiscan":{
			name: "Suiscan",
			url: "https://suiscan.xyz/",
			docsUrl:"",
			webUrl:"",
			x:"",
			chains:["sui"],
			nativeScan: true,
			types:["scan"]
		},
		
		"honeypot":{
			name: "honeypot.is",
			url:"https://honeypot.is/{chain}?address=",
			docsUrl:"",
			x:"https://x.com/ishoneypot",
			webUrl:"https://honeypot.is/ethereum",
			chains:["ethereum","base","bsc"],
			types:["scan"]
		},
		"tokensniffer":{
			name:"TokenSniffer",
			url:"https://tokensniffer.com/token/{chainShort}/",
			docsUrl:"",
			webUrl:"https://www.soliduslabs.com/",
			x:"https://x.com/solidus_labs",
			chains:["ethereum","base"],
			types:["scan"]
		},
		"solsniffer":{
			name:"Solsniffer",
			url:"https://solsniffer.com/scanner/",
			docsUrl:"https://solsniffer.gitbook.io/solsniffer-user-guide",
			webUrl:"https://solsniffer.com/",
			x:"https://x.com/solanasniffer",
			chains:["solana"],
			types:["scan"]
		},
		
		"bubblemaps":{
			name: "bubblemaps",
			url: "https://app.bubblemaps.io/{chainShort}/token/",
			docsUrl: "",
			webUrl: "https://bubblemaps.io/",
			x: "",
			chains:["ethereum","base","solana"],
			types:['scan']
		},
		"goplus": { 
			name: "goplus",
			url:"https://gopluslabs.io/token-security/{chainId}/",
			urlSol:"https://gopluslabs.io/token-security/solana/",
			urlSui:"https://gopluslabs.io/token-security/sui/",
			docsUrl:"",
			webUrl:"",
			chains:["ethereum","base","tron","sui","solana"],
			types:["scan"]
		},
		"apespace":{
			name: "ApeSpace",
			url: "https://apespace.io/honeypot/",
			docsUrl:"",
			webUrl:"",
			x:"",
			chains:["all"],
			types:["scan"]
		},
		"coinmarketcap":{
			name:"CoinMarketCap",
			url: "https://coinmarketcap.com/dexscan/{chain}/{pa}",
			noAppendToken: true,
			docsUrl:"",
			webUrl:"",
			x:"",
			chains:["all"],
			types:["scan"]
		},
		"defi": {
			name: "De.fi scan",
			url:"https://de.fi/scanner/contract/",
			urlTail:"?chainId={chainShort}",
			docsUrl:"",
			webUrl:"",
			chains:["ethereum","base","tron","sui","solana"],
			types:["scan"]
		},
		"rugcheck":{
			name:"Rugcheck",
			url:"https://rugcheck.xyz/tokens/",
			docsUrl:"",
			webUrl:"",
			chains:["solana"],
			types:["scan"]
		},
		"soulscan":{
			name:"SoulScanner",
			url:"https://t.me/soul_scanner_bot?start=",
			docsUrl:"",
			webUrl:"https://www.soulsniper.xyz/scanner",
			x:"https://x.com/soulscannerbot",
			chains:["solana"],
			types:["scan","tg"]
		},
		"phanes": { 
			name: "Phanes",
			url:"https://t.me/phanes_bot?start=",
			docsUrl:"https://docs.phanes.bot",
			webUrl:"https://docs.phanes.bot",
			chains:["ethereum","base","tron","sui","solana","ton"],
			types:["scan","tg"]
		},
		"pirb": { 
			name: "PIRB",
			url:"https://t.me/pirbviewbot",
			docsUrl:"https://t.me/PIRBViewGuide",
			webUrl:"https://pirb.tech/",
			chains:["ethereum","base","tron","sui","solana","ton"],
			noAppendToken: true,
			types:["scan","tg"]
		},
		"pirbpro": { 
			name: "PIRB Pro",
			url:"https://t.me/pirbviewprobot",
			docsUrl:"https://t.me/PIRBViewGuide",
			webUrl:"https://pirb.tech/",
			chains:["ethereum","base","tron","sui","solana","ton"],
			noAppendToken: true,
			types:["scan","tg"]
		},
		"devselling":{
			name: "IsDevSelling",
			url:"https://t.me/is_dev_selling_bot",
			docsUrl:"https://gitbook.devsellingbot.com/",
			webUrl:"https://devsellingbot.com/",
			chains:["ethereum","base","solana"],
			noAppendToken: true,
			types:["scan","tg"]
		},
		"syrax": { 
			name: "Syrax",
			url:"https://t.me/SyraxScannerBot?start=",
			docsUrl:"https://t.me/PIRBViewGuide",
			webUrl:"https://pirb.tech/",
			chains:["solana"],
			noAppendToken: true,
			types:["scan","tg"]
		},
		"callanalyser":{
			name:"Call Analyser",
			url:"https://t.me/CallAnalyserBot?start=",
			docsUrl:"",
			webUrl:"https://x.com/callanalyser0x",
			chains:["ethereum","base","tron","sui","solana","ton"],
			types:["scan","tg"]
		},
		"cryptach": {
			name: "Cryptach scan",
			url: "https://cryptach.org/scan/",
			urlTail: "/{chainShort}",
			docsUrl:"",
			webUrl:"https://cryptach.org/",
			chains:["ethereum","base","tron","sui","solana","ton"],
			types:["scan"]
		}
	},

	// swaps

	"swap":{
		
		"flooz": {
			name: "Flooz",
			url:"https://flooz.xyz/swap?network={chainSlang}&tokenAddress=",
			docsUrl:"",
			webUrl:"",
			chains: ['ethereum','base','solana'],
			types:["swap","tg"]
		},
		"birdeye": {
			name: "birdeye",
			url: "https://www.birdeye.so/token/",
			docsUrl:"",
			webUrl:"",
			urlTail:"?chain={chain}",
			chains: ["ethereum","base","solana","sui"],
			types: ["swap"]
		},
		"jupiter": {
			name: "Jupiter",
			url: "https://jup.ag/swap/SOL-",
			docsUrl:"",
			webUrl:"",
			chains: ["solana"],
			types: ["swap"]
		},
		"raydium": {
			name: "Raydium",
			url: "https://raydium.io/swap/?inputMint=sol&outputMint=",
			docsUrl:"",
			webUrl:"",
			chains: ["solana"],
			types: ["swap"]
		},
		"uniswap": {
			name: "Uniswap",
			url: "https://app.uniswap.org/#/swap",
			docsUrl:"",
			webUrl:"",
			chains: ['ethereum','polygon','optimism','arbitrum','gnosis','moonbeam'],
			custom: "eth",
			types: ["swap"]
		},
		"sushi": {
			name: "Sushi",
			url: "https://www.sushi.com/ethereum/swap?token0=NATIVE&token1=",
			docsUrl:"",
			webUrl:"",
			chains: ['ethereum','fantom','bsc','polygon','avalanche','arbitrum','optimism'],
			types: ["swap"]
		},
		"sun": {
			name: "Sun",
			url: "https://sun.io/#/sun_swap/v2?t0=T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb&t1=",
			docsUrl:"",
			webUrl:"",
			chains: ['tron'],
			types: ["swap"]
		},
		"bluemove": {
			name: "Blue Move",
			url: "https://dex.bluemove.net/swap/0x2::sui::sui/",
			docsUrl:"",
			webUrl:"",
			chains: ['sui'],
			custom: "sui",
			types: ["swap"]
			// urlTail: "/0x2::sui::sui"
		},
		"stonfi": {
			name: "stonfi",
			url: "https://app.ston.fi/swap?chartVisible=false&ft=TON&tt=",
			docsUrl:"",
			webUrl:"",
			chains: ['ton'],
			types: ["swap"]
		},

		// start tg bots
		
		"sigma": {
			name:"Sigma",
			url: "https://t.me/Sigma_buyBot?start=x1788888347-",
			docsUrl:"",
			webUrl:"",
			chains: ["ethereum","base"],
			types: ["tg"]
		},
		"bonk": {
			name:"Bonkbot",
			url: "https://t.me/bonkbot_bot?start=ref_2m4zz_ca_",
			docsUrl:"https://docs.bonkbot.io/",
			webUrl:"https://bonkbot.io/",
			chains: ["solana"],
			types: ["tg"]
		},
		"trojan": {
			name: "Trojan",
			url: "https://t.me/solana_trojanbot?start=r-jbskis-",
			docsUrl: "https://docs.trojan.app/",
			webUrl: "https://trojan.com/",
			chains: ["solana"],
			types:["tg"]
		},
		"bloom": {
			name:"Bloom",
			url: "https://t.me/BloomSolana_bot?start=ref_KXWMZTJ1U8_ca_",
			docsUrl:"https://solana.bloombot.app/",
			webUrl:"https://solana.bloombot.app/",
			chains: ["solana"],
			types: ["tg"]
		},
		"nova": {
			name:"Novabot",
			url: "https://t.me/novasol_bot?start=ref-qbpQA-buy-",
			docsUrl:["https://docs.tradeonnova.io/", "https://docs.novabots.io/"],
			webUrl:"",
			chains: ["solana"],
			types: ["tg"]
		},
		"soulsniper":{
			name:"SoulSniper",
			url:"https://t.me/soul_sniper_bot?start={ta}",
			noAppendToken: true,
			docsUrl:"",
			webUrl:"https://www.soulsniper.xyz/sniper",
			x:"https://x.com/soulscannerbot",
			chains:["solana"],
			types:["tg"]
		},
		"soltradingbot":{
			name:"SolTradingBot",
			url: "https://t.me/SolTradingBot?start={ta}-kj34WK5TB",
			noAppendToken: true,
			docsUrl: "https://soltradingbot.gitbook.io/docs",
			webUrl: "https://soltradingbot.com/",
			chains: ["solana"],
			types:["tg"]
		},
		"tradewiz": {
			name: "TradeWiz",
			url: "https://t.me/TradeWiz_Solbot?start=r-3UOY5CHTC5-",
			docsUrl:"https://tradewiz.gitbook.io/tradewiz",
			webUrl:"https://www.fastradewiz.com/",
			x:"https://x.com/tradewiz_bot",
			chains: ["solana"],
			types:["tg"]
		},
		"shuriken": {
			name: "Shuriken",
			url: "https://t.me/ShurikenTradeBot?start=qt-moon33-",
			docsUrl:"",
			webUrl:"",
			chains:["solana"],
			types:["tg"]
		},
		"banana": {
			name:"BananaGun",
			// tgUrl: "https://t.me/BananaGunSniper_bot?start=ref_helloworld123_"
			url: "https://t.me/BananaGunSniper_bot?start=snp_0xdex_",
			urlSol: "https://t.me/BananaGun_bot?start=snp_helloworld_",
			docsUrl:"",
			webUrl:"",
			chains: ["ethereum","base","solana"],
			types: ["tg"]
		},
		"maestro": {
			name:"Maestro",
			url: "https://t.me/MaestroSniperBot?start=",
			docsUrl:"",
			webUrl:"",
			chains: ["ethereum","base","solana","tron","ton"],
			types: ["tg"]
		},
		"chainswap": {
			name: "ChainSwapBot",
			url: "https://t.me/Cswap_solbot?start={ta}-QV15DB",
			noAppendToken: true,
			docsUrl:"",
			webUrl:"",
			chains: ["solana"],
			types: ["tg"]
		},
		
		"uni": {
			name:"Unibot",
			url: "https://t.me/unibotsniper_bot?start=helloworld123-",
			docsUrl:"",
			webUrl:"",
			chains: ["ethereum","base"],
			types: ["tg"]
		},
		"mevxbot": {
			name: "MevXBot",
			url: "https://t.me/MevxTradingBot?start=",
			docsUrl:"",
			webUrl:"",
			chains: ["ethereum","base","tron","solana"],
			types: ["tg"]
		},

		"mevx": {
			name: "MevX",
			url: "https://mevx.io/{chain}/",
			docsUrl:"",
			webUrl:"",
			chains: ["ethereum","base","tron","solana"],
			types: ["dashboard","tg"]
		},



		
		"photon": {
			name: "Photon",
			url: "https://photon-sol.tinyastro.io/lp/",
			docsUrl:"",
			webUrl:"",
			chains: ["solana"],
			types: ["dashboard","tg"]
		},
		"bullx": {
			name: "BullX",
			url: "https://bullx.io/terminal?chainId={chainId}&address=",
			docsUrl:"",
			webUrl:"",
			chains: ["ethereum","base","solana"],
			types: ["dashboard","tg"]
		},
		"bullxneo": {
			name: "BullX Neo",
			url: "https://neo.bullx.io/terminal?chainId={chainId}&address=",
			docsUrl:"",
			webUrl:"",
			notes:"https://neo.bullx.io/p/easyconnect",
			tag: "NEO",
			tagColor: "rgb(59,156,110)",
			chains: ["ethereum","base","solana"],
			types: ["dashboard","tg"]
		},
		"gmgn": {
			name: "gmgn",
			url: "https://gmgn.ai/{chainShort}/token/",
			docsUrl:"",
			webUrl:"",
			chains: ["ethereum","base","solana","tron"],
			types: ["dashboard","tg"]
		},
		"tronsnipe": {
			name: "Tron Snipe",
			url: "https://t.me/Tronsnipebot?start=ref_BCqFjNZYsz_ca_",
			docsUrl:"",
			webUrl:"",
			chains: "tron",
			types: ["tg"]
		},

		"ton": {
			name:"TonTradingBot",
			url:"https://t.me/tontrade?start=AMe9qpTq-",
			docsUrl:"",
			webUrl:"",
			chains: "ton",
			types: ["tg"]
		},
		"sui": {
			name:"Sui Sniper",
			url: "https://t.me/SuiSniperBot?start=",
			docsUrl:"",
			webUrl:"",
			urlTail: "-C3AFOP",
			chains: "sui",
			types: ["tg"]
		}
	},

	"bridge":{
		"allbridge":{
			name:"Allbridge",
			description:"Transfer stablecoins between EVM & non-EVM chains.",
			url:"https://core.allbridge.io/",
			docsUrl:"https://docs-core.allbridge.io/",
			webUrl:"https://allbridge.io/",
			noAppendToken: true,
			colorPrimary:"rgb(90,172,143)",
			chains: ["all"],
			types: ["bridge"]
		},
		"orbiter":{
			name:"Orbiter",
			description:"Orbiter Finance enables cross-rollup transactions of Ethereum/Bitcoin native assets.",
			url:"https://www.orbiter.finance/",
			docsUrl:"https://docs.orbiter.finance/welcome/bridge-protocol",
			webUrl:"https://www.orbiter.finance/",
			noAppendToken: true,
			colorPrimary:"#df2e2d",
			colorSecondary:"#222222",
			chains: ["all"],
			types: ["bridge"]
		},
		"debridge":{
			name:"deBridge",
			description: "Bridging, at lightspeed. Because DeFi doesn't wait.",
			url: "https://app.debridge.finance/?inputChain=1&outputChain=7565164",
			// url:"https://app.debridge.finance/?address=&inputChain={inputChain}&outputChain={outputChain}&inputCurrency={inputCa}&outputCurrency={outputCa}&amount={amount}&dlnMode=simple",
			docsUrl:"https://docs.debridge.finance/",
			webUrl:"https://debridge.finance/",
			noAppendToken: true,
			colorPrimary:"rgb(251,255,58)",
			chains: ["all"],
			types: ["bridge"]
		},
		"mayan":{
			name:"Mayan",
			description: "Cross-chain Auction Protocol. Swap with one click at the best rate via Solana.",
			url:"https://swap.mayan.finance/",
			docsUrl:"https://docs.mayan.finance/",
			webUrl:"https://mayan.finance/",
			noAppendToken: true,
			colorPrimary:"rgb(9,39,64)",
			chains: ["all"],
			types: ["bridge"]
		},
	}

	
}



export const sfx = {
	"null": {
		name: ": none :"
	},
	"sonar":{
		name: "Sonar",
	},
	"bell":{
		name: "Bell",
	},
	"elevator":{
		name: "Elevator",
	},
	"store":{
		name: "Store",
	},
	"bubble":{
		name: "Bubble",
	},
	"message":{
		name: "Message",
	},
	"notification":{
		name: "Notification",
	},
	"pen":{
		name: "Pen",
	},
	"space":{
		name: "Space",
	},
	"static":{
		name: "Static",
	},
	"discord":{
		name: "Discord"
	},
	"owl_eastern_screech":{
		name: "Eastern Screech Owl"
	},
	"owl_long_eared":{
		name: "Long Eared Owl"
	},
	"owl_barred":{
		name: "Barred Owl"
	},
	"owl_snowy":{
		name: "Snowy Owl"
	},
	"ape01":{
		name: "Ape 1"
	},
	"gorilla01":{
		name: "Ape 2"
	},
	"gorilla03":{
		name: "Ape 3"
	}
}

export const swapsOld = {
	flooz: {
		url:"https://flooz.xyz/swap?network={chainSlang}&tokenAddress={ta}",
		chains: ['ethereum','base','solana'],
	},
	jupiterswap: {
		url: "https://jup.ag/swap/SOL-",
		chains: ["solana"],
		custom: "jupiter"
	},
	raydiumswap: {
		url: "https://raydium.io/swap/?inputMint=sol&outputMint=",
		chains: ["solana"],
		custom: "raydium"
	},
	uniswap: {
		url: "https://app.uniswap.org/#/swap",
		chains: ['ethereum','polygon','optimism','arbitrum','gnosis','moonbeam'],
		custom: "eth",
	},
	sushiswap: {
		url: "https://app.sushi.com/swap",
		chains: ['ethereum','fantom','bsc','polygon','avalanche','arbitrum','optimism'],
	},
	sunswap: {
		url: "https://sun.io/#/sun_swap/v2?t0=T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb&t1=",
		chains: ['tron'],
		custom: "tron"
	},
	bluemove: {
		url: "https://dex.bluemove.net/swap/0x2::sui::sui/",
		chains: ['sui'],
		custom: "sui",
		// urlTail: "/0x2::sui::sui"
	},
	stonfi: {
		url: "https://app.ston.fi/swap?chartVisible=false&ft=TON&tt=",
		chains: ['ton'],
		custom: "ton"
	},
	
	x7swap: {
		url: "https://app.x7.finance/#/swap/",
		chains: ['ethereum']
	},

	// otsea: {
	// 	url: "https://otsea.io/mini-market?network=mainnet&transparent=true&tokenAddress=",
	// 	chains: ['ethereum'],
	// 	custom: "addToken",
	// },
	// pancakeswap: {
	// 	url: "https://pancakeswap.finance/swap",
	// 	chains: ['bsc'],
	// },
	// apeswap: {
	// 	url: "https://apeswap.finance/swap",
	// 	chains: ['ethereum','polygon','bsc']
	// },
	// biswap: {
	// 	url: "https://exchange.biswap.org/#/swap",
	// 	chains: ['bsc']
	// },
	// spookyswap: {
	// 	url: "https://spooky.fi/#/swap",
	// 	chains: ['fantom'],
	// },
	// quickswap: {
	// 	url: "https://quickswap.exchange/#/swap",
	// 	chains: ['polygon'],
	// },
	// traderjoe: {
	// 	url: "https://traderjoexyz.com/avalanche/trade",
	// 	chains: ['avalanche'],
	// },
	// oneinchswap: {
	// 	url: "https://app.1inch.io/#/1/unified/swap",
	// 	chains: ['ethereum','polygon','bsc']
	// },
	// shibaswap: {
	// 	url: "https://shibaswap.com/#/swap",
	// 	chains: ['ethereum','polygon'],
	// },
	// dogeswap: {
	// 	url: "https://www.doge-swap.com/exchange/#/swap",
	// 	chains: ['ethereum','polygon'],
	// },
	

}
// ▴
// ▾
// ▲
// ▼
// worthy chains to look at
// optimism, arbitrum

// url to post a tweet
// https://twitter.com/intent/tweet?text=text%20goes%20here.%20%24byte%20&url=http%3A%2F%2Furl%20goes%20here&hashtags=hashtag1%2Chashtag2%2Chashtag3

export const chainImages = {
	"1": "ethereum",
	"56": "binance",
	"137": "polygon",
	"250": "fantom",
	"43114": "avalanche"
}


// Network Name : XRPL EVM Sidechain
// New RPC URL : https://rpc-evm-sidechain.xrpl.org
// Chain ID : 1440002
// Currency Symbol : XRP
// Block Explorer : https://evm-sidechain.xrpl.org

export const chainMeta = {
	"ethereum":{
		id: 1,
		chainShort: "eth",
		blockExplorer: "https://etherscan.io/",
		blockExplorerName: "etherscan",
		scan: 'https://tokensniffer.com/token/eth/',
		scanImage: 'tokenSniffer'
	},
	"tron":{
		id: 1,
		chainShort: "tron",
		blockExplorer: "https://tronscan.org/#/",
		blockExplorerName: "tronscan",
		scan: 'https://solsniffer.com/scanner/',
		scanImage: 'solSniffer',
		explorerPairType: "token20",
		explorerTokenType: "token20",
		explorerAddressType: "address"
	},
	"solana":{
		id: 1399811149,
		chainShort: "sol",
		blockExplorer: "https://solscan.io/",
		blockExplorerName: "solscan",
		scan: 'https://solsniffer.com/scanner/',
		scanImage: 'solSniffer'
	},
	"base":{
		id: 8453,
		chainShort: "base",
		blockExplorer: "https://basescan.org/",
		blockExplorerName: "basescan",
		scan: 'https://tokensniffer.com/token/base/',
		scanImage: 'tokenSniffer'
	},
	"ton":{
		chainShort: "ton",
		blockExplorer: "https://tonviewer.com/",
		blockExplorerName: "tonscan",
		scan: null,
		scanImage: 'tokenSniffer',
		explorerPairType: "",
		explorerTokenType: "",
		explorerAddressType: ""
	},
	"sui":{
		chainShort: "sui",
		blockExplorer: "https://suiscan.xyz/",
		blockExplorerName: "suiscan",
		bridge: "https://bridge.sui.io/",
		scan: null,
		scanImage: null,
		explorerPairType: "object",
		explorerTokenType: "coin"
	},
	"bsc":{
		chainShort: "bsc",
		blockExplorer: "https://bscscan.com/",
		blockExplorerName: "bscscan",
		swaps: ['pancakeswap','sushiswap','kibaswap'],
		scan: 'https://tokensniffer.com/token/eth/',
		scanImage: 'tokenSniffer'
	},
	"avalanche":{
		chainShort: "avax",
		blockExplorer: "https://snowtrace.io/",
		blockExplorerName: "snowtrace",
		swaps: ['uniswap'],
		scan: 'https://tokensniffer.com/token/avalanche/',
		scanImage: 'tokenSniffer'
	},
	"blast":{
		chainShort: "blast",
		blockExplorer: "https://blastscan.io/",
		blockExplorerName: "blastscan",
		swaps: ['uniswap'],
		scan: 'https://tokensniffer.com/token/blast/',
		scanImage: 'tokenSniffer'
	},
}

export const blockieSeed = "love&hope37";


export const chains = {

	// add iconUrls: []

	"0x1": {
		name: "Ethereum Mainnet",
		nameid: "eth",
		image: "ethereum",
		id: "1",
		hex: "0x1",
		rpcUrls: ["https://mainnet.infura.io/v3/"],
		blockExplorerUrls: ["https://etherscan.io"],
		swaps:['uni'],
		nativeCurrency: {
		    "symbol": "ETH",
		    "name": "Ethereum",
		    "decimals": 18,
		}
	},
	// "flashbots": {
	// 	name: "Flashbots RPC",
	// 	nameid: "flashbots",
	// 	image: "flashbots",
	// 	id: "1",
	// 	hex: "0x1",
	// 	rpcUrls: ["https://rpc.flashbots.net"],
	// 	blockExplorerUrls: ["https://etherscan.io"],
	// 	swaps:['uni'],
	// 	nativeCurrency: {
	// 	    "symbol": "ETH",
	// 	    "name": "Ethereum",
	// 	    "decimals": 18,
	// 	}
	// },
	// "mevblocker": {
	// 	name: "MEVBlocker RPC",
	// 	nameid: "mevblocker",
	// 	image: "mevblocker",
	// 	id: "1",
	// 	hex: "0x1",
	// 	rpcUrls: ["https://rpc.mevblocker.io"],
	// 	blockExplorerUrls: ["https://etherscan.io"],
	// 	swaps:['uni'],
	// 	nativeCurrency: {
	// 	    "symbol": "ETH",
	// 	    "name": "Ethereum",
	// 	    "decimals": 18,
	// 	}
	// },
	// "0x5": {
	// 	name: "Ethereum Goerli Testnet",
	// 	note: "testnet",
	// 	nameid: "goerli",
	// 	image: "ethereum",
	// 	id: "5",
	// 	hex: "0x5",
	// 	test: "ethereum",
	// 	rpcUrls: ['https://rpc.ankr.com/eth_goerli'],
	// 	blockExplorerUrls: [],
	// 	nativeCurrency: {
	// 	    "symbol": "ETH",
	// 	    "name": "Ethereum",
	// 	    "decimals": 18,
	// 	}
	// },
	// "flashbotsgoerli": {
	// 	name: "Flashbots RPC Goerli",
	// 	nameid: "flashbotsgoerli",
	// 	image: "flashbots",
	// 	id: "5",
	// 	hex: "0x5",
	// 	test: "flashbots",
	// 	rpcUrls: ["https://rpc-goerli.flashbots.net"],
	// 	blockExplorerUrls: ["https://rpc.ankr.com/eth_goerli"],
	// 	swaps:['uni'],
	// 	nativeCurrency: {
	// 	    "symbol": "ETH",
	// 	    "name": "Ethereum",
	// 	    "decimals": 18,
	// 	}
	// },
	"0x15f902": {
		name: "XRPL EVM",
		nameid: "xrp",
		image: "xrp",
		id: "1440002",
		hex: "0x15f902",
		rpcUrls: ["https://rpc-evm-sidechain.xrpl.org"],
		blockExplorerUrls: ["https://evm-sidechain.xrpl.org"],
		swaps:['uni'],
		nativeCurrency: {
		    "symbol": "XRP",
		    "name": "Ripple",
		    "decimals": 18,
		}
	},
	"0x2105": {
		name: "Base Mainnet",
		nameid: "base",
		image: "base",
		id: "8453",
		hex: "0x2105",
		rpcUrls: ["https://mainnet.base.org"],
		blockExplorerUrls: ["https://basescan.org"],
		swaps:['leet','rocket'],
		nativeCurrency: {
		    "symbol": "ETH",
		    "name": "Ethereum",
		    "decimals": 18,
		}
	},
	// "0xa4b1": {
	// 	name: "Arbitrum One",
	// 	nameid: "arbitrum",
	// 	image: "arbitrum",
	// 	id: "42161",
	// 	hex: "0xa4b1",
	// 	rpcUrls: ['https://arb1.arbitrum.io/rpc'],
	// 	blockExplorerUrls: ['https://arbiscan.io/','https://explorer.arbitrum.io/'],
	// 	swaps:['sushi'],
	// 	nativeCurrency: {
	// 	    "symbol": "ETH",
	// 	    "name": "Ethereum",
	// 	    "decimals": 18,
	// 	}
	// },
	// "0x66eed": {
	// 	name: "Arbitrum Goerli",
	// 	nameid: "arbitrum",
	// 	image: "arbitrum",
	// 	id: "421613",
	// 	hex: "0x66eed",
	// 	rpcUrls: ['https://endpoints.omniatech.io/v1/arbitrum/goerli/public',
	// 	'https://arbitrum-goerli.public.blastapi.io'
	// 	],
	// 	blockExplorerUrls: [],
	// 	swaps:['sushi'],
	// 	nativeCurrency: {
	// 	    "symbol": "AGOR",
	// 	    "name": "Ethereum",
	// 	    "decimals": 18,
	// 	}
	// },
	// "0x7d0": {
	// 	name: "Dogechain Mainnet",
	// 	nameid: "doge",
	// 	image: "dogechain",
	// 	id: "2000",
	// 	hex: "0x7d0",
	// 	rpcUrls: ['https://rpc-sg.dogechain.dog',
	// 	'https://rpc01.dogechain.dog',
	// 	'https://rpc-us.dogechain.dog'],
	// 	blockExplorerUrls: ['https://explorer.dogechain.dog/'],
	// 	nativeCurrency: {
	// 	    "symbol": "DOGE",
	// 	    "name": "Dogecoin",
	// 	    "decimals": 18,
	// 	}
	// },
	// "0x171": {
	// 	name: "PulseChain Mainnet",
	// 	nameid: "pulsechain",
	// 	image: "pulsechain",
	// 	id: "369",
	// 	hex: "0x171",
	// 	rpcUrls: ['https://rpc.pulsechain.com'],
	// 	blockExplorerUrls: ['https://scan.pulsechain.com','https://beacon.pulsechain.com/'],
	// 	nativeCurrency: {
	// 	    "symbol": "PLS",
	// 	    "name": "Pulse",
	// 	    "decimals": 18,
	// 	}
	// },
	// "0x3af": {
	// 	name: "PulseChain Testnet",
	// 	note: "testnet v4",
	// 	nameid: "pulsechain",
	// 	image: "pulsechain",
	// 	id: "943",
	// 	hex: "0x3af",
	// 	rpcUrls: ['https://rpc.v4.testnet.pulsechain.com'],
	// 	blockExplorerUrls: ['https://scan.v4.testnet.pulsechain.com'],
	// 	nativeCurrency: {
	// 	    "symbol": "PLS",
	// 	    "name": "Pulse",
	// 	    "decimals": 18,
	// 	}
	// },

	// "0xa": {
	// 	name: "Optimism",
	// 	nameid: "optimism",
	// 	image: "optimism",
	// 	id: "10",
	// 	hex: "0xa",
	// 	rpcUrls: ['https://mainnet.optimism.io'],
	// 	blockExplorerUrls: ['https://explorer.optimism.io'],
	// 	swaps:['sushi'],
	// 	nativeCurrency: {
	// 	    "symbol": "ETH",
	// 	    "name": "Ethereum",
	// 	    "decimals": 18,
	// 	}
	// },



	// "0x38": {
	// 	name: "Binance Smart Chain",
	// 	nameid: "bsc",
	// 	image: "binance",
	// 	id: "56",
	// 	hex: "0x38",
	// 	rpcUrls: ["https://bsc-dataseed.binance.org"],
	// 	blockExplorerUrls: ["https://bscscan.com"],
	// 	nativeCurrency: {
	// 	    "symbol": "BNB",
	// 	    "name": "BNB",
	// 	    "decimals": 18, // 9 on Binance Chain?
	// 	}
	// },
	// "0x89": {
	// 	name: "Polygon Mainnet",
	// 	nameid: "polygon",
	// 	image: "polygon",
	// 	id: "137",
	// 	hex: "0x89",
	// 	rpcUrls: ["https://polygon-rpc.com"],
	// 	blockExplorerUrls: ["https://polygonscan.com"],
	// 	nativeCurrency: {
	// 	    "symbol": "MATIC",
	// 	    "name": "MATIC",
	// 	    "decimals": 18,
	// 	}
	// },
	// "0xfa": {
	// 	name: "Fantom Opera",
	// 	nameid: "fantom",
	// 	image: "fantom",
	// 	id: "250",
	// 	hex:"0xfa",
	// 	rpcUrls: ["https://rpc.ftm.tools/",
	// 	"https://fantom.api.onfinality.io/public"],
	// 	blockExplorerUrls: ["https://ftmscan.com"],
	// 	nativeCurrency: {
	// 	    "symbol": "FTM",
	// 	    "name": "FTM",
	// 	    "decimals": 18,
	// 	}
	// },
	// "0x86a": {
	// 	name: "Avalanche C-Chain",
	// 	nameid: "avalanche",
	// 	image: "avalanche",
	// 	id: "43114",
	// 	hex: "0x86a",
	// 	rpcUrls: ["https://api.avax.network/ext/bc/C/rpc",
	// 	"https://rpc.ankr.com/avalanche",
	// 	"https://avalanche.api.onfinality.io/public/ext/bc/P"],
	// 	blockExplorerUrls: ["https://snowtrace.io"],
	// 	nativeCurrency: {
	// 	    "symbol": "AVAX",
	// 	    "name": "AVAX",
	// 	    "decimals": 18,
	// 	}
	// },


};




export function toHex(num){
  return '0x' + Number(num).toString(16);
};



export const chainData = {
	'polygon': {
		params: [{
	        chainId: "0x89",
	        chainName: "Matic Mainnet",
			rpcUrls: ["https://rpc-mainnet.matic.network/"],
	        nativeCurrency: {
	            name: "MATIC",
	            symbol: "MATIC",
	            decimals: 18
	        },
	        blockExplorerUrls: ["https://polygonscan.com/"]
	    }]
	},
	'goerli': {
		params: [{
	      chainId: "0x5",
	      chainName: "Goerli",
	      rpcUrls: [`https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`],
	      nativeCurrency: {
	        name: "Goerli ETH",
	        symbol: "gorETH",
	        decimals: 18
	      },
	      blockExplorerUrls: ["https://goerli.etherscan.io"]
	  	}]
	},
	'harmony': {
		params: [
		 {
		   chainId: "0x63564c40",
		   rpcUrls: ["https://api.harmony.one"],
		   chainName: "Harmony Mainnet",
		   nativeCurrency: { name: "ONE", decimals: 18, symbol: "ONE" },
		   blockExplorerUrls: ["https://explorer.harmony.one"],
		   iconUrls: ["https://harmonynews.one/wp-content/uploads/2019/11/slfdjs.png"]
		 }
		],
	},
	'ethereum': {
		params: []
	},
	'fantom': {
		params: []
	},
	'binance': {
		params: []
	},
	'avalanche': {
		params: []
	},
}

export const supportedChains = [
	"ethereum",
	"solana",
	"base",
	"ton",
	"tron",
	"sui"
	// "blast",
	// "bsc",
	// "avalanche"
]

export const supportedChainsPortfolio = {
	"0x1": {
		name: "ethereum",
		key: "eth"
	},
	"0x2105": {
		name: "base",
		key: "base"
	},
	"sol": {
		name: "solana",
		key: "sol"
	}
}

export const coinColorsDefault = {
	"primary": "#38bcea",
	"secondary": "#8247e5",
	"chartLineOpacitySub": .7,
	"chartAreaOpacity": .33,
	"chartAreaOpacitySub": .11,
}

export const coinColors = {
	"btc": "#f69c3d",
	"eth": "#627eea",
	"seth": "#627eea",
	"weth": "#627eea",
	"avax": "#e84142",
	"usdt": "#03ac84",
	"busd": "#f0b90b",
	"usdc": "#2775ca",
	"sol": "#6e36bd",
	"flex": "#815bf4",
	"ada": "#226ad0",
	"unfi": "#35ea8f",
	"trx": "#b32d24",
	"link": "#2959d9",
	"wbtc": "#f69c3d",
	"doge": "#ba9f32",
	"dai": "#fab120",
	"dot": "#e6027a",
	"shib": "#ff0e00",
	"matic": "#8247e5",
	"ape": "#134cd0",
	"sand": "#03adef",
	"etc": "#36b437",
	"mana": "#ff4956",
	"ftm": "#38bcea",
	"op": "#fe041f",
	"wbnb": "#F2B932",
	"bnb": "#F2B932",
	"vet": "#30a3ef",
	"aave": "#8374ac",
	"luna": "#F2E384",
	"tusd": "#1b59ff",
	"uni": "#fd077d",
	"icp": "#ec1d78",
	"lrc": "#1b60ff",
	"cake": "#d1884f",
	"lunc": "#f9d65d",
	"rune": "#00efb6",
	"sushi": "#e658a4",
	"ftt": "#02a6c2",
	"bsv": "#E8B324",
	"neo": "#00e599",
	"crv": "#74fd80",
	"ens": "#699df5",
	"snx": "#03c9f8",
	"grt": "#384ba5",
	"1inch": "#ad252b",
	"mkr": "#1f9f91",
	"anc": "#4bda4a",
	"yfi": "#046ce9",
	"srm": "#5cbed6",
	"comp": "#00d395",
	"steth": "#04a3ff",
	"band": "#4520e6",
	"perp": "#3CEAAB",
	"mim": "#fed955",
	"ohm": "#708b96",
	"amp": "#D62D7B",
	"joe": "#f2716a",
	"quick": "#418aca",
	"boo": "#6665dd",
	"png": "#fdc702",
	"any": "#4F87F0",
	"marshall-rogan-inu": "#cf2624",
	"mri": "#cf2624",
	"mcc": "#606dbb",
	"refi": "#ff2222"

}

export const colorSwatches = ['#5671d4', '#597cd9', '#5c87dd', '#5e92e1', '#5f9de5', '#5fa8e9', '#5eb3ed', '#5cbff1', '#58caf5', '#4fd6fa', '#00e4ff', '#00e4ff77'];

export const dataProviderLogos = [
	// {
	// 	url:'logo_twitter.png',
	// 	href:'https://twitter.com',
	// 	desc:'twitter.com',
	// 	circular: true,
	// },
	{
		url:'logo_tg.png',
		href:'https://telegram.org',
		desc:'telegram.org',
		circular: false,
	},
	{
		url:'logo_alchemy.png',
		href:'https://alchemy.com',
		desc:'alchemy.com',
		circular: false,
	},
	{
		url:'logo_uniswap.png',
		href:'https://uniswap.org',
		desc:'uniswap.org',
		circular: false,
	},
	{
		url:'logo_x.png',
		href:'https://x.com',
		desc:'x.com',
		circular: true,
	},

	// {
	// 	url:'logo_dexscreener.png',
	// 	href:'https://dexscreener.com',
	// 	desc:'dexscreener.com',
	// 	circular: false,
	// },
	{
		url:'logo_etherscan.png',
		href:'https://etherscan.io',
		desc:'etherscan.io',
		circular: false,
	},
	{
		url:'logo_defined.png',
		href:'https://defined.fi',
		desc:'defined.fi',
		circular: true,
	},
	// {
	// 	url:'logo_coingecko.png',
	// 	href:'https://coingecko.com',
	// 	desc:'coingecko.com',
	// 	circular: true,
	// },
	// {
	// 	url:'logo_coinmarketcap.png',
	// 	href:'https://coinmarketcap.com',
	// 	desc:'coinmarketcap.com',
	// 	circular: true,
	// },


	{
		url:'logo_tokensniffer.png',
		href:'https://tokensniffer.com',
		desc:'tokensniffer.com',
		circular: false,
	},

	{
		url:'logo_honeypot.png',
		href:'https://honeypot.is/ethereum',
		desc:'honeypot.is',
		circular: false,
	},

	// {
	// 	url:'logo_opensea.png',
	// 	href:'https://www.opensea.io',
	// 	desc:'opensea.io',
	// 	circular: false,
	// },

	// {
	// 	url:'logo_twilio.png',
	// 	href:'https://www.twilio.com/',
	// 	desc:'twilio.com',
	// 	circular: false,
	// },




]

export const tokenCats = [
	{
		name: 'new pairs',
		id: 'newpairs'
	},
	{
		name: 'trending',
		id: 'trending'
	}
]


export const subDigits = [
  "&#x2080;", // Subscript 0
  "&#x2081;", // Subscript 1
  "&#x2082;", // Subscript 2
  "&#x2083;", // Subscript 3
  "&#x2084;", // Subscript 4
  "&#x2085;", // Subscript 5
  "&#x2086;", // Subscript 6
  "&#x2087;", // Subscript 7
  "&#x2088;", // Subscript 8
  "&#x2089;"  // Subscript 9
];



export const tokenFiltersData = {
	"verified": {
		title: 'verified',
		field: 'verified',
		type: 'toggle'
	},
	"locked": {
		title: 'locked',
		field: 'locked',
		type: 'boolean',
	},
	"renounced": {
		title: 'renounced',
		field: 'renounced',
		type: 'boolean',
	},
	"fdv_min": {
		title: 'fdv',
		field: 'data.fdv',
		type: 'range',
	},
	"fdv_max": {
		title: 'fdv',
		field: 'data.fdv',
		type: 'range',
	},
	"volume_min": {
		title: 'fdv',
		field: 'volume.h24',
		type: 'range',
	},
	"volume_max": {
		title: 'fdv',
		field: 'volume.h24',
		type: 'range',
	},
	"age": {
		title: 'age',
		field: 'liquidityAt',
		type: 'input',
	},
	"csmin": {
		title: 'contract score min',
		field: 'contractScore',
		type: 'input',
		validate: 'Number',
		range: [0,100],
	},

}


export const fdvLabels = ['1k','100k','1M','10M','100M','1B','10B+'];


export const volumeLabels = ['1k','100k','1M','10M+'];




export const eTrendingNames = {
	"bt": "BuyTech ETH",
	"sg": "SafeGuard ETH",
	"tg": "Trend Guru ETH",
	"dt": "DexTools ETH",
	"mt": "Moontok ETH",
	"mtsol": "Moontok SOL",
	"mtbase": "Moontok Base",
	"mtsui": "Moontok SUI",
	"ds": "DexScreener ETH",
	"dssol": "DexScreener SOL",
	"dsbase": "DexScreener Base",
	"soltbirdy": "$BIRDY",
	"soltbb": "SOL TRENDING",
	"btbase": "Base BuyTech",
	"spy": "SpyDefi ETH",
	"spybase": "SpyDefi Base",
	"spytron": "SpyDefi Tron",
	"spysol": "SpyDefi Solana",
	// "tront": "Tron Trending",
	"majoreth": "Major Trending ETH",
	"majorbase": "Major Trending Base",
	"majorsol": "Major Trending SOL",
	"majorsolpf": "Major Trending Pump.Fun",
	"ton": "Ton Trending",
	"cherryeth": "Cherry ETH",
	"cherrybase": "Cherry Base",
	"cherrysol": "Cherry SOL",
	"cherrytron": "Cherry Tron",
	"cherrysui": "Cherry SUI",
}

export const eTrendingData = {
	"ethereum":{
		"bt":{
			name: "BuyTech ETH",
		},
		"sg":{
			name: "SafeGuard ETH",
		},
		"dt":{
			name: "DexTools Hot Pairs",
		},
		"ds":{
			name: "DexScreener ETH",
		},
		"majoreth":{
			name: "Major ETH"
		},
		"tg":{
			name: "Trend Guru",
		},
		
		
		"mt":{
			name: "Moontok ETH",
		},
		
		"spy":{
			name: "SpyDefi ETH",
		},
		
		"cherryeth":{
			name: "Cherry ETH"
		}	
	},
	
	"solana": {
		"majorsol":{
			name: "Major SOL"
		},
		"dssol": {
			name: "DexScreener SOL"
		},
		// "soltbirdy": {
		// 	name: "$BIRDY SOL"
		// },
		"soltbb": {
			name: "Deluge SOL"
		},
		"mtsol": {
			name: "Moontok SOL"
		},
		// "spysol": {
		// 	name: "SpyDefi SOL"
		// },
		"cherrysol":{
			name: "Cherry SOL"
		},
		
		"majorsolpf":{
			name: "Major SOL Pump.Fun"
		}	
	},
	"base": {
		"btbase":{
			name: "BuyTech Base",
		},
		// "majorbase":{
		// 	name: "Major Base"
		// },
		"mtbase":{
			name: "Moontok Base",
		},
		"dsbase":{
			name: "DexScreener Base",
		},
		
		"cherrybase":{
			name: "Cherry Base"
		},
		"spybase":{
			name: "SpyDefi Base",
		},
	},
	"tron": {
		
		
		// "spytron": {
		// 	name: "SpyDefi Tron"
		// },
		// "tront": {
		// 	name: "Tron Trending"
		// },
		"cherrytron":{
			name: "Cherry Tron"
		}
	},
	"ton": {
		"ton": {
			name: "Ton Trending"
		}
	},
	"sui": {
		"cherrysui":{
			name: "Cherry SUI"
		},
		"mtsui":{
			name: "Moontok SUI"
		}
	}
}

export const adminWalletList = [
	"0x0780e25F8e3642A32f23bcbfB6ffe2A1035eeDBa", // jb22
	"0x606B8f7fbbd2038FCd6c906Bd376c0A188477BDE", // 2ndary
	"0xaD924355bE23Fcc9586Fe4703e3B21D25868277A", // d wallet
	"0x95F28179e462CAE4ec3B88c281A66df4bE56B157", // angel
	"0xaA5D76eF315a495252E227687D3Bf944647846b9" // a2
]

export const stagingAccessList = [

];


export const walletWhiteList = [
	"0x66E23e601252fb289207cEBf66079ceDE6dc4989",
	"0xEBFa1C3350b05187cFea2491C766b6DB1b10b5b0",  // test dev
	"0xcb8C8cA0d9cA8f35bC630dFF8a8D54bc0980a87d", // dokx
	"0x0780e25F8e3642A32f23bcbfB6ffe2A1035eeDBa",
	"0xB29681cE0b4669cE6E7Ca2e55863839FCBAD840E",
	"0x60fD3a8B0095Dd41Ae31f718EED7c4466E0D06a3",
	"0x95F28179e462CAE4ec3B88c281A66df4bE56B157",  // angel
	"0x939f1d3bC2a8E0Fafa38037545FC9748EcC737D9", // ant
	"0xc26d9A610C6c4e912FDeD64F0766Bd9de6D28Be5", // marketing
	"0xc15d540feC48e32EEf17a395c26d81a684a212c2", // tob
	// "0xaD924355bE23Fcc9586Fe4703e3B21D25868277A", // dman
	"0x0DF60D80ED2F6A076D47Ac82a6BDBFA9233A16ad", // starman
	// "0x206062EA296E4E893ed708af61d27B68316F0990", // sentai
	// "0xBd0148ab73644480ECf7c02c6c64eE7EF43a226D", // pb
	"0x54821d1B461aa887D37c449F3ace8dddDFCb8C0a", // paal
	"0x29be112d6bc37646e93c18499884c4fb95f99195", // jab
	"0x49d0b3b979C8DB8D91b34A16716264C68771d915", // hodler
	"0xA9Eb4296e2265CA0664961C8Cb55b4c0bA3094e7",
	"Aristhodle.eth",
	"0x534b8531e362Da97D808ba2ab3959fa2597BeF11",  // aristhodle
	"0xC6D4E5C1cd5c2142C4592bBf66766e0f5f588d84",  // eastbird
	"0x5950e45026969440E5Ab6955Ad488509aaA8c3a9",  // psily
	// "0x351B8000D53BB464d6afC05e0f00169aC47e7f48",
	// "0x85c4cDbBe7cbb7eEcecA0F835E66451De5a61D1C",
	// "0xcccaddce78ca4e1d0c02dec47785a4ba9ec6b4c1",
	// "0x94d15f76E11986E270fE067e599dE516D8DeBeb6",
	// "0x3F17D91Fee30DAe3030080c1D5D2BB5e803906b8",
	// "0x22007a8B65033f6719D745635E0b7C89eb68ad4B",
	// "0x6892F09a7834E93C84Ae3D0829aD00aEf1094732",
	// // "0x07a0d6BC5b63792CbD7bdd62231ee4D077228Ffe", // mitesh og
	// "0x4174677Be62EAF287C4d8C4A9E95781Af63D64DC",
	// "0x58970a06164d5027B841d7e79344bcE5AfC40651",
	// "0xaeEF513b28313c7F8e80891A7434bc2AE5104609",
	// "0xFCa5565DAbc899b641b3f24F6A762Fc2351235B5",
	// "0x1b076946Ba920E3489C544B45a6ec36595A191b9",
	"0x18DcE61496f9a5c846C6d2462e0116CaF00E494d",  //mitesh
	"0x1E0a2F8001DBE68Ee9a783fBc0802a863f7624d0", // slip
	"0xA837269440708b0aAf414Bc84DcCB2f7e22476F3", // blondie
]


export const mockPortfolio = {
	// weth
	"0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2":{
		address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
		balance: "9888000000000000000"
	},
	// evermoon
	"0x4ad434b8CDC3AA5AC97932D6BD18b5d313aB0f6f":{
		address: "0x4ad434b8CDC3AA5AC97932D6BD18b5d313aB0f6f",
		balance: "533370000000000000000000"
	},
	// arbitrum
	"0xB50721BCf8d664c30412Cfbc6cf7a15145234ad1":{
		address:"0xB50721BCf8d664c30412Cfbc6cf7a15145234ad1",
		balance:"1370000000000000000000"
	},
	// matic
	"0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0":{
		address:"0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
		balance:"12440000000000000000000"
	},
	// pepe
	"0x6982508145454Ce325dDbE47a25d4ec3d2311933":{
		address:"0x6982508145454Ce325dDbE47a25d4ec3d2311933",
		balance:"4880000000000000000000000000"
	},
	// pepe 2
	"0xfb66321D7C674995dFcC2cb67A30bC978dc862AD":{
		address:"0xfb66321D7C674995dFcC2cb67A30bC978dc862AD",
		balance:"118000000000000000000000000000"
	},
	// sani
	"0x4521C9aD6A3D4230803aB752Ed238BE11F8B342F":{
		address:"0x4521C9aD6A3D4230803aB752Ed238BE11F8B342F",
		balance:"944000000000000000000000000"
	},
	// metis
	"0x9E32b13ce7f2E80A01932B42553652E053D6ed8e":{
		address:"0x9E32b13ce7f2E80A01932B42553652E053D6ed8e",
		balance:"277700000000000000000"
	},
	// ape
	"0x4d224452801ACEd8B2F0aebE155379bb5D594381":{
		address:"0x4d224452801ACEd8B2F0aebE155379bb5D594381",
		balance:"3230000000000000000000"
	},
	// shib
	"0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE":{
		address:"0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE",
		balance:"2120000000000000000000000000"
	},
	// bobo
	"0xB90B2A35C65dBC466b04240097Ca756ad2005295":{
		address:"0xB90B2A35C65dBC466b04240097Ca756ad2005295",
		balance:"3792000000000000000000000000"
	},
	// hex
	"0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39":{
		address:"0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39",
		balance:"16440000000000"
	},
	// 0xdex
	"0xB2Cfc9A054562BCfD854daD55a99E8BC8085A480":{
		address:"0xB2Cfc9A054562BCfD854daD55a99E8BC8085A480",
		balance:"19880000000000000000000000"
	},
	//dogelon
	"0x761D38e5ddf6ccf6Cf7c55759d5210750B5D60F3":{
		address:"0x761D38e5ddf6ccf6Cf7c55759d5210750B5D60F3",
		balance:"5324000000000000000000000000"
	},
	// kishu
	"0xA2b4C0Af19cC16a6CfAcCe81F192B024d625817D":{
		address:"0xA2b4C0Af19cC16a6CfAcCe81F192B024d625817D",
		balance:"12770000000000000000000"
	},
	// usdc
	"0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48":{
		address:"0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
		balance:"1146"
	}
}

export const memePhotos = [
	'2024-11-06 16.43.59.jpg',
	'2024-11-06 16.44.21.jpg',
	'2024-11-06 16.44.30.jpg',
	'2024-11-06 16.44.40.jpg',
	'2024-11-06 16.44.49.jpg',
	'2024-11-06 16.45.13.jpg',
	'2024-11-06 16.45.28.jpg',
	'2024-11-06 16.45.47.jpg',
	'2024-11-06 16.45.58.jpg',
	'2024-11-06 16.46.06.jpg',
	'2024-11-06 16.46.14.jpg',
	'2024-11-06 16.46.30.jpg',
	'2024-11-06 16.46.40.jpg',
	'2024-11-06 16.46.47.jpg',
	'2024-11-06 16.46.55.jpg',
	'2024-11-06 16.47.05.jpg',
	'2024-11-06 16.47.22.jpg',
	'2024-11-06 16.47.36.jpg',
	'2024-11-06 16.47.54.jpg',
	'2024-11-06 16.48.28.jpg',
	'2024-11-06 16.48.42.jpg',
	'2024-11-06 16.48.51.jpg',
	'2024-11-06 16.49.02.jpg',
	'2024-11-06 16.49.08.jpg',
	'2024-11-06 16.49.28.jpg',
	'IMG_1086.jpg',
	'medmonkey.jpg'
]

export const bgGradients = [
	"linear-gradient(0deg, rgba(255, 0, 150, 1) 0%, rgba(0, 204, 255, 1) 100%)",
	"linear-gradient(0deg, rgba(255, 94, 77, 1) 0%, rgba(255, 141, 89, 1) 100%)",
	"linear-gradient(0deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%)",
	"linear-gradient(0deg, rgba(255, 195, 0, 1) 0%, rgba(239, 26, 157, 1) 100%)",
	"linear-gradient(0deg, rgba(138, 35, 135, 1) 0%, rgba(0, 155, 255, 1) 100%)",
	"linear-gradient(0deg, rgba(255, 66, 119, 1) 0%, rgba(255, 173, 15, 1) 100%)",
	"linear-gradient(0deg, rgba(219, 82, 135, 1) 0%, rgba(114, 0, 255, 1) 100%)",
	"linear-gradient(0deg, rgba(85, 101, 118, 1) 0%, rgba(223, 124, 173, 1) 100%)",
	"linear-gradient(0deg, rgba(240, 158, 37, 1) 0%, rgba(255, 56, 135, 1) 100%)",
	"linear-gradient(0deg, rgba(51, 71, 127, 1) 0%, rgba(48, 25, 52, 1) 100%)",
	"linear-gradient(0deg, rgba(255, 174, 80, 1) 0%, rgba(13, 88, 206, 1) 100%)",
	"linear-gradient(0deg, rgba(17, 104, 156, 1) 0%, rgba(56, 112, 150, 1) 100%)",
	"linear-gradient(0deg, rgba(0, 170, 255, 1) 0%, rgba(0, 220, 255, 1) 100%)",
	"linear-gradient(0deg, rgba(17, 146, 255, 1) 0%, rgba(179, 78, 240, 1) 100%)",
	"linear-gradient(0deg, rgba(255, 195, 0, 1) 0%, rgba(167, 121, 53, 1) 100%)",
	"linear-gradient(0deg, rgba(68, 68, 102, 1) 0%, rgba(216, 119, 59, 1) 100%)",
	"linear-gradient(0deg, rgba(255, 105, 180, 1) 0%, rgba(184, 88, 255, 1) 100%)",
	"linear-gradient(0deg, rgba(128, 95, 255, 1) 0%, rgba(11, 109, 233, 1) 100%)",
	"linear-gradient(0deg, rgba(0, 255, 145, 1) 0%, rgba(61, 160, 255, 1) 100%)",
	"linear-gradient(0deg, rgba(114, 47, 129, 1) 0%, rgba(74, 125, 254, 1) 100%)",
	"linear-gradient(0deg, rgba(19, 206, 123, 1) 0%, rgba(255, 255, 255, 1) 100%)",
	"linear-gradient(0deg, rgba(45, 54, 85, 1) 0%, rgba(247, 102, 13, 1) 100%)",
	"linear-gradient(0deg, rgba(32, 182, 249, 1) 0%, rgba(255, 212, 75, 1) 100%)",
	"linear-gradient(0deg, rgba(45, 47, 118, 1) 0%, rgba(79, 137, 179, 1) 100%)",
	"linear-gradient(0deg, rgba(214, 45, 86, 1) 0%, rgba(110, 55, 133, 1) 100%)",
	"linear-gradient(0deg, rgba(0, 174, 167, 1) 0%, rgba(245, 113, 74, 1) 100%)",
	"linear-gradient(0deg, rgba(171, 92, 255, 1) 0%, rgba(47, 71, 179, 1) 100%)",
	"linear-gradient(0deg, rgba(68, 66, 76, 1) 0%, rgba(219, 105, 58, 1) 100%)",
	"linear-gradient(0deg, rgba(0, 205, 255, 1) 0%, rgba(220, 15, 132, 1) 100%)",
	"linear-gradient(0deg, rgba(89, 89, 214, 1) 0%, rgba(240, 107, 56, 1) 100%)",
	"linear-gradient(0deg, rgba(245, 124, 30, 1) 0%, rgba(1, 79, 155, 1) 100%)",
	"linear-gradient(0deg, rgba(0, 213, 255, 1) 0%, rgba(112, 27, 233, 1) 100%)",
	"linear-gradient(0deg, rgba(59, 121, 179, 1) 0%, rgba(112, 156, 255, 1) 100%)",
	"linear-gradient(0deg, rgba(0, 113, 204, 1) 0%, rgba(34, 77, 162, 1) 100%)",
	"linear-gradient(0deg, rgba(103, 0, 255, 1) 0%, rgba(83, 13, 255, 1) 100%)",
	"linear-gradient(0deg, rgba(83, 70, 164, 1) 0%, rgba(235, 90, 24, 1) 100%)",
	"linear-gradient(0deg, rgba(246, 112, 56, 1) 0%, rgba(125, 9, 122, 1) 100%)",
	"linear-gradient(0deg, rgba(13, 140, 122, 1) 0%, rgba(250, 85, 49, 1) 100%)",
	"linear-gradient(0deg, rgba(91, 156, 243, 1) 0%, rgba(47, 214, 179, 1) 100%)",
	"linear-gradient(0deg, rgba(248, 116, 111, 1) 0%, rgba(241, 64, 43, 1) 100%)",
	"linear-gradient(0deg, rgba(115, 118, 233, 1) 0%, rgba(35, 111, 136, 1) 100%)",
	"linear-gradient(0deg, rgba(23, 178, 255, 1) 0%, rgba(10, 81, 155, 1) 100%)",
	"linear-gradient(0deg, rgba(31, 108, 120, 1) 0%, rgba(91, 202, 148, 1) 100%)"
];

export const vibrantColors = [
	"rgba(255, 99, 132, 0.14)",     // Pink
	"rgba(255, 159, 64, 0.14)",     // Orange
	"rgba(255, 205, 86, 0.14)",     // Yellow
	"rgba(75, 192, 192, 0.14)",     // Teal
	"rgba(54, 162, 235, 0.14)",     // Light Blue
	"rgba(153, 102, 255, 0.14)",    // Purple
	"rgba(201, 203, 207, 0.14)",    // Gray
	"rgba(255, 69, 0, 0.14)",       // Red-Orange
	"rgba(0, 128, 128, 0.14)",      // Dark Teal
	"rgba(255, 215, 0, 0.14)",      // Gold
	"rgba(138, 43, 226, 0.14)",     // Blue-Violet
	"rgba(255, 20, 147, 0.14)",     // Deep Pink
	"rgba(0, 191, 255, 0.14)",      // Sky Blue
	"rgba(218, 112, 214, 0.14)",    // Orchid
	"rgba(244, 164, 96, 0.14)",     // Sandy Brown
	"rgba(60, 179, 113, 0.14)",     // Medium Sea Green
	"rgba(255, 140, 0, 0.14)",      // Dark Orange
	"rgba(34, 139, 34, 0.14)",      // Forest Green
	"rgba(30, 144, 255, 0.14)",     // Dodger Blue
	"rgba(47, 79, 79, 0.14)",       // Dark Slate Gray
	"rgba(106, 90, 205, 0.14)",     // Slate Blue
	"rgba(72, 61, 139, 0.14)",      // Dark Slate Blue
	"rgba(255, 99, 71, 0.14)",      // Tomato
	"rgba(127, 255, 0, 0.14)",      // Chartreuse
	"rgba(210, 105, 30, 0.14)",     // Chocolate
	"rgba(95, 158, 160, 0.14)",     // Cadet Blue
	"rgba(148, 0, 211, 0.14)"       // Dark Violet
  ];

export function getVibrantColorGradient(index, count, type="pleasing") {
	
	const colorGradients = {
		green: [
		  { start: "11,81,2", middle: "23,70,17", end: "27,255,0" },
		  { start: "47,79,79", middle: "120,160,160", end: "140,160,180" }, // Forest green
		  { start: "0,100,0", middle: "50,205,50", end: "124,252,0" }, // Lime green
		  { start: "46,139,87", middle: "60,179,113", end: "144,238,144" }, // Medium sea green
		  { start: "34,139,34", middle: "50,205,50", end: "173,255,47" }, // Electric green
		  { start: "0,128,0", middle: "85,107,47", end: "107,142,35" }, // Olive green
		  { start: "102,205,170", middle: "32,178,170", end: "64,224,208" }, // Teal-inspired green
		  { start: "152,251,152", middle: "143,188,143", end: "127,255,0" }, // Mint green
		],
		neutral: [
		  { start: "47,79,79", middle: "112,128,144", end: "169,169,169" }, // Slate grey
		  { start: "105,105,105", middle: "119,136,153", end: "211,211,211" }, // Dark grey
		  { start: "169,169,169", middle: "192,192,192", end: "220,220,220" }, // Neutral grey
		  { start: "47,79,89", middle: "100,110,120", end: "140,150,160" }, // Charcoal grey
		  { start: "67,70,75", middle: "80,82,85", end: "135,138,140" }, // Graphite
		  { start: "60,60,60", middle: "80,80,80", end: "200,200,200" }, // Dim silver
		  { start: "80,90,100", middle: "120,120,120", end: "160,160,160" }, // Neutral slate
		],
		red: [
		  { start: "139,0,0", middle: "178,34,34", end: "255,0,0" }, // Crimson red
		  { start: "165,42,42", middle: "178,34,34", end: "255,0,0" }, // Blood red
		  { start: "128,0,0", middle: "139,69,19", end: "205,92,92" }, // Maroon
		  { start: "255,69,0", middle: "255,99,71", end: "255,160,122" }, // Electric red
		  { start: "199,21,133", middle: "219,112,147", end: "255,182,193" }, // Pinkish red
		  { start: "153,50,204", middle: "186,85,211", end: "255,20,147" }, // Magenta-leaning red
		  { start: "255,140,0", middle: "255,127,80", end: "255,99,71" }, // Fiery orange-red
		],
		pleasing: [
			{ start: "32,178,170", middle: "120,255,240", end: "0,255,255" }, // Sea Green to Radiant Aqua to Neon Cyan
			{ start: "138,43,226", middle: "186,85,255", end: "75,0,130" }, // Purple to Electric Violet to Indigo Glow
			{ start: "255,0,255", middle: "255,130,255", end: "240,0,240" }, // Magenta to Bright Lilac to Electric Fuchsia
			{ start: "255,69,0", middle: "255,160,80", end: "255,102,0" }, // Red-Orange to Vibrant Tangerine to Fiery Sunset
			{ start: "46,139,87", middle: "0,255,127", end: "50,205,50" }, // Forest Green to Neon Mint to Bright Lime
			{ start: "0,191,255", middle: "100,149,237", end: "70,130,180" }, // Deep Sky Blue to Cornflower Glow to Vibrant Steel Blue
			{ start: "255,20,147", middle: "255,105,180", end: "255,50,200" }, // Deep Pink to Hot Pink to Radiant Fuchsia
			{ start: "255,165,0", middle: "255,200,60", end: "255,140,0" }, // Vibrant Orange to Bright Gold to Neon Amber
			{ start: "255,99,71", middle: "255,50,50", end: "255,70,100" }, // Tomato to Fiery Red to Warm Coral
			{ start: "30,144,255", middle: "70,200,255", end: "0,128,255" }, // Dodger Blue to Bright Cyan to Neon Aqua
			{ start: "186,85,211", middle: "220,130,255", end: "200,0,255" }, // Orchid to Radiant Lavender to Neon Violet
			{ start: "255,215,0", middle: "255,240,50", end: "255,255,0" }, // Gold to Bright Lemon Glow to Neon Yellow
			{ start: "60,179,113", middle: "0,255,127", end: "124,252,0" }, // Medium Sea Green to Bright Mint to Neon Lime
			{ start: "255,0,0", middle: "255,50,50", end: "255,20,60" }, // Pure Red to Fiery Glow to Crimson Pulse
			{ start: "255,140,0", middle: "255,180,50", end: "255,70,0" }, // Bright Amber to Neon Gold to Fiery Orange
			{ start: "240,128,128", middle: "255,70,100", end: "255,0,50" }, // Coral to Bright Rosy Glow to Neon Red
			{ start: "102,205,170", middle: "127,255,212", end: "64,224,208" }, // Aquamarine to Vibrant Mint to Tropical Teal
			{ start: "186,85,211", middle: "220,20,255", end: "255,105,225" }, // Orchid to Neon Violet to Hot Magenta
			{ start: "144,238,144", middle: "0,255,0", end: "34,139,34" }, // Light Green to Pure Neon Green to Forest Glow
			{ start: "102,255,255", middle: "127,255,212", end: "0,255,255" }, // Neon Cyan to Aqua Glow to Bright Turquoise
			{ start: "210,105,30", middle: "255,140,60", end: "255,90,30" }, // Chocolate to Warm Tangerine to Bright Ochre
			{ start: "255,0,127", middle: "255,85,170", end: "255,0,255" }, // Shocking Pink to Vivid Rose to Radiant Magenta
		]
	};
	  
	  

	  
	  
	  
	  
  
	// const gradient = pleasingGradients[index % pleasingGradients.length];
	// const [r1, g1, b1] = gradient.start.split(",").map(Number);
	// const [r2, g2, b2] = gradient.end.split(",").map(Number);
  
	// // const colors = [];
	// // for (let i = 0; i < count; i++) {
	// //   const ratio = i / (count - 1);
	// //   const r = Math.round(r1 + (r2 - r1) * ratio);
	// //   const g = Math.round(g1 + (g2 - g1) * ratio);
	// //   const b = Math.round(b1 + (b2 - b1) * ratio);
	// //   colors.push(`${r},${g},${b}`);
	// // }

	// const colors = [];
	// for (let i = 0; i < count; i++) {
	// 	// Calculate ratio with a non-linear shift for more aggressive transitions (e.g., ease-in-out)
	// 	const ratio = Math.pow(i / (count - 1), 0.75); // Modify exponent for different effects

	// 	// Linear interpolation for each color channel
	// 	const r = Math.round(r1 + (r2 - r1) * ratio);
	// 	const g = Math.round(g1 + (g2 - g1) * ratio);
	// 	const b = Math.round(b1 + (b2 - b1) * ratio);

	// 	colors.push(`${r},${g},${b}`);
	// }
  
	// return colors;


	const gradient = colorGradients[type][index % colorGradients[type].length];
	const [r1, g1, b1] = gradient.start.split(",").map(Number); // Start color
	const [rm, gm, bm] = gradient.middle.split(",").map(Number); // Middle color
	const [r2, g2, b2] = gradient.end.split(",").map(Number); // End color

	const colors = [];
	const midpoint = Math.floor(count / 2); // Midpoint index for even split

	// Start → Middle
	for (let i = 0; i <= midpoint; i++) {
		const ratio = (i / midpoint) ** 0.75;
		// const ratio = Math.pow(i / midpoint, 0.75); // Adjust exponent for ease-in-out
		const r = Math.round(r1 + (rm - r1) * ratio);
		const g = Math.round(g1 + (gm - g1) * ratio);
		const b = Math.round(b1 + (bm - b1) * ratio);

		colors.push(`${r},${g},${b}`);
	}

	// Middle → End
	for (let i = 1; i < count - midpoint; i++) {
		const ratio = (i / midpoint) ** 0.75;
		// const ratio = Math.pow(i / (count - midpoint - 1), 0.75);
		const r = Math.round(rm + (r2 - rm) * ratio);
		const g = Math.round(gm + (g2 - gm) * ratio);
		const b = Math.round(bm + (b2 - bm) * ratio);

		colors.push(`${r},${g},${b}`);
	}

	return colors;
}

export const accessTiers = {
	"0xdex":{
		address: zeroxdexAddress,
		tiers:[
			{
				percent: .5,
				tier: 2
			},
			{
				percent: .1,
				tier: 1,
			},
			{
				percent: .01,
				tier: 0,
			}
		]
	},
	"usdc":{
		address: usdcAddress,
		tiers:[
			{
				percent: .5,
				tier: 2
			},
			{
				percent: .1,
				tier: 1,
			},
			{
				percent: .01,
				tier: 0,
			}
		]
	}
}
  

  