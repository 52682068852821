import React, { useEffect } from "react";


import {ReactComponent as UniswapLogo} from '../../icons/svgs/logo_uniswap.svg';
import {ReactComponent as ApeswapLogo} from '../../icons/svgs/logo_apeswap.svg';
import {ReactComponent as BiswapLogo} from '../../icons/svgs/logo_biswap.svg';
import {ReactComponent as SushiswapLogo} from "../../icons/svgs/logo_sushiswap.svg";
import {ReactComponent as FraxswapLogo} from "../../icons/svgs/logo_fraxswap.svg";
import {ReactComponent as PancakeLogo} from '../../icons/svgs/logo_pancakeswap.svg';
import {ReactComponent as ShibaswapLogo} from "../../icons/svgs/logo_shibaswap.svg";
import {ReactComponent as KibaswapLogo} from '../../icons/svgs/logo_kibaswap.svg';

import sushilogo from "../../icons/logo_sushi.png";
import sunswaplogo from "../../icons/logo_sunswap.png";
import otsealogo from "../../icons/swaps/logo_otsea.png";
import x7logo from "../../icons/logo_x7.png";
import dedustlogo from "../../icons/logo_dedust.png";



export function SwapIcon({ swap }) {


    const getIcon = () => {
        if(swap == "uniswap"){
            return (
                <img src={require(`../../icons/swaps/logo_uniswap.png`)}/>
            )
        }else if(swap=="sunswap"){
            return (
                <img src={sunswaplogo}/>
            )
        }else if(swap=="x7swap"){
            return (
                <img src={x7logo}/>
            )
        }else if(swap=="kibaswap"){
            return (
                <KibaswapLogo/>
            )
        }else if(swap=="otsea"){
            return (
                <img src={otsealogo}/>
            )
       
        }else if(swap=="ethervista"){
            return (
                <img src={require(`../../icons/logo_ethervista.png`)}/>
            )
        }else if(swap=="flowx"){
            return (
                <img src={require(`../../icons/logo_flowx.png`)}/>
            )
        }else if(swap=="bluemove"){
            return (
                <img src={require(`../../icons/logo_bluemove.png`)}/>
            )
        }else if(swap=="swapbased"){
            return (
                <img src={require(`../../icons/logo_swapbased.png`)}/>
            )
        }else if(swap=="stonfi"){
            return (
                <img src={require(`../../icons/logo_stonfi.png`)}/>
            )
        }else if(swap=="cetus"){
            return (
                <img src={require(`../../icons/logo_cetus.png`)}/>
            )
        }else if(swap=="turbos-finance"){
            return (
                <img src={require(`../../icons/logo_turbos-finance.png`)}/>
            )
        }else if(swap=="aerodrome"){
            return (
                <img src={require(`../../icons/logo_aerodrome.png`)}/>
            )
        }else if(swap=="fluxbeam"){
            return (
                <img src={require(`../../icons/logo_fluxbeam.png`)}/>
            )
        }else if(swap=="raydium"){
            return (
                <img src={require(`../../icons/logo_raydium.png`)}/>
            )
        }else if(swap=="meteora"){
            return (
                <img src={require(`../../icons/logo_meteora.png`)}/>
            )
        }else if(swap=="orca"){
            return (
                <img src={require(`../../icons/logo_orca.png`)}/>
            )
        }else if(swap=="pancakeswap"){
            return (
                <PancakeLogo/>
            )
        }else if(swap=="traderjoe"){

            return (
                <img src={require(`../../icons/logo_traderjoe.png`)}/>
            )

        }else if(swap=="mimo"){

            return (
                <img src={require(`../../icons/logo_mimo.png`)}/>
            )

        }else if(swap=="thruster"){

            return (
                <img src={require(`../../icons/logo_thruster.png`)}/>
            )

        }else if(swap=="sushiswap"){

            return (
                <img src={sushilogo}/>
            )

        }else if(swap=="shibaswap" || swap=="0xD9CE49caf7299DaF18ffFcB2b84a44fD33412509"){

            return (
                <ShibaswapLogo/>
            )

        }else if(swap=="fraxswap"){

            return (
                <FraxswapLogo/>
            )

        }else if(swap=="jupiterswap"){

            return (
                <img src={require(`../../icons/logo_jupiter.png`)}/>
            )

        }else if(swap=="raydiumswap"){

            return (
                <img src={require(`../../icons/logo_raydium.png`)}/>
            )

        }else if(swap=="baseswap"){

            return (
                <img src={require(`../../icons/logo_baseswap.png`)}/>
            )

        }else if(swap=="dedust"){

            return (
                <img src={require(`../../icons/logo_dedust.png`)}/>
            )

        }else if(swap=="apeswap"){

            return (
                <ApeswapLogo/>
            )

        }else{
            return (
                <p>{swap}</p>
            )
        }
    }



    return (

        <>
            {getIcon(swap)}
        </>

    )


}




