import React, { useState, useEffect, useCallback, useRef} from "react";

import { IconActionButton } from "../IconActionButton";

import { TokenDataTabs } from "../Dex/TokenDataTabs";

import { useCollection } from "../../hooks/useCollection";
import { useSocial } from "../../hooks/useSocial";
import { useWallet } from "../../hooks/useWallet";

import { useNavigator } from "../../hooks/useNavigator";

import classNames from "classnames";
import logo0x from "../../icons/logo_0xdex_faded.png";

export function BuyDexOption({ u, passClick, type="default", hideOptions=false }) {

    const navigator = useNavigator();



    const optionsref = useRef(null);

    useEffect(() => {

      
    }, [ ]);

    const optionsClick = () => {

    }


    return (

        
        <>
            <div style={{marginTop:1,padding:'12px 9px'}} className="options-header">
                <i className="icon-button-plus"/>
                features
            </div>
            <div style={{paddingLeft:9}}>
                <div style={{marginLeft:0,maxWidth:70}}>
                    <img style={{marginTop:0,maxWidth:'100%'}} src={logo0x} alt="0xdex logo" />
                </div>
                <p style={{fontSize:12,marginBottom:3,maxWidth:220,marginLeft:0,paddingRight:11}} className="no-wrap">These features are available by holding 1 Million+ 0xDex tokens and authenticating with your DeFi wallet.</p>
                <div className="flex-row align center">
                    <button onClick={() => navigator.push(`/ethereum/0xfFBc8746159cd0b7cD4ad70Bcb5662c1072682F5`)} style={{margin:0,marginTop:7}} className="tiny outline">view token</button>
                    <button onClick={() => window.open(process.env.REACT_APP_WEBSITE_URL)} style={{margin:0,marginTop:7,marginLeft:7}} className="tiny outline">view website</button>
                </div>
                
            </div>
            
        </>

    )
}







