import React, { useEffect } from "react";


import { useConnect, useAccount, useDisconnect, useChainId, useSignMessage, useSignTypedData, useSwitchChain } from 'wagmi';

import { client } from "../../services/api";

import { useAuth } from "../../hooks/useAuth";
import { useCore } from "../../hooks/useCore";
import { useWallet } from "../../hooks/useWallet";

import { useCookies } from 'react-cookie';

import web3 from 'web3';

import { IconActionButton } from "../IconActionButton";

import metalogo from "../../icons/logo_metamask.png";
import cbwlogo from "../../icons/logo_cbwallet.png";
import wconnectlogo from "../../icons/logo_walletconnect.png";

import { formatNumberScale } from "../../utils/currency";

import logo0x from "../../icons/logo_0xdex_faded.png";

import { fishEmojis } from "../../utils/data";

import { ConnectButton, WalletButton } from '@rainbow-me/rainbowkit';

import { authAdapter } from "../../utils/wagmi-config";

import { ethers, hashMessage, verifyMessage } from 'ethers';

var parse = require('html-react-parser');



export function WalletConnectComponent() {

	const { chains, switchChain } = useSwitchChain();

	const { connect, connectors, error, isLoading, pendingConnector } = useConnect();
    const { 
		isConnected,
		connector,
		address,
		status,
		chain,
		// chainId
	} = useAccount();

	const [cookies, setCookie] = useCookies(['zeroxdex']);


	// const { chainId } = useChainId();

	
	const { disconnect } = useDisconnect();

  
	let { walletModalVisible, handleWalletModalVisible, handleChain, blueCheck } = useCore();
	let {
		getWalletStatus,
		deactivateWallet,
		walletHistory,
		fetchBalances,
		handleSetWalletAddress,
		handleWalletStatus,
		authenticateWallet,
		isAuthenticated,
		walletAddress,
		whiteListed,
        zeroXPercent,
        zeroXBalance,
		connectorIcon,
		connectorName,
		chainId,
		dashboardAccess,
		user
	} = useWallet();


	// useEffect(() => {
	// 	console.log('window ethereum hook listener lets go', window.ethereum)
    // }, [ window.ethereum ]);
	//
	//


	const closeModal = () => {
		handleWalletModalVisible(false);
	}


	const handleConnect = (id) => {

		for(var i=0;i<connectors.length;i++){
			console.log(connectors[i])
		}

		const connector = connectors.find((c) => c.id === id);
		if (connector) {
		connect(connector);
		} else {
		console.error(`Connector with ID "${id}" not found`);
		}
		// console.log(connector)
		// connect(connector);  // This triggers the connection
	};

	// const { signMessage } = useSignMessage({
	// 	onSuccess(data) {
	// 	  console.log('Signed Message:', data);
	// 	},
	// 	onError(error) {
	// 	  console.error('Error signing message:', error);
	// 	},
	// });

	const { signMessageAsync } = useSignMessage();

	const domain = {
		name: "0xdex",                           // Name of your Dapp
		version: "1",                             // Version of the Dapp
		chainId: 1,                               // Chain ID (Ethereum mainnet = 1)
		verifyingContract: "0x0000000000000000000000000000000000000000",  // Optional, contract address
	};
	  
	// Define the types for the typed data
	const types = {
		EIP712Domain: [
		  { name: "name", type: "string" },       // Name of the Dapp
		  { name: "version", type: "string" },    // Version of the Dapp
		  { name: "chainId", type: "uint256" },  // Chain ID (Ethereum mainnet = 1)
		  { name: "verifyingContract", type: "address" },  // Verifying contract address (optional)
		],
		Message: [
			{ name: 'text', type: 'string' }
		]
	};
	
	// // Define the message data that will be signed
	// const messageData = {
	// 	sender: address,                          // Address to sign
	// 	message: "Please confirm your identity",  // The message content
	// };



	// const { signTypedDataAsync } = useSignTypedData();

	const { signTypedDataAsync } = useSignTypedData();

	const handleSignMessage = async () => {
		if (!isConnected) {
		  alert('Please connect your wallet first.');
		  return;
		}
	
		try {

			let n = await authAdapter.getNonce();
			let message = await authAdapter.createMessage({
				nonce: n,
				address
			});

			// const signature = await signMessageAsync({ message });

			let signature = null;
			// if(connector.id == "coinbase"){
				
			// 	let mdata = {
			// 		domain: {
			// 			chainId: 1, // Ethereum mainnet
			// 			name: '0xdex', // Name of your application
			// 			verifyingContract: '0x...' // Address of your smart contract (if applicable)
			// 		},
			// 		types,
			// 		primaryType: "EIP712Domain",
			// 		message: {
			// 			text: 'Hello, Wagmi community!' // The message you want to sign
			// 		}
			// 	}

			// 	// let mdata = {
			// 	// 	types,
			// 	// 	primaryType: 'Message',
			// 	// 	message: {
			// 	// 		sender: address,                         
			// 	// 		// message: message, 
			// 	// 	  	contents: message,
			// 	// 	},
			// 	// }
				
			// 	console.log(mdata)
				
			// 	signature = await signTypedDataAsync({typedData: mdata});

			// }else{

				signature = await signMessageAsync({ message });

			// }
			
			console.log("Signature:", signature);
			setCookie('sm',signature, { path: '/' });

			let v = await authAdapter.verify({
				message,
				signature,
				wa: address,
			});

			if(v){
				let jwt = v.data.jwt;
				client.api.setHeaders({ Authorization: `Bearer ${jwt}` });
				console.log('lets go', connector)
				authenticateWallet(address,connector.name,connector.id);
				setCookie('jwt',jwt, { path: '/' });
			}
				// Handle the signature (e.g., send to your backend for verification)
			


		} catch (error) {
		  console.error('Error during signing:', error);
		}
	};

    return (
        <div className="modal-overlay" onClick={closeModal}>
			<div className="wallet-modal" onClick={(e) => {e.preventDefault();e.stopPropagation();}}>

				<div style={{border:'1px solid #2d556c'}} className="wallet-content">

					<IconActionButton type="close" passClick={closeModal}/>

					<div style={{maxWidth:88}}>
                        <img style={{marginBottom:11,maxWidth:'100%'}} src={logo0x} alt="0xdex" />
                    </div>

					{ connectorIcon ? (
						<div className="flex-row align center">
							<div style={{marginRight:11}} className="green-circle"></div>
							<img style={{width:33,borderRadius:7}} src={connectorIcon}/>
							<h6 style={{margin:7}}>Connected with {connectorName}.</h6>
						</div>
						
					):null}
					
					<p style={{margin:0,marginTop:7}}>Your address</p>
					{/* <p style={{wordWrap:'break-word'}} className="small mute">{address}</p> */}
					<p style={{wordWrap:'break-word', filter: user && user.walletBlur ? "blur(4px)" : "none"}} className="small mute">{address}</p>

					{ dashboardAccess ? (
						<div style={{width:239,padding: '6px 9px 9px',marginBottom:11,borderTop:"1px solid #2b2b2b"}} className="data-panel data-row">
							<div style={{width:'100%'}} className="flex-column">
								<p style={{margin:0,fontSize:12,width:'100%'}} className="small mute">Access Tier</p>
								<p style={{margin:0,fontSize:15,fontWeight:"600"}}>
									{dashboardAccess.id=="gigachad" ? (
										<>
										GigaChad Pro <span className="green">+</span>
										</>
									):(
										<>
											{dashboardAccess.name}
										</>
									)}

									

								</p>
							</div>
							{ dashboardAccess.verified ? (
								<div className="flex-column">
									{blueCheck}
								</div>
							):null}
							
							
						</div>
					):null}
					
					
					{ zeroXPercent > 8 ? (
                        <div className="flex-column">
                            <div className="flex-row align center">
                                <div style={{fontSize:33,marginTop:-7,marginRight:11}} className="icon-check green"/>
                                <h1>Access allowed</h1>
                            </div>

                        </div>
                    ): !walletAddress && isConnected && !isAuthenticated && connector ? (
						<>
							

							

							{/* {zeroXPercent > .4 || whiteListed ? (
								
								<div className="flex-row align center">
									<div style={{fontSize:22,marginTop:0,marginRight:11}} className="icon-check green"/>
									<div className="data-value medium">Access allowed</div>
								</div>

							):null} */}

                            {/* {zeroXPercent > .001 && zeroXBalance ? ( */}
                                <div className="flex-row align center">
                                    <div style={{fontSize:22,marginTop:0,marginRight:11}} className={ zeroXPercent >= .01 ? "icon-check green" : "icon-device-tablet blue"}/>

                                        <div className="data-value medium">Wallet holds {parse(formatNumberScale(zeroXBalance))} <span className="small">$</span>0xdex.
										{/* <span className="small mute">{fishEmojis[fishEmojiKey]}</span> */}
										</div>

                                </div>
                            {/* ):null} */}
                            {whiteListed ? (
                                <div className="flex-row align center">
                                    <div style={{fontSize:22,marginTop:0,marginRight:11}} className="icon-check green"/>
                                        <div className="data-value medium">Wallet is whitelisted.</div>
                                </div>
                            ):null}


							<h2 style={{marginTop:22}}>Sign message to login and authenticate.</h2>

							<div className="flex-row align center">

								<button style={{width:"initial"}} className="outline" onClick={handleSignMessage}>
									Sign message
								</button>
								<button style={{width:"initial",marginLeft:22}} className="outline" onClick={() => { deactivateWallet(); }}>
									Disconnect
								</button>

							</div>
							

							
							
							{error && <p style={{ color: 'red' }}>{error.message}</p>}

							{/* <WalletButton wallet="rainbow" />
							<WalletButton wallet="metamask" />
							<WalletButton wallet="coinbase" /> */}
							
							


							

							{/* <button className="outline" onClick={ () => handleConnect("metaMask") }>
								<img src={metalogo}/>Metamask
							</button> */}

							

							
						</>
					):(
						<>
							<button style={{width:"initial"}} className="outline" onClick={() => { deactivateWallet(); }}>Disconnect</button>
						</>
					)}

					{/*<div>Connection Status: {active}</div>
				    <div>Account: {account}</div>
				    <div>Network ID: {chainId}</div>*/}
				</div>
			</div>
        </div>
    );
}
