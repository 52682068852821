import React, { useState, useMemo,useEffect } from "react";
import { useHistory, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useSettingsParams } from "./useSettingsParams";

export function useNavigator() {

    let navigate = useNavigate();
	const settingsParams = useSettingsParams();

    const [searchParams, setSearchParams] = useSearchParams();

    const { pathname } = useLocation();
    const [screen, setScreen] = useState(settingsParams);

    const [screens, setScreens] = useState([]);
    const [contractAddress, setContractAddress] = useState(null);

    const [params, setParams] = useState({});

    useEffect(() => {
        if(screen !== pathname){
            setScreen(pathname)
        }
        let pnarray = pathname.split('/');
        if (JSON.stringify(pnarray) !== JSON.stringify(screens)) {
            setScreens(pnarray);
        }
        // setScreens(pnarray);
        // for (const entry of searchParams.entries()) {
        //     console.log(entry);
        // }


    }, [ pathname ]);


    // useEffect(() => {
    //     // read the params on component load and when any changes occur
    //     const currentParams = Object.fromEntries([...searchParams]);
    //     // get new values on change
    //     console.log('useEffect:', currentParams);
    //     // update the search params programmatically
    //     // setSearchParams({ sort: 'name', order: 'ascending' });
    // }, [searchParams]);

    function handleSearchParams(params){

        setSearchParams(params);
    }


    function push(path) {
        setScreen(path);

        // console.log(pathname, path);
        // // history.push(`${pathname}?panel=${path}`);
        // let pname = '';
        // if( pathname.indexOf('/portfolio/') > -1){
        //     pname = pathname.split('/portfolio/')[0];
        // }else if( pathname.indexOf('/portfolio') > -1){
        //     pname = pathname.split('/portfolio')[0];
        // }else{
        //     pname = pathname;
        // }
        // // navigate(`${pname}/${path}`);
        navigate(`${path}`);
    }

    function back() {
        const stack = screen.split("/");

        // TODO: need to handle close if end of stack
        if (!stack.length > 1) return;

        const nextStack = stack.slice(0, -1);
        const nextPath = nextStack.join("/");

        push(nextPath);
    }

    function handleContractAddress(c){
        setContractAddress(c);
    }

    return {
        push,
        back,
        screen,
        screens,
        handleContractAddress,
        contractAddress,
        pathname,
        searchParams,
        handleSearchParams
    };
}
