import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';

import { client } from "./services/api";

import { useCookies } from 'react-cookie';
import { useWallet } from './hooks/useWallet';

import { walletSignMessage } from './utils/data';

import { ProvideAuth } from "./hooks/useAuth";
import { ProvideCore } from "./hooks/useCore";

import { CookiesProvider } from 'react-cookie';

import { WagmiProvider, useAccount, useConnect, useDisconnect } from 'wagmi';
import {
    // wagmiConfig,
    // rainbowConfig,
    // authenticationAdapter
} from './utils/wagmi-config';


import {
    RainbowKitProvider,
    RainbowKitAuthenticationProvider,
    darkTheme,
    midnightTheme,
} from '@rainbow-me/rainbowkit';

import merge from 'lodash.merge';

import { getApp } from "./utils/helpers";


const rTheme = merge(midnightTheme(), {

    colors: {
        // closeButton: '#1f5',
        // menuItemBackground: '#4f2',
        modalBackdrop: 'rgba(22,22,22,.84)',
        modalBackground: 'rgba(22,22,22,.94)',
        modalBorder: '#2d556c',
        modalText: '#ddd',
        // modalTextDim: '#eee',
        // modalTextSecondary: '#ddd',
    },
    fonts: {
        body: 'Saira',
    },
    
    shadows: {

        walletLogo: '0px 0px 1px 1px rgba(53, 53, 53, 0.4)',
    },
});

const GetApp = getApp();

export default function AppFoundation() {
    

    const [cookies, setCookie] = useCookies(['zeroxdex']);

    const { walletStatus, handleWalletStatus } = useWallet();
  
    const { disconnect } = useDisconnect();

    const authenticationAdapter = {

        getNonce: async () => {
           
            const response = await client.getNonce();
            // console.log(response)
            // setWalletStatus("loading");
            const { nonce } = await response.data;
            // console.log('Nonce received:', nonce);
            return nonce;
        },
      
        createMessage: ({ nonce, address }) => {
        //   console.log('Creating message with nonce:', nonce);
    
          let me = walletSignMessage.replace('[wa]',address).replace('[nonce]', nonce);
          return me;
        },
      
        verify: async ({ message, signature }) => {
            // console.log('Verifying message and signature...');
    
           
            // console.log(message, signature);
    
            let nd = {
                message,
                signature
            }
    
            let signv = await client.validateSign(nd);
            
            if(signv.ok && signv.data?.success){
                let jwt = signv.data.jwt;
                client.api.setHeaders({ Authorization: `Bearer ${jwt}` });
               
                setCookie('jwt',jwt, { path: '/' });
                handleWalletStatus("authenticated");
                return Boolean(true);
            }else{
                return false;
            }
    
        },

        signOut: async () => {
            // console.log('sign out...');
            disconnect();
    
        },
    };

    return (
		
      
        <RainbowKitAuthenticationProvider
            adapter={authenticationAdapter}
            status={walletStatus}
        >
            <RainbowKitProvider
                appInfo={{
                    appName: '0xdex',
                    learnMoreUrl: 'https://www.0xdex.ai',
                    // appIcon: 'https://www.0xdex.ai/logo192.png',
                }}
                // modalSize="compact"
                theme={rTheme}
            >
               
                        <>
                            <GetApp/>
                        </>
                        
                   
            </RainbowKitProvider>
        </RainbowKitAuthenticationProvider>
               
    );
}
