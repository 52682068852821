

//   ,--.           ,------.  ,------.,--.   ,--.
//  /    \,--.  ,--.|  .-.  \ |  .---' \  `.'  /
// |  ()  |\  `'  / |  |  \  :|  `--,   .'    \
//  \    / /  /.  \ |  '--'  /|  `---. /  .'.  \
//   `--' '--'  '--'`-------' `------''--'   '--'
//


import React from 'react';
import ReactDOM from 'react-dom/client';

import AppFoundation from "./AppFoundation";

import './index.css';

import reportWebVitals from './reportWebVitals';

import './bi_polyfill';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <AppFoundation/> 
);

// root.render(
//   <React.StrictMode>
//     <AppFoundation/>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
