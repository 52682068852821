import classNames from "classnames";
import React, { useState, useEffect, useRef } from "react";
import Toggle from 'react-toggle'

export function InputToggle({ title, value, field, onChange, shiftIconOpacity=true, icon=null, iconOff=null, iconColors=['#444','green'], horizontal=false, disabled=false }) {
    const [content, setContent] = useState(value || "");

    const inputField = useRef(null);

    useEffect(() => {
        // console.log(field, value)
        if(content !== value){
            setContent(value);
        }
    }, [ value ]);

    useEffect(() => {
        // console.log(field, value)
        if(content !== value){
            setContent(value);
        }
    }, []);


    function handleChange(e) {
        let rv = {};
        rv[field] = e.target.checked;
        // console.log(!e.target.value)
        onChange(rv);
    }

    return (
        <div className={classNames("input-group", disabled && "disabled")}>

            <div style={{maxWidth: 240}} className={`${horizontal ? "flex-row" : "flex-column"} justify space-between`}>

                {title && <label style={{ marginBottom: horizontal ? 0 : 9}}>{title}</label>}

                <div className="flex-row align center">
                    <Toggle
                        checked={Boolean(content)}
                        icons={false}
                        onChange={handleChange} />

                    { icon ? (
                        <>
                            { icon=="dot" ? (
                                <div style={{marginLeft:4, marginTop:6, transform:'scale(.9)', opacity: content || !shiftIconOpacity ? 1 : .2 }} className={classNames("green-circle large", content && "animated")}>
                                
                                </div>
                            ):(
                                <div style={{marginLeft:7, transform:'scale(.9)', opacity: content || !shiftIconOpacity ? 1 : .44 }} className="svg-icon small">
                                    { !content && iconOff ? (
                                        <div className={`${iconOff}`}></div>
                                    ):(
                                        <div className={`${icon}`}></div>
                                    )}
                                    
                                   
                                </div>
                            )}
                        </>
                        
                        
                    ):null}
                </div>
                


            </div>



                

        </div>
    );
}


