import React, { useState, useEffect } from "react";
import classNames from "classnames";

export function InputSelectMulti({
  title=null,
  subtitle=null,
  options=[],
  optionValues=[],
  images=[],
  optionsSelected=[],
  selected,
  onChange,
  addClass,
  addStyle={},
  addClassInputGroup,
  addStyleInputGroup=null,
  noChangeValues=[],
  addIcon=null,
  disabled=false
}) {
    
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
      setSelectedItems(optionsSelected)
    }, [ optionsSelected ] );



    

    // Handle the change event
    const handleChange = (event) => {
      const value = event.target.value;
    
      setSelectedItems((prevSelectedItems) => {
        // Check if value is already in the selectedItems array
        const isAlreadySelected = prevSelectedItems.includes(value);
    
        // Update selectedItems based on whether value is already selected or not
        const updatedSelectedItems = isAlreadySelected
          ? prevSelectedItems.filter((item) => item !== value)
          : [...prevSelectedItems, value];
    
        // Call the onChange prop with the updated selected items array
        onChange(updatedSelectedItems);
    
        return updatedSelectedItems;
      });
    };

    return (
      <div style={addStyleInputGroup ? addStyleInputGroup : {}} className={`input-group${addClassInputGroup ? " " + addClassInputGroup : ""}${disabled ? " disabled" : ""}`}>
        {title && <label style={{width:'100%',marginBottom: subtitle ? 0 : 7}}>{title}</label>}
        {subtitle && <label className="small" style={{fontSize:11,fontWeight:'500',opacity:.7,width:'100%'}}>{subtitle}</label>}
        {options.map((option, i) => (

          <div className={classNames("data-panel interactive", selectedItems.includes(optionValues[i]) && "active")} style={{marginRight:4,marginBottom:4, ...addStyle}} key={optionValues[i]}>
            <label style={{display:"flex",alignItems:'center',cursor:"pointer"}} >
              <input
                type="checkbox"
                value={optionValues[i]}
                checked={selectedItems.includes(optionValues[i]) ? true : false}
                onChange={handleChange}
                style={{marginRight:4}}
              />
              { images[i] !== undefined && images[i] ? (
                <img style={{marginLeft:0}} className="chain-icon" src={images[i]}/>
              ):null}
              {option}
            </label>
          </div>
          
        ))}

        {/* <div>
          <strong>Selected Items:</strong> {selectedItems.join(', ')}
        </div> */}
      </div>
    );
    
    
    
    // const [selectedTab, setSelectedTab] = useState(value || tabsValues[0]);

    // function handleChange(tabValue) {
    //     setSelectedTab(tabValue);
    //     onChange(tabValue);
    // }

    // return (
    //     <div className="segments">
    //         {tabs.map((tab, i) => (
    //             <button
    //                 key={i}
    //                 className={classNames(
    //                     "small",
    //                     selectedTab == tabsValues[i] ? "active" : "transparent"
    //                 )}
    //                 onClick={() => handleChange(tabsValues[i])}
    //             >
    //                 {tab}
    //             </button>
    //         ))}
    //     </div>
    // );
}
