import React, { useEffect, useState, useCallback, useRef } from "react";
// import { useNavigate, NavLink } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";
import { useSocial } from "../../hooks/useSocial";
import { useWallet } from "../../hooks/useWallet";
import { useDex } from "../../hooks/useDex";
import { useCore } from "../../hooks/useCore";
import { useNavigator } from "../../hooks/useNavigator";
import { Helmet } from "react-helmet-async";

import { Layout } from "../../components/Layout";
import { AppHome } from "../../components/Layout/AppHome";
import { PageHeader } from "../../components/Layout/PageHeader";
import { TickerBlock } from "../../components/Dex/TickerBlock";
import { DexSwap } from "../../components/Dex/DexSwap";
import { DexSwapDraggable } from "../../components/Dex/DexSwapDraggable";
import { FiltersPanel } from "../../components/Dex/FiltersPanel";
import { DexPairItemList } from "../../components/Dex/DexPairItemList";
import { UserPage } from "../../components/Social/UserPage";
import { Portfolio } from "../Portfolio";

import { ResizeColumns } from "../../components/Layout/ResizeColumns";

import { LoaderSpinner } from "../../components/Layout/LoaderSpinner";

import { MarketChart } from "../../components/MarketChart";

import ResizePanel from "react-resize-panel";

import { TwitterDashboard } from "../../components/Social/TwitterDashboard";

import { InputToggle } from "../../components/Input/InputToggle";
import { TokenList } from "../../components/Dex/TokenList";
import { PairList } from "../../components/Dex/PairList";

import { formatCurrency, condenseZerosTitle } from "../../utils/currency";

import { replaceSubDigits } from "../../utils/tools";
import { supportedChains, listViews, restrictedUsernames } from "../../utils/data";

import useSocketContext from '../../hooks/useSocketContext';

import classNames from "classnames";
import { DexMainMav } from "../../components/Dex/DexMainNav";

var parse = require('html-react-parser');

export function ContentDashboard() {

    const navigator = useNavigator();
    const { contractAddress, handleContractAddress, screens, pathname, handleSearchParams, searchParams } = useNavigator();

    let {
        dashboardAccess
    } = useWallet();
    let { 
        windowDimensions,
        handleSidebarRight,
        sidebarRight,
        isOnline,
        basePage,
        handleBasePage,
        listPage,
        handleListPage,
        ethTrending,
    } = useCore();

    let {
        fetchUserPage,
        userPage,
        userView,
        handleUserView,
        userSubView,
        handleUserSubView,
        fetchExploreUsers
    } = useSocial();

    const [ bottomSize, setBottomSize ] = useState("50%");

    const {
        token,
        caToken,
        newPairs, // depr
        tokenPairs, // depr
        fetchNewPairs,
        pairs,
        handleTokenPair,
        fetchTokenPair,
        loading,
        dexView,
        handleDexView,
        listView,
        handleListView,
        editFilters,
        tokenLists,
        listTokens,
        tokenList,
        handleTokenList,
        fetchTokenList,
        fetchExploreLists,
        dexViewChartInit,
        dexViewSwapInit,
        handleDexViewChartInit,
        handleDexViewSwapInit,
        ethTrendingKey,
        ethTrendingChain,
        handleEthTrendingKey,
        ethTrendingTokens,
        fetchEthTrendingTokens,
        showTicker,
        handleShowTicker
    } = useDex();


    const [ listType, setListType ] = useState(null);
    const pageWrapper = useRef(null);

    const [cpView, setCpView] = useState('');

    const [iTime, setITime] = useState(60000);

 
    const [loadingIframe, setLoadingIframe] = useState(false);

   
    useEffect(() => {
        const currentParams = Object.fromEntries([...searchParams]);
       
        setTimeout(() => {
            if(currentParams.view !== undefined){
                let cv = currentParams.view;
                if(cv == "chart" || cv == "swap"){
                    handleDexView(cv);
                    handleListView(currentParams.view);
                }else{
                    handleDexView("list");
                    handleListView(currentParams.view);
                }
                
                if(currentParams.tc !== undefined){
                    handleEthTrendingKey(currentParams.tc);
                }
               
                if(currentParams.list !== undefined){
                    handleListPage(currentParams.list);
                }
    
                setCpView(cv);
        
            }
        },20)
        

        // handleTokenFilters(currentParams);
    },[searchParams])

    useEffect(() => {

        if( ethTrending ){
            if(ethTrendingTokens && ethTrendingTokens.length > 30){

            }else{
                // console.log(' get eth trending  ')
                fetchEthTrendingTokens();
            }
            
        }

        // console.log(ethTrending,ethTrendingKey)

    }, [ ethTrending, ethTrendingChain ]);


    useEffect(() => {

        // console.log('do the work on the tokens',ethTrendingTokens)

    }, [  ]);



    useEffect( () => {

        let it = 60000;
        if(dashboardAccess?.speed > 0){
            it = 20000 + 40000/dashboardAccess?.speed;
        }
        setITime(it);
        if(listView == "explore"){
            getCurrentExplorerLists();
            getCurrentExplorerUsers();
            const interval = setInterval(getCurrentExplorerLists, it);
            return () => clearInterval(interval);
        }

        if(listView=="newpairs"){
            fetchNewPairs();
            const interval = setInterval(getNewPairs, it);
            return () => clearInterval(interval);
        }

    }, [ listView ]);

    const getCurrentExplorerLists = useCallback( async () => {
        fetchExploreLists();
    }, [listPage]);

    const getCurrentExplorerUsers = () => {
        fetchExploreUsers();
    }

    const getNewPairs = useCallback( async () => {
       
        // fetchTokenList(listPage);
    }, [listPage]);


    useEffect( () => {

        if(listPage){
            let it = 60000;
            if(dashboardAccess?.speed > 0){
                it = 20000 + 40000/dashboardAccess?.speed;
            }
            setITime(it);
            fetchTokenList(listPage);
            const interval = setInterval(getCurrentListDex, it);
            return () => clearInterval(interval);
        }

    }, [ listPage ]);

    

    const getCurrentListDex = useCallback( async () => {
        fetchTokenList(listPage);
    }, [listPage]);

    useEffect(() => {
        // console.log(screens)
        if(screens[1] == undefined || !screens[1].length){
            handleBasePage(null);
            
            handleDexView("list");
            handleListView("home");
            handleTokenPair(null);
        }else if(listViews.includes(screens[1])){
            
            if(token){
                handleTokenPair(null);
            }
            if(listViews.includes(screens[1])){

               
                handleDexView("list");
               
                handleBasePage(screens[1]);
                handleListView(screens[1]);
                handleSidebarRight(false);
                if(screens[1] == "trending" && screens[2] !== undefined){
                    handleEthTrendingKey(screens[2]);
                }
            }else{
                handleBasePage(null);
                handleDexView("list");
            }
            
        }else{
            let bp = screens[1];
            if( supportedChains.includes(screens[1]) ){
                
                bp = "token";
                handleBasePage("token");
               
                if(cpView == "chart" || cpView == "swap"){
                    setTimeout(() => {
                        handleDexView(cpView);
                    }, 20);
                }

                let p = screens[2].split("?")[0];
                if(token && token.pair?.address !== p){
                    handleTokenPair(null);
                }

            }else{
                // console.log('list update', screens[1]);

                if(token){
                    handleTokenPair(null);
                    handleSidebarRight(false);
                }

                if(!restrictedUsernames.includes(screens[1])){

                    let un = screens[1];
                    let uview = screens[2] !== undefined ? screens[2] : null;
                    let usview = screens[3] !== undefined ? screens[3] : null;
                    
                    if(listView !== "user" || userView !== uview || userSubView !== usview){
                        let q = un;
                        q = uview ? q+"/"+uview : q;
                        q = usview ? q + "/" + usview : q;
                        console.log(q);
                        fetchUserPage(q);
                    }
                    handleBasePage("user");
                    handleListView("user");
                    if(!uview){
                        uview = "lists"
                    }else if(usview && usview == "following"){
                        uview = "listsf"
                    }
                    handleUserView(uview);
                    handleUserSubView(usview);
                    
                    // console.log(un, 'lfg')

                }else{


                    handleBasePage(screens[1]);
                    handleListView(screens[1]);
                    if(screens[1] == "list" && screens[2] !== undefined){
                        handleListPage(screens[2]);
                    
                    }


                }
                
                
                
                
            }
        }

        if(supportedChains.includes(screens[1]) && screens[2] !== undefined){
            let p = screens[2].split("?")[0];
            if(!token || token.pair?.address !== p){
                handleTokenPair(null);
                if(!loading.dex){
                    // console.log('load it up')
                    fetchTokenPair(screens[1], p);
                }

            }
        }

    }, [ screens, listTokens]);


    useEffect(() => {
        if(dexView !== "chart"){
            handleDexViewChartInit(false);
        }
        if(dexView !== "swap"){
            handleDexViewSwapInit(false);
        }
    }, [ caToken ]);


    useEffect(() => {

        // if(listTokens && listTokens.length){
        //     const interval = setInterval(getCurrentDex, 60000);
        //     return () => clearInterval(interval);
        // }

    }, [ listTokens ]);


    const toggleBtmHeight = (newSize) => {
        setBottomSize(newSize + "px");
    }


    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          // handleSubmit();
        }
    }

    const closeTicker = () => {
        handleShowTicker(false);
    }

    const handleOnLoad = () => {
		// setLoadingIframe(false);
	}


    //↓ ↑

    return (
        <Layout stylePassPage={{ overflowY: 'hidden' }}>

           
            { showTicker && (

                <TickerBlock closeTicker={closeTicker} stylePass={{position:'relative'}}/>

            )}

            { (token && !sidebarRight && !loading.dex) ? (
                <PageHeader/>
            ):null}
                
           


            {/*{ tokenPair && (
                <>
                    {condenseZerosTitle(tokenPair.data.baseToken.symbol, tokenPair.data.priceUsd)}
                </>
            )}*/}

            <Helmet>

            </Helmet>


            {editFilters ? (
                <FiltersPanel/>
            ):null}

            <DexMainMav/>

            {token && token.pair?.data && dexViewChartInit && (

                <>
                    <div
                    className="dex-chart"
                    style={{
                        height: showTicker && !token ? 'calc( 100% - 320px )' : 'calc( 100% - 6px )',
                        display: dexView == "chart" ? 'flex' : 'none'}}
                    >
                        {token && token.pair.data !== undefined && (


                            <iframe onLoad={handleOnLoad} style={{height: 'calc( 100% - 6px )'}}
                            src={`https://dexscreener.com/${token.pair.data.chainId}/${token.pair.data.pairAddress}?theme=dark&embed=1&info=0`}
                            />

                        )}


                        {/* {token && token.pair.data !== undefined && (

                            <iframe 
                            onLoad={handleOnLoad} 
                            style={{height: 'calc( 100% - 6px )'}}
                            // width="100%" 
                            // height="600" 
                            src={`https://birdeye.so/tv-widget/${token.address}?chain=${token.pair.data.chainId}&viewMode=pair&chartInterval=15&chartType=Candle&chartTimezone=America%2FLos_Angeles&chartLeftToolbar=show&theme=dark`}
                            frameborder="0" 
                            allowfullscreen>
                            </iframe>

                        )} */}


                      
                        
                    </div>

                    <div className="dex-footer" style={{opacity:1,display: dexView == "chart" ? 'flex' : 'none'}}>

                        <div style={{width: 18,height: 18,opacity: .5,marginLeft: 5}} className="dex-icon">
                            <img style={{maxWidth:'100%',maxHeight:'100%'}} src={require(`../../icons/data/logo_dexscreener_mark.png`)}/>
                        </div>

                    </div>
                </>
            )}



            { token && !loading.dex && dexViewSwapInit && (
                <DexSwap visible={dexView == "swap"} token={token}/>
            )}

            {/* <TwitterDashboard visible={dexView == "social"}/> */}

            {/*{token && dexViewChartInit && !loading.dex && (

                <>
                    <div
                    className="dex-chart"
                    style={{
                        height: tickerBlock && !token ? 'calc( 100% - 320px )' : 'calc( 100% - 6px )',
                        display: dexView == "chart" ? 'flex' : 'none'}}
                    >
                        {token && token.pair.candles !== undefined && (

                            <MarketChart candles={token.pair.candles}/>
                        )}


                    </div>

                    <div className="dex-footer" style={{display: dexView == "chart" ? 'flex' : 'none'}}>

                        <div style={{width: 18,height: 18,opacity: .5,marginLeft: 5}} className="dex-icon">
                            <img style={{maxWidth:'100%',maxHeight:'100%'}} src={require(`../../icons/data/logo_dexscreener_mark.png`)}/>
                        </div>

                    </div>
                </>
            )}*/}

            

            {/*{ loading.dex ? (*/}

            {/*):null}*/}

            {/*<TokenList visible={dexView == "list"} height={bottomSize} tokens={listTokens}/>*/}
            
            
            {/* <h1>{dexView}{basePage}{listView}</h1> */}

            
            { dexView == "list" ? (
                 <div className={classNames("page-content-wrapper visible")} >

                    { loading.trending ? (
					    <LoaderSpinner/>
				    ):null}
                    
                    { loading.list ? (
                        <div className="load-panel">
                            <LoaderSpinner/>
                        </div>
                    ):null}
    
                    {/* { screens[2] == undefined && !screens[1]?.length && !loading.dex ? (
                        <AppHome visible={true}/>
                    ):null} */}

                    { basePage == "userasdf" && userPage ? (
                        <UserPage u={userPage}/>
                    ):(
                        <DexPairItemList
                        height={bottomSize}
                       
                        addClass="feature-panel"
                        />
                    )}

                    {/* <ResizeColumns/> */}
    
                    
                  
    
                </div>
            ):null}
           
            {/*<h1>{dexView}{listView}</h1>*/}
            

            {/* { (listView == "newpairs" || basePage == "newpairs") ? (
                <PairList visible={dexView=="list"} height={bottomSize} pairs={pairs}/>

            ):null} */}

           
            {/* { ethTrendingTokens[ethTrendingKey] ? (
                <PairList visible={dexView == "list"} height={bottomSize} pairs={ethTrendingTokens[ethTrendingKey]}/>
            ):null} */}
          

            {/*<p>++{dexView}++</p>*/}
            {/*<MarketChart candles={coinCurrent.candles == undefined ? [] : coinCurrent.candles }/>*/}

            {/* <SwapCurrency/>
            <CoinList height={bottomSize}/>*/}


        </Layout>
    );
}
