import React, { useEffect, useState, useRef } from "react";

import { useConnect, useAccount, useDisconnect, useChainId, useSignMessage, useSignTypedData, useSwitchChain } from 'wagmi';

import { useAuth } from "../../hooks/useAuth";
import { useCore } from "../../hooks/useCore";
import { useWallet } from "../../hooks/useWallet";

import classNames from "classnames";
import { toHex, chainData } from "../../utils/data";
import * as tools from "../../utils/tools";
import web3 from "web3";


export function ChainPanel() {
	
	const { chainPanel, handleChainPanel } = useCore();
	const { chains, switchChain } = useSwitchChain();
	

	const { handleSwitchChain, chainId } = useWallet();

	const chainp = useRef(null);


	function readClipboard() {
		navigator.clipboard.readText()
			.then(text => {
			console.log('Clipboard content:', text);
			})
			.catch(err => {
			console.error('Failed to read clipboard:', err);
			});
	}

	useEffect(() => {
		const checkIfClickedOutside = e => {
		  if (chainPanel && chainp.current && !chainp.current.contains(e.target)) {
		    handleChainPanel(false);
		  }
		//   readClipboard()
		}

		document.addEventListener("click", checkIfClickedOutside)

		return () => {
		  // Cleanup the event listener
		  document.removeEventListener("click", checkIfClickedOutside)
		}

		// console.log(chains)
	}, [chainPanel]);

	// example of switching or adding network with Harmony Mainnet
	


	

    return (
        <div ref={chainp} className={classNames('chain-panel', chainPanel && 'show')}>
			{chains.map((chain, i) => (
				<div style={{justifyContent:'space-between'}} onClick={() => switchChain({ chainId: chain.id })} className={classNames('chain-item', chainId == toHex(chain.id) && 'active')} key={i}>
					<div className="flex-row">
						<img
							className="chain-icon"
							src={require(`../../icons/logo_chain_${chain.name.split(" ").join("_").toLowerCase()}.png`)}
							// style={{ filter: chains[key].test!==undefined && chains[key].length ? 'saturation(.3);' : ''}}
							/>
						<p>{chain.name}</p>
						{ chainId == toHex(chain.id) && (
							<div className="active-green-circle"/>
						)}
					</div>

					

				</div>
			))}
        </div>
    );
}
