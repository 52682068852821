import React, { useState, useEffect, useRef } from "react";
import { useMarket } from "../../hooks/useMarket";
import { useDex } from "../../hooks/useDex";
import { useWallet } from "../../hooks/useWallet";
import { useCore } from "../../hooks/useCore";
import { useCollection } from "../../hooks/useCollection";
import { client_dexscreener } from "../../services/api_dexscreener";

import { useNavigator } from "../../hooks/useNavigator";

import { InputToggle } from "../Input/InputToggle";

import { MarketChartLineArea } from "../MarketChart/MarketChartLineArea";
import { TokenDataTabs } from "./TokenDataTabs";
import { TokenSymbolIcon } from "./TokenSymbolIcon";
import { TokenDataTabsInteractive } from "./TokenDataTabsInteractive";
import { IconActionButton } from "../IconActionButton";
import { TokenToolbar } from "./TokenToolbar";
import { ContractAddressToolbar } from "./ContractAddressToolbar";

import Blockies from 'react-blockies';

import moment from 'moment';

import { useCookies } from 'react-cookie';

import TimeAgo from 'react-timeago';
import timeFormat from '../../utils/timeago_format';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

import { formatCurrency, condenseZeros, formatPercent, formatCurrencyScale, formatNumberScale } from "../../utils/currency";
import { chainMeta, fishEmojis, eTrendingNames, swaps } from "../../utils/data";
import { burnAddresses, convertUnicode, createHyperlinks, addCommas, exponentToString, getTimeDiff, getTimeDisplay, openPage, zeroxdexAddress } from "../../utils/tools";
import honeypot from "../../icons/honeypot.png";
import classNames from "classnames";
import {ReactComponent as BubbleMaps} from '../../icons/svgs/bubblemaps.svg';
import {ReactComponent as GoogleIcon} from '../../icons/svgs/google.svg';
import {ReactComponent as WikiIcon} from '../../icons/svgs/wikipedia.svg';
import {ReactComponent as ShieldIcon} from '../../icons/svgs/tokensniffer.svg';
import {ReactComponent as WalletIcon} from '../../icons/svgs/wallet.svg';
import {ReactComponent as HealthIcon} from '../../icons/svgs/health.svg';
import {ReactComponent as SightsIcon} from "../../icons/svgs/sights.svg";
import {ReactComponent as MagGlassIcon} from "../../icons/svgs/magglass.svg";
import {ReactComponent as ScanIcon} from "../../icons/svgs/scan.svg";
import {ReactComponent as CandlesIcon} from "../../icons/svgs/candles.svg";
import {ReactComponent as ChartLineIcon} from '../../icons/svgs/chartlineicon.svg';


import {ReactComponent as TwitterIcon} from '../../icons/svgs/twitter.svg';
import {ReactComponent as TGIcon} from '../../icons/svgs/tg2.svg';
import {ReactComponent as BridgeIcon} from "../../icons/svgs/bridge.svg";
import {ReactComponent as XIcon} from '../../icons/svgs/x.svg';
import {ReactComponent as NotesIcon} from '../../icons/svgs/notes.svg';
import {ReactComponent as WaterDrop} from "../../icons/svgs/waterdrop.svg";
import {ReactComponent as Crown} from "../../icons/svgs/crown.svg";

import {ReactComponent as SwapButtonIcon} from "../../icons/svgs/swapicon.svg";

import { SwapIcon } from "./SwapIcon";

import tokensniffer from "../../icons/logo_tokensniffer.png";
import { BuyDexOption } from "./BuyDexOption";

var parse = require('html-react-parser');

export function TokenData({ token }) {

    const linksref = useRef(null);

    const [cookies, setCookie] = useCookies(['zeroxdex']);



    const navigator = useNavigator();
    let { contractAddress, handleContractAddress, screens } = useNavigator();

    

   

    let { coins, coinSymbols } = useMarket();
    let {
        balancesAlchemy,
        fetchTokenBalances,
        walletAddress,
        ethPrice,
        solPrice,
        user,
        tgSnipe
    } = useWallet();
    let { 
        handleModalVisible,
        handleTokenListModal,
        handleQrModal,
        handleQrurl,
        handleModalTitle,
        ethTrending,
        listPage,
        handleSidebarRight,
        handleMobileView
    } = useCore();

    let { handleShowCollectionsOptions, showCollectionsOptions, handleTokenListCollectionModal } = useCollection();
    
    let { handleTokenPair,
        closeTokenPair,
        dexUpdateTime,
        handleHealthCheck,
        handleEthTrendingKey,
        ethTrendingKey,
        trendingChain,
        handleTrendingChain,
        handleListView,
        ethFactor, // set to 1, to calculate estimates of eth value for tokens
        // tokenHealth,
        loading,
        swapVisible,
        handleSwapVisible,
        handleDexChartVisible,
        dexChartVisible,
        tokenLists,
        tokenListId,
        blankOwnerAddress,
        deadWalletAddress,
        handleDexView,
        dexView,
        listView,
        tokenDataTime,
        handleTokenDataTime,
    } = useDex();


    const [ tokenBalance, setTokenBalance ] = useState(0);
    const [ ethTrendingToken, setEthTrendingToken]= useState({
        bt: null,
        sg: null,
        tg: null,
        dt: null
    });

    const [ isTrending, setIsTrending] = useState(false);

    const [ priceInUsd, setPriceInUsd ] = useState(true);
    const [ liqPercent, setLiqPercent ] = useState(false);

    const [ showLinks, setShowLinks ] = useState("");


    const [ swapPopOut, setSwapPopOut] = useState(false);
    const [ darkMode, setDarkMode ] = useState(false);

    // const snipeRef = useRef(showSnipers);
    // const linksref = useRef(showLinks);

    useEffect(() => {
        linksref.current = showLinks;
    }, [showLinks]);

    const formatter = buildFormatter(timeFormat);
   
    useEffect(() => {

        if(token && walletAddress){
            let ca = `${token.pair.data.baseToken.address}:${token.pair.data.chainId}`
            fetchTokenBalances(walletAddress,ca);
            setEthTrendingToken({
                bt: null,
                sg: null,
                tg: null,
                dt: null
            });
        }

    }, [ token, walletAddress ] );

   

    useEffect(() => {
       
        
        let po = cookies.popout;
        let dm = cookies.darkmode;
       
        if(po && po == "true"){
            setSwapPopOut(true);
        }
        if(dm && dm == "true"){
            setDarkMode(true);
        }
        

    }, [  ] );



    useEffect(() => {
		const checkIfClickedOutside = e => {
            // e.preventDefault();
            // e.stopPropagation();
            
            // if ( showLinks.length && linksref.current && !linksref.current.contains(e.target)  ) {
            //     setShowLinks("");
            // }

          
        }

		document.addEventListener("mouseup", checkIfClickedOutside)

		return () => {
		  // Cleanup the event listener
		  document.removeEventListener("mouseup", checkIfClickedOutside)
		}

	}, [showLinks]);

    useEffect(() => {
        if(token && ethTrending ){

            let eTrending = {
              
            };
            let isTrending = false;
            for(var key in ethTrending){
                for(var i=0;i<ethTrending[key].length;i++){

                    if(ethTrending[key][i].pair){
                        // console.log(ethTrending[key][i].pair,token.pair.address);
                        if(ethTrending[key][i].pair.toLowerCase() == token.pair.address.toLowerCase()){
                            eTrending[key] = {
                                rank: (i+1),
                                startedAt: ethTrending[key][i].startedAt
                            };
                            isTrending = true;
                        }
                    }else if(ethTrending[key][i].token !== undefined){
                        // console.log(ethTrending[key][i].token,token.address, token.pair?.ta);
                        if(ethTrending[key][i].token?.toLowerCase() == token.address?.toLowerCase()
                          || ethTrending[key][i].token?.toLowerCase() == token.pair?.ta.toLowerCase() ){
                            eTrending[key] = {
                                rank: (i+1),
                                startedAt: ethTrending[key][i].startedAt
                            };
                            isTrending = true;
                        }
                    }
                }
            }

            setEthTrendingToken(eTrending);
            setIsTrending(isTrending);
        }

    }, [ ethTrending, token, walletAddress ] );

    // const token = tokenPair !== undefined && tokenPair.data !== undefined ? {...tokenPair.data} : null;

    const pairData = token.pair.data !== undefined ? { ...token.pair.data } : null;

    const tokenImageUrl = (token && token.pair.data.baseToken !== undefined && coins[(coinSymbols[token.pair.data.baseToken.symbol.toLowerCase()])] !== undefined)
        ? coins[(coinSymbols[token.pair.data.baseToken.symbol.toLowerCase()])].image : token && token.image !== undefined ? token.image : null;
        // : require(`../../icons/logo_chain_${chain.image}.png`);

    const onTokenDataTimeClick = (v) => {
        handleTokenDataTime(v);
    }

    const checkHealth = () => {
        handleHealthCheck(token.address);
    }

    function toggleLiqPercent(){
        setLiqPercent(!liqPercent);
    }

    function showAddList(e){

        handleTokenListCollectionModal(true);

        // console.log('whats up', showCollectionsOptions)

        if(tokenLists.length < 1 && walletAddress){

            // handleModalVisible(true);
        }else{
            if(showCollectionsOptions){
                // handleShowCollectionsOptions(false, "off","off");
            }else{
                // handleAssetToAdd(nft);
                //
                let ex = e.clientX;
                let ey = e.clientY + 33;
                handleShowCollectionsOptions(true, ex, ey );

            }
        }
    }

    const openTrending = (k) => {
        handleEthTrendingKey(k);
        handleTrendingChain(token.chainId);
        handleDexView("list");
        handleListView("trending");
    }

    const openLiquidityLock = () => {

    }

    const openExternalHealth = () => {
        window.open(
            `https://honeypot.is/ethereum?address=${token.address}`,
            '_blank',
            'toolbar=0,status=0,width=600,height=700,left=1000,top=100'
        );
    }

    
    const togglePriceMode = () => {
        setPriceInUsd(!priceInUsd);
    }

    const closeToken = () => {

        closeTokenPair();
        setTimeout(() => {
            handleSidebarRight(false);
            handleMobileView("page");
        },50)
        
        if(listView == "home"){
            navigator.push(`/`);
        }else if(listView == "list" && listPage){
           
            navigator.push(`/${listView}/${listPage}`);
        }else if(listView == "trending"){
            navigator.push(`/${listView}/${ethTrendingKey}`);
        }else{
            navigator.push(`/${listView}`);
        } 
       
    }

    const calculateBuyPercentage = (volumeUSD, liquidityUSD, percent) => {
        // Calculate the volume of buys and sells after the increase

        const liqBefore = liquidityUSD / (1+ (percent/100));
        let liqChange = liquidityUSD - liqBefore;
        let buyPercent = (volumeUSD/2 + liqChange) / volumeUSD;
    
        return buyPercent*100;
    }

    const hasLiquidity = token.pair.data.liquidity !== undefined && token.pair.data.liquidity.usd > 500;

    const renounced = ((token.owner == blankOwnerAddress || token.owner == deadWalletAddress));
    let td = token.decimals ? token.decimals : 6;
    const tb = pairData && pairData.baseToken !== undefined && balancesAlchemy[pairData.baseToken.address] !== undefined ? parseInt(balancesAlchemy[pairData.baseToken.address].balance) / (10 ** td) : 0;

    const tokenHealth = token && token.pair.health !== undefined ? {...token.pair.health} : null;

    const ethValue = !pairData ? 0 : token.pair.data && token.pair.data.quoteToken.symbol == "WETH" ? tb*pairData.priceNative*ethFactor : tb*pairData.priceNative*ethFactor;
    const solValue = !token || !token.pair || !token.pair.data ? 0 : ((tb * token.pair.data.priceUsd)/solPrice) / (1);

    const buyPercent = pairData.liquidity?.usd ? calculateBuyPercentage(pairData.volume[tokenDataTime], pairData.liquidity.usd, pairData.priceChange[tokenDataTime] ) : 0;

    const percentSupplyOwned = Math.round((tb/Number(token.totalSupply))*100000).toFixed(7)/1000;
    const fishEmojiKey = percentSupplyOwned > 1 ? 4 : percentSupplyOwned > .5 ? 3 :percentSupplyOwned > .2 ? 2 : percentSupplyOwned > .1 ? 1 : 0;
    const percentLiquidity = pairData.liquidity !== undefined ? ((pairData.liquidity.usd/pairData.fdv)*100).toFixed(2) : 0;

    const now = new Date();
    const createdT = getTimeDiff(now,token.pair.pairCreatedAt);

    const marketCap = pairData.fdv * (1 - token.burnedSupply/token.totalSupply);

    const tPrice = priceInUsd ? pairData.priceUsd : pairData.priceNative;

    const isEth = pairData.baseToken.symbol == "WETH";

    const swapDisabled = pairData.verified == false || pairData.liquidity == undefined || (pairData.liquidity.usd < 10) || (tokenHealth && (tokenHealth.isHoneypot || tokenHealth.sellTax > 50 || tokenHealth.buyTax > 50));

    const liqLockedPercent = token.pair.liquidityLockedAt ? ((token.pair.liquidityLock.amount/(10**18))/token.pair.liquidityTotalSupply)*100 : 0;

    const swapKeys = Object.keys(swaps)
    // .filter(k => token.address == zeroxdexAddress || user?.sniperWallets?.includes(k) )
    .filter(k => (!user || !walletAddress) || user?.sniperWallets?.includes(k) );

    const togglePopOut = (f,v) => {
        let val = f['popout'];
        setSwapPopOut(val);
       
        if(val == "false"){
            val = "true";
        }
        setCookie('popout',val, { path: '/' });
    }

    const toggleDarkMode = (f,v) => {
        let val = f['darkmode'];
        setDarkMode(val);
       
        if(val == "false"){
            val = "true";
        }
        setCookie('darkmode',val, { path: '/' });
    }
    const toggleShowLinks = (v) => {
       
        if(showLinks !== v){
            setShowLinks(v);
        }else{
            setShowLinks("")
        }
        
    }
    

    return (
        <>
            <div className="data-panel data-item data-row section-header" style={{justifyContent:'space-between',alignItems:'center',borderRadius:0,padding:'8px 13px',}}>
                <div style={{color: "#ddd",fontSize:11,fontWeight:'bold'}} className="data-value">


                    {pairData.baseToken.symbol} <span className="mute"> /</span> {pairData.quoteToken.symbol}

                    {pairData.labels !== undefined && pairData.labels.length > 0 && (
                        <span className="data-label outline" style={{marginLeft:7}}>
                            {pairData.labels[0]}
                        </span>
                    )}

             
                </div>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginRight:27}}>
                    <div data-tip={pairData.dexId} className="svg-icon raw swap-small">
                        <SwapIcon swap={pairData.dexId}/>
                    </div>
                    <img data-tip={pairData.chainId} style={{marginLeft:5}} className="chain-icon" src={require(`../../icons/logo_chain_${pairData.chainId}.png`)}/>

                    { pairData.baseToken.address.slice(-4) == "pump" ? (
                        <img data-tip={`pump fun`} style={{marginLeft:7}} className="chain-icon" src={require(`../../icons/trending/logo_majorsolpf.png`)}/>
                    ):null}
                    
                    <div onClick={closeToken} style={{marginLeft:11, position:'relative', transform: 'scale(.8'}} className="icon-action-button">
                        <div style={{fontSize:18}} className="icon-close"></div>
                    </div>

                </div>
             
                
            
                
                
            </div>


            { tb > 0.00001 && (
                <div className="token-balance-dex data-panel small">
                    <div className="data-item row full">

                        <div className="svg-icon">
                            <WalletIcon/>
                        </div>

                        { balancesAlchemy[pairData.baseToken.address] !== undefined && (
                            <div className="data-value currency">
                                <p className="token-balance">
                                    {token.totalSupply && percentSupplyOwned > .01 && (
                                        <span style={{fontSize:'.73em'}} className="small">{fishEmojis[fishEmojiKey]} {percentSupplyOwned}% &nbsp;</span>
                                    )}
                                    {tb > 100 ? (
                                        <>
                                            {parse(formatCurrencyScale(parseInt(Number(tb))))}
                                        </>
                                    ):(
                                        <>
                                            {tb.toFixed(2)}
                                        </>
                                    )}
                                    <span style={{marginLeft:7}} className="small">{pairData.baseToken.symbol}</span>
                                </p>
                                <div style={{marginTop:4,justifyContent:"flex-end"}} className="flex-row justify">
                                    <p style={{marginRight:17}} className="usd-balance">
                                       
                                        { token.pair.chainId == "solana" ? (
                                            <>
                                                {parse(formatCurrency((solValue*solPrice).toFixed(2), "USD"))}
                                            </>
                                        ):(
                                            <>
                                                {parse(formatCurrency((ethValue*ethPrice).toFixed(2), "USD"))}
                                            </>
                                        )}
                                    </p>
                                    <p style={{fontSize:11,opacity:.4,marginRight:17}} >::</p>
                                    <p style={{alignItems:'center'}} className={classNames("usd-balance", token.pair.chainId == "solana" && "solana no-prefix")}>
                                        {isEth ? (
                                            <>
                                                Ξ{tb.toFixed(2)}
                                            </>
                                        ): token.pair.chainId == "ethereum" || token.pair.chainId == "base" ? (
                                            <>
                                                {parse(condenseZeros(formatCurrency(ethValue > 1 ? (ethValue).toFixed(3) : (ethValue).toFixed(4), "ETH")))}
                                            </>
                                        ): token.pair.chainId == "solana" ? (
                                            <>
                                                <img style={{filter:'grayscale(0)',transform:'scale(.88)',opacity:.8}} className="chain-icon small" src={require(`../../icons/logo_chain_solana.png`)}/>
                                                {parse(condenseZeros(formatNumberScale(solValue > 1 ? solValue : solValue.toFixed(3))))} 
                                            </>
                                        ):null}

                                    </p>

                                </div>


                            </div>
                        )}
                    </div>
                </div>
            )}


            <div style={{padding: '10px 9px 10px 8px',margin:0,borderRadius:0}} className="data-panel active flex-row align center">
                

                { token && pairData.baseToken !== undefined && (
                    <TokenSymbolIcon
                      token={token}
                      ca={pairData.baseToken.address}
                      symbol={pairData.baseToken.symbol}
                      name={pairData.baseToken.name}
                      showCa={true}
                      boosts={pairData.boosts?.active ? pairData.boosts : false}
                      // addClass="large"
                      />
                )}



                <div className="coin-links">
                        
                    <div style={pairData.chainId == "solanatest" ? {pointerEvents:'none'} : {}} onClick={showAddList} data-tip={pairData.chainId == "solanatest" ? "in development" : "add to"} className={classNames("coin-link", showCollectionsOptions && "null")}>
                        <div id="showAddCollection" className="icon-ellipsis-vertical"/>
                    </div>
                    <div style={{marginLeft:4}} onClick={() => { dexView !== "chart" ? handleDexView("chart") : handleDexView("list")} } data-tip={dexView == "chart" ? "Hide Chart" : "View Chart"} className={classNames("coin-link active-border", dexView == "chart" && 'active')}>
                        <div style={{transform:'scale(1.2)', marginLeft:-2}} className="svg-icon">
        
                            <ChartLineIcon/>
        
                            {/* <Candles/> */}
        
                        </div>
                    </div>
                </div>

            </div>


            <div style={ token.pair.chainId == "ethereum" ? {marginBottom:0} : {}} className="action-bar flex-row tab-buttons full align center">

                {/* {pairData.chainId == "ethereum" ? (
                    <button data-tip="health check" style={{margin:0,filter:'saturate(0)'}} className="outline tight-margin" onClick={checkHealth}>
                        <div style={{maxWidth:18}} className="svg-icon no-margin">
                            <HealthIcon/>
                        </div>
                    </button>
                ):null}
                
                <button data-tip={`${pairData.chainId == "solana" ? "sol sniffer" : "token sniffer"}`}
                    style={{filter:'saturate(0)'}}
                    className="outline tight-margin"
                    onClick={ () => openPage(token,"sniffer") }>
                    { pairData.chainId == "solana" ? (
                            <div style={{ width: 32 }} className="svg-icon large">
                                <img style={{transform:"scale(1.22)"}} src={require(`../../icons/sniffers/solsniffer.png`)}/>
                        </div>
                    ):(
                        <div style={{ width: 32 }} className="svg-icon">
                            <ShieldIcon/>
                        </div>
                    )}
                    scan
                </button> */}


                <button data-tip={`bridge options`}
                    style={{}}
                    className={classNames("outline tight-margin", "toggle", showLinks == "bridge" && "active")}
                    // disabled={swapDisabled || sniperKeys.length < 1}
                    onClick={() => toggleShowLinks("bridge")} >
                        
                    
                    <div style={{width:29,height:29,marginRight:5}} className="svg-icon">
                        <BridgeIcon/>
                    </div>
                    {/* bridge */}
                   
                </button>


                <button data-tip={`scan options`}
                    style={{}}
                    className={classNames("outline tight-margin", "toggle", showLinks == "scan" && "active")}
                    // disabled={swapDisabled || sniperKeys.length < 1}
                  
                    onClick={() => toggleShowLinks("scan")} >
                    
                    <div style={{width:18}} className="svg-icon">
                        <ScanIcon/>
                    </div>
                    {/* scan */}
                    {/*{ user && user.tgSniper !== undefined ? (
                        <img style={{maxHeight: '15px',maxWidth: '15px !important'}} src={require(`../../icons/wallets/logo_${user.tgSniper}.png`)}/>
                    ):null}*/}
                </button>


               

                <button data-tip={`chart options`}
                    style={{}}
                    className={classNames("outline tight-margin", "toggle", showLinks == "chart" && "active")}
                    // disabled={swapDisabled || sniperKeys.length < 1}
                    onClick={() => toggleShowLinks("chart")} >
                        
                    
                    <div style={{width:29,height:34,marginTop:1}} className="svg-icon">
                        <CandlesIcon/>
                    </div>
                    {/* chart */}
                   
                </button>
                
               
                <button data-tip={`swap options`}
                    style={{}}
                    className={classNames("outline tight-margin", "toggle", showLinks == "swap" && "active")}
                    // disabled={swapDisabled || sniperKeys.length < 1}
                    onClick={() => toggleShowLinks("swap")} >
                    
                    <div style={{width:29,height:29,marginRight:3}} className="svg-icon">
                        <SwapButtonIcon/>
                    </div>
                    {/* swap */}
                   
                </button>
               
                

                {/* { pairData.liquidity !== undefined && (

                    <button data-tip="swap token" style={{minWidth:84,filter:'saturate(0)'}} onClick={() => {
                        if(dexView !== "swap"){
                            handleDexView('swap');
                        }else{
                            handleDexView('list');
                        }

                        }}
                        disabled={swapDisabled}

                        className={classNames("outline tight-margin", (loading.dex) && 'disabled')}>{dexView == "swap" ? 'In Swap' : 'Swap'}

                    </button>
                )} */}

                
        
                <div ref={linksref} className={classNames("tg-snipers data-column full", showLinks.length && "show")}>
                    <div className="flex-row" style={{flexDirection:"row",flexWrap:"wrap"}} >
                        {showLinks.length ? (
                            <>
                                {Object.keys(swaps[showLinks])
                                .filter(k => swaps[showLinks][k].chains.includes(pairData.chainId) || swaps[showLinks][k].chains.includes("all") )
                                .filter(k => !user || showLinks !== "swap" || user.sniperWallets.includes(k))
                                .filter(k => k !== "pumpfun" || k == "pumpfun" && token.address.slice(-4) == "pump" )
                                .map((k, i) => {
                                    
                                    return (

                                        <div key={`snipe${i}`} onClick={() => { tgSnipe(k, token.address, token.pair.address, pairData.chainId,showLinks,swapPopOut, darkMode);  }} className={classNames("chain-item", showLinks !== "swapp" ? "col-2" : "")} style={{maxHeight:45,justifyContent:'space-between', padding:8}}>
                                            <div style={{width:'100%'}} className="flex-row align center justify space-between  ">
                                                <div className="flex-row align center">
                                                    <img className="chain-icon wallet-icon" src={require(`../../icons/swaps/logo_${k}.png`)}/>
                                                    <p style={{fontSize: swaps[showLinks][k].name.length > 10 ? `${(.7 - .057*(swaps[showLinks][k].name?.length - 11 ))}rem` : swaps[showLinks][k].name.length > 10 ? 11 : 12 }} >{swaps[showLinks][k].name}</p>
                                                </div>
                                                <div style={{overflow:"auto",maxHeight:"48vh"}} className="flex-row align center">
                                                    {swaps[showLinks][k].types.map((t,i) => {
                                                        switch(t){
                                                            case "tg":
                                                                return <div key={`slicon${i}`} style={{width:14,height:14,marginRight:3,marginLeft:4,opacity:.77}} className="svg-icon"><TGIcon/></div>
                                                                break;
                                                            case "dashboard":
                                                                return <div key={`slicon${i}`} style={{marginLeft:3}} className="icon-device-desktop"/>
                                                                break;
                                                            case "chart":
                                                                return <div key={`slicon${i}`} style={{marginLeft:3}} className="icon-button-bar-chart-2"/>
                                                                break;
                                                            case "swap":
                                                                return(
                                                                    <div key={`slicon${i}`} style={{width:22}} className="svg-icon swap-small">
                                                                        <SwapButtonIcon/>
                                                                    </div>
                                                                )
                                                                break;
                                                            case "bridge":
                                                                return(
                                                                    <div key={`slicon${i}`} style={{width:22}} className="svg-icon swap-small">
                                                                        <BridgeIcon/>
                                                                    </div>
                                                                )
                                                                break;
                                                            case "scan":
                                                                return(
                                                                    <div key={`slicon${i}`} style={{width:12}} className="svg-icon swap-small">
                                                                        <MagGlassIcon/>
                                                                    </div>
                                                                )
                                                                break;
                                                            default:
                                                                return null;
                                                        }
                                                        return <p>{t}</p>
                                                    })}
                                                    {/* <div style={{borderRadius:3,display:"none",fontSize:11, fontWeight:"600"}} className="data-label outline">swap</div> */}
                                                </div>
                                                
                                            </div>
                                        </div>

                                    )
                                    
                                })}
                            </>
                        ):null}
                        
                    </div>
                    
                    <div className="flex-row">
                        <InputToggle
                        // title="pop out"
                        field="popout"
                        icon="icon-button-external-link"
                        horizontal={true}
                        value={swapPopOut}
                        onChange={togglePopOut}/>

                        { showLinks == "chart" ? (
                            <InputToggle
                            // title="pop out"
                            disabled={showLinks !== "chart"}
                            field="darkmode"
                            icon="icon-button-moon"
                            iconOff="icon-button-sun"
                            shiftIconOpacity={false}
                            horizontal={true}
                            value={darkMode}
                            onChange={toggleDarkMode}/>
                        ):null}
                        

                    </div>
                    
                        
                        
                    {/* ):(
                        <div style={{position:"relative"}} className="options-menu">
                            <BuyDexOption/>
                        </div>
                        
                    )} */}
                    

                </div>
               

                


            </div>


            <div style={{ opacity: loading.dex ? .3 : 1}} className="token-data">

                <div style={{width: '100%'}} className="data-column flex-column justify space-between">

                    { token.updated && (
                        <div style={{top:7,right:11,opacity:.77,position:"absolute"}} className="token-dex-update-timer flex-row align center">
                            {/* <div className="icon-button-refresh-cw"/> */}
                            <div style={{width:12,marginRight:7}} className="svg-icon">
                                <ScanIcon/>
                            </div>
                            <TimeAgo formatter={formatter} date={token.pair.lastProcessedAt} />
                        </div>
                    )}

                    <div style={{padding: '0px 11px 11px 11px',justifyContent: 'space-between'}} className="data-row right bookend">

                           

                            <div className="data-item" data-tip={`toggle price`} onClick={togglePriceMode} style={{flex:'none',cursor:'pointer',marginTop:12,marginLeft: 0}}>
                            <div style={{marginLeft:3}} className="data-title">price <span className="highlight">{priceInUsd ? 'USD' : token.pair.data.quoteToken.symbol }</span></div>
                            <div style={{marginTop:4,padding:'2px 8px 4px 7px'}} className="data-panel">

                                <div style={{color: "#ddd"}} className="price data-value x-large">

                                    {tPrice > .99 ? (
                                        <>
                                            {formatCurrency(tPrice, priceInUsd ? "USD" : "ETH")}
                                        </>

                                    ):(
                                        <>
                                            {parse(condenseZeros(formatCurrency(tPrice, priceInUsd ? "USD" : "ETH")))}
                                        </>

                                    )}
                                </div>

                            </div>

                        </div>


                        {/*
                        {tokenPair.cg && tokenPair.cg.market_data.ath !== undefined && tokenPair.cg.market_data.ath/token.priceUsd > 1.05 && (
                            <p style={{margin:0,fontWeight:'600',fontSize:11}}>{(tokenPair.cg.market_data.ath/token.priceUsd).toFixed(1)}x to ATH</p>
                        )}
                        <p>access:{tokenPair.accessCount}<br/></p>
                        <p>superDirty:{tokenPair.superDirty ? 'true' : 'false'}</p>*/}
                        <div className="data-item" style={{paddingTop:27,justifyContent:'space-between',display:'flex'}}>
                            { token.pair.priceChangedUpAt && token.pair.priceChangedDownAt && getTimeDiff(now, token.pair.priceChangedAt) < 48 && (
                                <>
                                    { token.pair.priceChangedUpAt > token.pair.priceChangedDownAt ? (
                                        <p style={{textAlign:"left",marginBottom:0,marginLeft:7,marginTop:11,fontWeight:'600',fontSize:11}}><span style={{fontSize:14,fontWeight:'600'}} className="green">↑</span> {getTimeDisplay(token.pair.priceChangedUpAt)}<br/><span style={{fontSize:14,fontWeight:'600'}} className="red">↓</span> {getTimeDisplay(token.pair.priceChangedDownAt)}</p>
                                    ):(
                                        <p style={{textAlign:"left", marginBottom:0,marginLeft:7,marginTop:11,fontWeight:'600',fontSize:11}}><span style={{fontSize:14,fontWeight:'600'}} className="red">↓</span> {getTimeDisplay(token.pair.priceChangedDownAt)}<br/><span style={{fontSize:14,fontWeight:'600'}} className="green">↑</span> {getTimeDisplay(token.pair.priceChangedUpAt)} </p>
                                    )}
                                </>
                            )}
                            
                        </div>


                        {/*<div className="data-item" style={{marginTop:12,marginLeft: 12}}>
                            <div className="data-title">{token.quoteToken.symbol}</div>
                            <div style={{color: "#ddd"}} className="price data-value medium">
                            {token.priceUsd > .99 ? (
                                <>
                                    {formatCurrency(token.priceNative).replace(/\.?0+$/, "")}
                                </>

                            ):(
                                <>
                                    {parse(condenseZeros(formatCurrency(token.priceNative, "ETH")))}
                                </>

                            )}
                            </div>
                        </div>*/}

                    </div>



                    <div style={{padding: '11px 7px',backgroundColor:"#2222225c",justifyContent: 'space-between'}} className="data-row right bookend">

                        <div className="data-item data-small">
                            <div className="data-title">fdv{(token.tokenBurn?.burnedSupply/token.totalSupply)*100 <= 0.5 ? (<><span className="mute"> / </span>mcap</>) : null}</div>
                                { token.pair.data.fdv !== undefined && (
                                    <div style={{color: "#ddd"}} className="data-value medium bold"><span className="small">$</span>{parse("" + formatCurrencyScale(token.pair.data.fdv))}</div>
                                )}
                        </div>

                        { (token.burnedSupply/token.totalSupply)*100 > 0.5 ? (
                            <div data-tip={`${((token.burnedSupply/token.totalSupply)*100).toFixed(3)}% supply burned`} className="data-item data-small">
                                <div className="data-title">mcap</div>
                                    { pairData.fdv !== undefined && (
                                        <div style={{color: "#ddd"}} className="data-value medium bold"><span className="small">$</span>{parse(`${formatCurrencyScale(marketCap)}`)}</div>
                                    )}
                            </div>
                        ):null}



                        <div style={{cursor:'pointer'}} onClick={toggleLiqPercent} className="data-item data-small" data-tip={`${percentLiquidity}% of fdv`}>
                            <div className="data-title">liq
                                { liqPercent && (
                                    <span style={{opacity:.55}} className="small">({percentLiquidity}%)</span>
                                )}

                            </div>
                                { !loading.dex && (
                                    <>
                                        { hasLiquidity ? (
                                            <div style={{pointerEvents: 'none',color: "#ddd"}} className="data-value medium bold">
                                                <span className="small">$</span>{parse(formatCurrencyScale(pairData.liquidity.usd))}
                                            </div>
                                        ): (
                                            <div className="caution-tag" style={{pointerEvents: 'none',marginTop: 4}}>
                                                no liq : ${pairData.liquidity?.usd ? Math.round(pairData.liquidity.usd) : "0"}
                                            </div>
                                        )}


                                    </>
                                )}


                        </div>

                        <div className="data-item data-small">
                            <div className="data-title">24h vol</div>
                            { pairData.volume !== undefined && (
                                <div style={{color: "#ddd"}} className="data-value medium bold"><span className="small">$</span>{parse(formatCurrencyScale(pairData.volume.h24))}</div>
                            )}

                        </div>
                    </div>
                </div>

                { pairData && pairData.priceChange !== undefined && (
                    <div className="flex-column align center" style={{width: '100%'}}>
                        <TokenDataTabsInteractive
                            title="price"
                            bookend={true}
                            passClick={onTokenDataTimeClick}
                            buttonValue={tokenDataTime}
                            buttonValues={['m5','h1','h6','h24']}
                            values={[pairData.priceChange?.m5 ? pairData.priceChange?.m5 : 0,
                                pairData.priceChange?.h1 ? pairData.priceChange?.h1 : 0,
                                pairData.priceChange?.h6 ? pairData.priceChange?.h6 : 0,
                                pairData.priceChange?.h24 ? pairData.priceChange?.h24 : 0]}
                            valuesFormat={['percent','percent','percent','percent']}
                            labels={['5m','1h','6h','24h']}
                            dataItemStyle={{minHeight:51,padding:'8px 11px 2px'}}
                        />
                        <TokenDataTabs
                            addClass="active"
                            title="price"
                            bookend={true}
                            values={ [(pairData.txns[tokenDataTime].buys + pairData.txns[tokenDataTime].sells),
                                pairData.txns[tokenDataTime].buys,
                                pairData.txns[tokenDataTime].sells,
                                pairData.volume[tokenDataTime]]}
                            valuesFormat={['number','number','number','currencymin']}
                            labels={['txs','buys','sells','vol']}
                        />
                        {}
                        <div style={{marginTop:0,marginBottom:4,position:"relative",width:'100%',padding:'7px 4px',background:"#222222b8"}} className="flex-row align center">
                            <p style={{margin:3,lineHeight:'9px', opacity: pairData.txns[tokenDataTime].buys > 0 || pairData.txns[tokenDataTime].sells > 0 ? 1 : .5}} className="data-value">txs</p>
                            <div className="tx-chart">
                                { pairData.txns[tokenDataTime].buys > 0 || pairData.txns[tokenDataTime].sells > 0 ? (
                                    <>
                                        <div className="tx-buys" style={{ width:`${Math.round(pairData.txns[tokenDataTime].buys/(pairData.txns[tokenDataTime].buys + pairData.txns[tokenDataTime].sells) * 100 )}%`}}/>
                                        <div className="tx-sells" style={{ width:`${Math.round(pairData.txns[tokenDataTime].sells/(pairData.txns[tokenDataTime].buys + pairData.txns[tokenDataTime].sells) * 100 )}%`}}/>
                                    </>
                                ):null}
                                
                            </div>
                        </div>
                        {/* <div style={{marginBottom:44,position:"relative",width:'100%',padding:'0 7px 0 7px'}} className="flex-row align center">
                            <p style={{margin:3,lineHeight:'9px'}} className="data-value">vol</p>
                            <div className="tx-chart">
                                <p>{buyPercent}</p>
                                <div className="tx-buys" style={{ width:`${Math.round(pairData.txns[tokenDataTime].buys/(pairData.txns[tokenDataTime].buys + pairData.txns[tokenDataTime].sells) * 100 )}%`}}/>
                                <div className="tx-sells" style={{ width:`${Math.round(pairData.txns[tokenDataTime].sells/(pairData.txns[tokenDataTime].buys + pairData.txns[tokenDataTime].sells) * 100 )}%`}}/>
                            </div>
                        </div> */}
                        
                    </div>
                )}

                {/*<div style={{width:'100%',minHeight:9}}/>
*/}

                

{/*  health Flags: [
    'all_snipers_honeypot',
    'medium_tax',
    'high_tax',
    'some_snipers_honeypot',
    'EXTREMELY_HIGH_TAXES',
    'ONE_SELL_PER_BLOCK_LIMIT',
    'EFFECTIVE_HONEYPOT_LOW_SELL_LIMIT'
    'low_fail_rate',
    'medium_fail_rate',
    'high_fail_rate',
    'LOW_SELL_LIMIT'
    'EXTREMELY_LOW_SELL_LIMIT' ]*/}




                { pairData.liquidity !== undefined && (

                    <>
                        {(tokenHealth || loading.health || (pairData.liquidity.usd < 10)) && (
                            <div style={{marginTop:8}} className="health-check">

                                {/* { !loading.health && token.pair.health && token.pair.chainId == "ethereum" ? (
                                    <div style={{paddingRight:44}} className="data-item">
                                        <div className="data-title">score</div>
                                        <div className="data-value large">
                                            {token.cg ? "100" : (token.pair.health?.isHoneypot || token.pair.health?.sellTax > 50 || !token.contractScore || token.contractScore < 2) ? "0" : token.contractScore}
                                        </div>
                                    </div>
                                ):null} */}



                                {loading.health && (
                                    <div style={{marginTop: 7,marginLeft: -8}} className="loader-spinner-element"></div>
                                )}

                                { token.pair.data.dexId == "ethervista" ? (
                                    <div data-tip={pairData.dexId} className="svg-icon raw swap-small">
                                        <SwapIcon swap={pairData.dexId}/>
                                    </div>
                                ):null}
                                

                                {!loading.health && tokenHealth && tokenHealth.buyTax !== undefined && token.pair.data.dexId !== "ethervista" && (
                                    <div className="honeylogo">
                                        {/*<img src={honeypot}/>*/}
                                        {/*<div style={{width:14,height:14}} className="svg-icon">
                                            <svg height="800px" width="800px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.001 512.001">
                                                <g>
                                                    <path d="M311.818,240.194c-3.666-6.348-10.437-10.258-17.767-10.258h-76.101c-7.33,0-14.103,3.91-17.767,10.258l-38.05,65.906
                                                        c-3.666,6.348-3.666,14.168,0,20.516l38.05,65.904c3.666,6.348,10.437,10.258,17.767,10.258h76.101
                                                        c7.33,0,14.103-3.91,17.767-10.258l38.049-65.904c3.666-6.348,3.666-14.168,0-20.516L311.818,240.194z M282.207,361.745h-52.41
                                                        l-26.206-45.388l26.206-45.39h52.41l26.204,45.39L282.207,361.745z"/>
                                                    <path d="M427.045,159.65v-14.992c18.739-7.813,31.946-26.318,31.946-47.854c0-28.576-23.249-51.825-51.825-51.825H197.478
                                                        l-38.97-38.97c-8.011-8.012-21.002-8.012-29.014,0c-8.012,8.011-8.012,21.002,0,29.014l9.956,9.956h-34.617
                                                        c-28.576,0-51.824,23.249-51.824,51.825c0,21.536,13.207,40.04,31.946,47.854v14.992
                                                        C38.487,205.375,12.015,268.26,12.015,333.631c0,65.076,25.318,126.282,71.288,172.346c3.847,3.856,9.074,6.023,14.521,6.023
                                                        h316.353c5.448,0,10.674-2.168,14.521-6.023c45.97-46.064,71.288-107.27,71.288-172.346
                                                        C499.986,268.26,473.514,205.375,427.045,159.65z M104.833,86.011h302.334c14.136,0,14.681,21.584-0.639,21.584H105.472h-0.639
                                                        c-5.951,0-10.791-4.842-10.791-10.793C94.042,90.851,98.884,86.011,104.833,86.011z M405.432,470.969H106.57
                                                        c-34.581-37.55-53.524-85.995-53.524-137.337c0-56.935,24.146-111.609,66.25-150.004c8.263-7.536,6.692-15.027,6.692-35h260.025
                                                        c0,19.65-1.659,27.385,6.692,35c42.103,38.395,66.25,93.069,66.25,150.004C458.954,384.974,440.012,433.419,405.432,470.969z"/>
                                                </g>
                                            </svg>
                                        </div>*/}
                                        <div className={classNames("active-green-circle", (tokenHealth.isHoneypot || tokenHealth.Error || pairData.liquidity.usd < 500) && 'red', (tokenHealth.buyTax > 5 || tokenHealth.sellTax > 5) && 'orange')             }>

                                        </div>
                                    </div>
                                )}

                                {!loading.health && tokenHealth && tokenHealth.buyTax !== undefined && token.pair.data.dexId !== "ethervista" && (
                                    <div className="flex-column">
                                        { tokenHealth.buyGas > 0 && (
                                            <>
                                                <div className="data-row">
                                                    <div className="data-title">tax </div>
                                                    <div className="data-value">
                                                        <span style={{color: tokenHealth.buyTax <= 5.1 ? 'inherit' : tokenHealth.buyTax < 10 ? 'orange' : 'red'}}>{Math.round(tokenHealth.buyTax)}</span>
                                                        <span style={{margin: '0 5px'}} className="mute">|</span>
                                                        <span style={{color: tokenHealth.sellTax <= 5.1 ? 'inherit' : tokenHealth.sellTax < 10 ? 'orange' : 'red'}}>{Math.round(tokenHealth.sellTax)}</span></div>
                                                </div>
                                                <div className="data-row">
                                                    <div className="data-title" style={{}}>gas </div>
                                                    <div className="data-value">
                                                        <span style={{color: tokenHealth.buyGas < 220000 ? 'inherit' : tokenHealth.buyGas < 280000 ? 'orange' : 'red'}}>{parse(formatCurrencyScale(tokenHealth.buyGas))} </span>
                                                        <span className="mute">&nbsp;|&nbsp;</span>
                                                        <span style={{color: tokenHealth.sellGas < 200000 ? 'inherit' : tokenHealth.sellGas < 300000 ? 'orange' : 'red'}}> {parse(formatCurrencyScale(tokenHealth.sellGas))}</span>
                                                    </div>
                                                </div>

                                            </>
                                        )}

                                        { !tokenHealth.isHoneypot && token.totalSupply && tokenHealth.MaxTxAmount > 0 && (
                                            <div className="data-row">
                                                <div className="data-title">max tx</div>
                                                <div className="data-value">{parse(formatPercent(((tokenHealth.MaxTxAmount)/token.totalSupply)*100, false, true))}</div>
                                            </div>

                                        )}

                                        { (tokenHealth.isHoneypot || pairData.liquidity.usd < 10 || (tokenHealth.Error && tokenHealth.Error.indexOf('fail') > 0 ) ) && (
                                            <>
                                                <div className="caution-tag orange">
                                                    caution
                                                </div>

                                                { tokenHealth.isHoneypot && !loading.dex && (
                                                    <div className="caution-tag">
                                                        honeypot
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        { token.pair.healthUpdatedAt && (
                                            <div style={{fontSize:'.64em',marginTop:4}}>
                                                <span className="mute">updated </span>
                                                {getTimeDisplay(token.pair.healthUpdatedAt)} {getTimeDiff(now,token.pair.healthUpdatedAt) >= 1/60 ? " ago" : ""}
                                                {token.pair.chainId == "ethereum" ? (
                                                     <div onClick={openExternalHealth} style={{display:"inline-block",minWidth:0,minHeight:0,marginLeft:7,cursor:'pointer'}} data-tip="open honeypot.is" className="coin-link">
                                                        <div style={{fontSize:13}} className="icon-pop-out"></div>
                                                    </div>
                                                ):null}
                                                   
                                                
                                                {/*<TimeAgo live={true} formatter={formatter} date={tokenPair.healthUpdatedAt} maxPeriod={Number.MAX_SAFE_INTEGER}/> ago*/}
                                            </div>
                                        )}

                                        { getTimeDiff(now,pairData.pairCreatedAt) < 24*7 ? (
                                            <>
                                                { token.pair.health.flags.map((f,i) => (
                                                    <p key={`f${i}`} style={{margin:0,fontSize:11,lineHeight:'12px',color:'red'}}>{f}</p>
                                                ))}
                                            </>
                                        ):null}
                                        
                                        


                                    </div>
                                )}

                            </div>
                        )}

                    </>
                )}

                { isTrending ? (

                    <div style={{marginBottom:11,marginTop:4}} className="data-column">
                        <div style={{marginLeft: (7 + (10*(Object.keys(ethTrendingToken).length % 4))),marginBottom:3}} className="data-title">trending</div>
                        <div style={{justifyContent: 'center', display: 'flex',flexWrap:'wrap'}} className="data-row">
                            {
                            Object.keys(ethTrendingToken)

                            // .sort((a, b) => {
                            //     if(listDataSort == "desc"){
                            //         return b.defaultPair.data.priceChange[`${listDataTime}`] > a.defaultPair.data.priceChange[`${listDataTime}`] ? 1 : -1;
                            //     }else{
                            //         return b.defaultPair.data.priceChange[`${listDataTime}`] < a.defaultPair.data.priceChange[`${listDataTime}`] ? 1 : -1;
                            //     }
                            // })
                            // Object.keys(Object.fromEntries(sortedTrendingBadges))
                            .map((key, i) => {

                                if(ethTrendingToken[key]){
                                    return (

                                        <div key={`trend${i}`}
                                        onClick={() => openTrending(key)}
                                        data-tip={`Trending #${ethTrendingToken[key].rank} on ${eTrendingNames[key]}`}
                                        style={{marginRight: 4, marginBottom:4, alignItems: 'center'}}
                                        className={classNames("data-panel micro interactive", ethTrendingKey == key && listView=="trending" && dexView=="list" && "active")}>

                                            <div className="data-column">
                                                <div className="flex-row" style={{alignItems:'center'}}>
                                                    <div style={{marginRight: 4}} key={`et-${i}`} className="data-tab-image">
                                                        <img src={require(`../../icons/trending/logo_${key}.png`)}/>
                                                    </div>
                                                    
                                                    <div style={{marginRight:0}} className={'rank-number rank-' + ethTrendingToken[key].rank }>
                                                        { ethTrendingToken[key].rank < 2 ? (
                                                            <div style={{left:2}} className="rank-crown">
                                                                <Crown/>
                                                            </div>
                                                        ):null}
                                                        {ethTrendingToken[key].rank}
                                                    </div>
                                                </div>

                                                <p style={{margin:0,fontSize:11,fontWeight:600}}>{getTimeDisplay(ethTrendingToken[key].startedAt)}</p>
                                            </div>

                                        </div>

                                    )
                                }

                            })}
                        </div>

                    </div>
                ):null}

                {/*{ tokenPair.tokenSnifferUpdatedAt && (

                    <div className="data-column">
                        <div className="data-label">tokensniffer</div>
                        <div className="data-vale">{tokenPair.tokenSniffer.score}</div>
                        { tokenPair.tokenSniffer.exploits !== undefined && (
                            <>
                                {tokenPair.tokenSniffer.exploits.map((e, i) => (
                                    <p>{e}</p>
                                ))}
                            </>
                        )}
                        <p className="small mute"></p>
                        <p>{getTimeDisplay(tokenPair.tokenSnifferUpdatedAt)} ago</p>
                    </div>
                )}*/}





                <div style={{
                    margin:'2px 0px',
                    width: "calc( 100% - 14px )",
                    padding: '11px 16px 6px 11px',
                    alignItems:'flex-start'
                    }}
                    className="data-panel column">
                    { token.cg || (token.contractUrls !== undefined) ? (
                        <TokenToolbar tp={token}/>
                    ):null}


                    { token.chainId == "ethereum" || token.chainId == "base" ? (
                        <div style={{margin:'11px 0px 7px 0px',padding:4}} className="data-item row">

                            { token.pair.health && token.pair.chainId == "ethereum" ? (
                                <div onClick={() => openPage(token,'holders')} style={{alignItems:'center',marginRight:11}} className="data-panel interactive align center">
                                    <div className="svg-icon">
                                        <div className="icon-button-user"></div>
                                    </div>
                                    <div style={{fontSize:11}} className="data-value">{addCommas(token.pair.health.totalHolders)}</div>
                                </div>
                            ):null}



                            <div className="svg-icon">
                                <div style={{marginRight:0,color: renounced ? "green" : "orange"}} className={renounced ? "icon-check" : "icon-button-alert-circle"}></div>
                            </div>
                            <div style={{marginLeft:3,marginRight:11,fontSize:11}} className="data-value">{renounced ? "renounced" : "renounced"}</div>

                            <div style={{marginLeft:4}} className="svg-icon">
                                <div style={{marginRight:0,color: token.verified ? "green" : "red"}} className={token.verified ? "icon-check" : "icon-button-alert-circle"}></div>

                            </div>
                            <div style={{marginLeft:3,fontSize:11}} className="data-value">{token.verified ? "verified" : "verified"}</div>

                        </div>

                    ):null}
                    
                    {!token.ownerOg && token.owner && token.owner !== "nofunction" && token.pair.liquidityAt && token.renouncedAt && token.listenedToLaunch && createdT < 24 && (
                        <div style={{margin:'7px 7px 7px 7px',padding:4}} className="data-item row">
                            <div style={{marginLeft:4}} className="svg-icon">
                                <div style={{marginRight:0,color: "red"}} className={"icon-button-alert-circle"}></div>

                            </div>
                            <div style={{marginLeft:3,fontSize:11}} className="data-value">launched renounced</div>
                        </div>
                    )}

                    {token.proxy && token.proxy !== "none" && (
                        <div style={{margin:'7px 7px 7px 7px',padding:4}} className="data-item row">
                            <div style={{marginLeft:4}} className="svg-icon">
                                <div style={{marginRight:0,color: "orange"}} className={"icon-button-alert-circle"}></div>

                            </div>
                            <div style={{marginLeft:7,fontSize:11}} className="data-value">proxy contract</div>
                        </div>
                    )}

                    {/*{token.assemblyInContract && (
                        <div style={{margin:'7px 7px 7px 7px',padding:4}} className="data-item row">
                            <div style={{marginLeft:4}} className="svg-icon">
                                <div style={{marginRight:0,color: "orange"}} className={"icon-button-alert-circle"}></div>

                            </div>
                            <div style={{marginLeft:7,fontSize:11}} className="data-value">assembly in contract</div>
                            <div className="data-value small">
                                {token.assemblyUses.map((v,i) => (<span className="assembly-use-value">{v}</span>))}
                            </div>
                        </div>
                    )}*/}


                    {(token.pair.liquidityLockedAt) && (
                        <div onClick={() => openPage(token,"liquiditylock")} style={{margin:'0px 0px 7px 0px',padding:4}} className="data-row interactive">
                            <div style={{padding:4,borderRadius:7}} className="data-item row">
                                <div style={{marginLeft:4}} className="svg-icon">
                                    <div style={{transform:'scale(.9)',marginRight:0,color: "green"}} className={"icon-button-lock"}></div>

                                </div>
                                <div style={{alignItems:'flex-start'}} className="data-column">

                                    <div style={{marginLeft:7,fontSize:11}} className="data-value"><span className="mute">liq locked &nbsp;</span> {parse(formatPercent(String(liqLockedPercent > 100 ? 100 : liqLockedPercent), false, true))} <span className="mute">in</span> <span className="first-capital">{token.pair.liquidityLock.lockedIn}</span></div>

                                    <div style={{marginLeft:7,fontSize:11}} className="data-value"><span className="mute">until</span> {moment(token.pair.liquidityLock.unLockDate*1000).format('DD MMM, YYYY')}</div>

                                </div>

                            </div>
                        </div>
                    )}


                        <div style={{margin:'0px 0px 7px 1px',alignItems:'flex-start',flexWrap:'flex-wrap',paddingLeft:0,paddingRight:7}} className="data-row">

                            { (token.pair.liquidityBurn && token.pair.liquidityBurn.burnedSupply !== undefined && token.pair.liquidityBurn.burnedSupply > 0.000001) && (
                                <div style={{marginRight:5,marginBottom:5,paddingLeft:0}} className="data-panel">
                                    <div style={{padding:4,borderRadius:7}} className="data-item row">
                                        <div style={{marginLeft:4,marginTop:2, width:18,height:18}} className="svg-icon green">
                                            <svg viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M433 45c50 134 24 207-32 265-61 64-156 112-223 206-89 125-104 400 217 472-135-71-164-277-18-406-38 125 32 205 119 176 85-29 141 32 139 102-1 48-20 89-69 112 209-37 293-210 293-342 0-174-155-198-77-344-93 8-125 69-116 169 6 66-63 111-114 81-41-25-40-73-4-109 77-76 107-251-115-382z" />
                                            </svg>

                                        </div>
                                        <div style={{alignItems:'flex-start'}} className="data-column">

                                            {/*<p>{tokenPair.liquidityBurn.burnedSupply0xd} - {tokenPair.liquidityBurn.burnedSupply0x0} - {tokenPair.liquidityBurn.burnedSupply} </p>*/}

                                            <div style={{marginLeft:7,fontSize:11}} className="data-value">
                                                <div style={{alignItems:'flex-start',marginLeft:1}} className="data-column">
                                                    <div style={{minWidth:90,lineHeight:'15px'}} className="flex-row align center">
                                                        <span className="mute">liq burned &nbsp;</span>
                                                        { token.pair.liquidityBurn.burnedSupplyPercent0x0 > 0.00001 && (
                                                            <span className="first-capital">{parse(formatPercent(String(token.pair.liquidityBurn.burnedSupplyPercent), false, true, true))}</span>
                                                        )}

                                                    </div>
                                                    <div style={{marginTop:4}} className="flex-row align center">
                                                        { token.pair.liquidityBurn.burnedSupplyPercent0xd > 0 && (
                                                            <>
                                                                <a onClick={() => openPage(token,"liquidityburnd")} >0xd</a>&nbsp;&nbsp;<span className="first-capital">{parse(formatPercent(String(token.pair.liquidityBurn.burnedSupplyPercent0xd), false, true, true))}</span>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div style={{marginTop:4}} className="flex-row align center">
                                                        { token.pair.liquidityBurn.burnedSupplyPercent0x0 > 0.00001 && (
                                                            <>
                                                                <a onClick={() => openPage(token,"liquidityburn0")} >0x0</a>&nbsp;&nbsp;<span className="first-capital">{parse(formatPercent(String(token.pair.liquidityBurn.burnedSupplyPercent0x0), false, true, true))}</span>
                                                            </>
                                                        )}

                                                    </div>

                                                    {/*{ tokenPair.liquidityBurn.burnedSupplyPercent0x0 > 0 && (
                                                        <div style={{marginTop:4}} className="flex-row align center">
                                                            <a onClick={() => openPage(tokenPair,""liquidityburn0")} >0x0</a>&nbsp;<span className="first-capital">{parse(formatPercent(String(tokenPair.liquidityBurn.burnedSupplyPercent0x0), false, true))}</span>
                                                        </div>
                                                    )}
        */}

                                                </div>
                                                {/*<span className="first-capital">{parse(formatPercent(String(tokenPair.liquidityBurn.burnedSupplyPercent0xd), false, true))}</span>
                                                <span className="first-capital">{parse(formatPercent(String(tokenPair.liquidityBurn.burnedSupplyPercent0x0), false, true))}</span>*/}
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            )}

                            <div onClick={() => openPage(token,'liquiditypool') } className="data-panel interactive">
                                <div className="data-item row">


                                    <div className="data-column">
                                    <div className="data-row">
                                        <div style={{opacity:.73, transform: "scale(.69)", marginRight:4}} className="svg-icon">
                                            <WaterDrop/>
                                        </div>
                                        <div style={{textAlign:"left",fontSize:11}} className="data-value">

                                            <span className="mute">pool</span>
                                        </div>
                                    </div>

                                    { token.pair.data.liquidity?.base ? (
                                        <>
                                             <div style={{padding:0}} className="data-row">
                                                <div style={{fontSize:11,whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}} className="data-value">
                                                    {token.pair.data.baseToken.symbol}<span className="mute"><span className="mute"> :</span></span>&nbsp; {parse(formatNumberScale(token.pair.data.liquidity.base))}
                                                </div>
                                            </div>
                                            <div style={{padding:0}} className="data-row">
                                                <div style={{fontSize:11}} className="data-value">
                                                    {token.pair.data.quoteToken.symbol}<span className="mute"><span className="mute"> :</span></span>&nbsp; {parse(formatNumberScale(token.pair.data.liquidity.quote))}
                                                </div>
                                            </div>
                                        </>
                                    ):null}
                                   

                                    </div>
                                </div>
                            </div>
                        </div>



                    <ContractAddressToolbar
                        label={`${token.pair.data.baseToken.symbol}`}
                        type="token"
                        chain={`${token.pair.chainId}`}
                        ca={`${token.pair.data.baseToken.address}`}/>

                    <ContractAddressToolbar
                        label="pair"
                        type="pair"
                        chain={`${token.pair.chainId}`}
                        ca={`${token.pair.data.pairAddress}`}/>


                    { token.ownerOg && ( !token.owner || token.owner == blankOwnerAddress || token.owner == deadWalletAddress ) ? (
                        <ContractAddressToolbar
                            label="creator"
                            type="address"
                            chain={`${token.pair.chainId}`}
                            ca={`${token.ownerOg}`}/>
                    ) : token.owner ? (
                        <ContractAddressToolbar
                            label="owner"
                            type="address"
                            chain={`${token.pair.chainId}`}
                            ca={`${token.owner}`}/>
                    ) : null}

                    
                    <ContractAddressToolbar
                        label={`${token.pair.data.quoteToken.symbol}`}
                        type="token"
                        chain={`${token.pair.chainId}`}
                        ca={`${token.pair.data.quoteToken.address}`}/>

                    <div style={{padding:'11px 0 0 11px'}} className="flex-row full coin-links justify space-between">
                        {token.symbol !== undefined && token.symbol && (
                            <div className="flex-row align center">
                                <div className="svg-icon">
                                    <div style={{fontSize:18,opacity:.6,marginRight:14}} className="icon-button-search"/>
                                </div>
                                <div onClick={() => openPage(token,'x') } data-tip={`x search : ${ token.symbol.charAt(0) == '$' ? token.symbol : '$' + token.symbol}`} className="coin-link">
                                    <div style={{width:14,height:14}} className="svg-icon">
                                        <XIcon/>
                                    </div>
                                </div>
                                <div onClick={() => openPage(token,'google') } data-tip={`google search : ${token.name}`} className="coin-link">
                                    <div style={{width:14,height:14}} className="svg-icon">
                                        <GoogleIcon/>
                                    </div>
                                </div>
                                <div onClick={() => openPage(token,'wiki') } data-tip={`wiki search : ${token.name}`} className="coin-link">
                                    <div style={{width:22,height:22}} className="svg-icon">
                                        <WikiIcon/>
                                    </div>
                                </div>
                            </div>

                        )}



                    </div>

                </div>



                <div style={{width:'100%',height:25}}/>


                {!loading.dex && (
                    <div style={{paddingTop:33,position:'relative'}} className="contract-code small">

                        { token.totalSupply && (
                            <>
                                Token Total Supply: {parse(' ' + formatNumberScale(token.totalSupply/10**6))}<br/>
                              
                                { token.tokenBurn ? (
                                    <>
                                        { Number(token.tokenBurn.burnedSupply) > .001 && (token.chainId == "ethereum" || token.chainId == "base") ? (
                                            <>
                                                Tokens burned:
                                                { token.tokenBurn.burnedSupply0xd > 0 && token.tokenBurn.burnedSupply0x0 > 0 && (
                                                    <>
                                                        {token.tokenBurn.burnedSupplyPercent.toFixed(3)}<span className="small">%</span>
                                                    </>
                                                )}
                                                {/* {parse(formatCurrencyScale(parseInt(Number(tokenPair.tokenBurn.burnedSupply))))} <span className="mute">|</span> {tokenPair.tokenBurn.burnedSupplyPercent.toFixed(3)}<span className="small">%</span>*/}
                                                { token.tokenBurn.burnedSupply0xd > 0 && (
                                                    <>
                                                        <br/>{parse(`<a target="_blank" href="https://etherscan.io/token/${token.address}?a=${burnAddresses[0]}">0xd</a>`)}: {parse(""+formatCurrencyScale(token.tokenBurn.burnedSupply0xd))} <span className="mute">|</span> {token.tokenBurn.burnedSupplyPercent0xd.toFixed(3)}<span className="small">%</span>
                                                    </>
                                                ) }
                                                { token.tokenBurn.burnedSupply0x0 > 0 && (
                                                    <>
                                                        <br/>{parse(`<a target="_blank" href="https://etherscan.io/token/${token.address}?a=${burnAddresses[1]}">0x0</a>`)}: {parse(""+formatCurrencyScale(token.tokenBurn.burnedSupply0x0))} <span className="mute">|</span> {token.tokenBurn.burnedSupplyPercent0x0.toFixed(3)}<span className="small">%</span>
                                                    </>
                                                ) }

                                            </>
                                        ):(
                                            <>
                                                Burned Supply: <span className="mute">--</span>
                                            </>
                                        )}
                                    </>
                                ):null}
                                

                            </>
                        )}
                        <br/>
                        {/*{ token.pair.health?.MaxTxAmount && (token.pair.health.MaxTxAmount) / token.totalSupply < 1 ? (
                            <>
                                Max Tx: {parse("" + formatCurrencyScale(parseInt(Number(token.pair.health.MaxTxAmount))))} <span className="mute">-</span> {(((token.pair.health.MaxTxAmount)/token.totalSupply)*100).toFixed(1)}<span className="small">%</span>
                            </>
                        ):null}*/}

                        { token.maxWallet && (token.maxWallet) / token.totalSupply < 1 ? (
                            <>
                                Max Wallet: {parse(formatCurrencyScale(parseInt(Number(token.maxWallet))))} <span className="mute">-</span> {(((token.maxWallet)/token.totalSupply)*100).toFixed(1)}<span className="small">%</span>
                            </>
                        ):null}
                        
                        <div style={{position:'absolute',top:5,left:5,transform:'scale(.5)'}} className="svg-icon">
                            <NotesIcon/>
                        </div>

                        {token.contractComments && token.contractComments.length ? (
                            <>
                                 {parse(createHyperlinks(convertUnicode(token.contractComments)))}<br/>
                            </>
                        ):null}
                       
                        { token.cg && token.cg.description !== undefined ? (
                            <><br/>{token.cg.description}</>
                        ):null}
                        {/* {token.contractFilesMeta[0] !== undefined && (
                            <>
                                    {token.contractFilesMeta[0].functions}<span className="small mute">functions</span> {token.contractFilesMeta[0].lines}<span className="small mute">lines</span>
                            </>
                        )} */}

                    </div>
                )}

            </div>

            <div className="token-pair-footer">

                { pairData.pairCreatedAt && pairData.pairCreatedAt !== undefined ? (
                    <>
                        <div>

                            {token.pair.liquidityAt !== undefined && token.pair.liquidityAt && token.pair.liquidityAt !== token.pair.pairCreatedAt && (
                                <>
                                    <span style={{marginRight:4}} className="mute">liq</span> {getTimeDisplay(token.pair.liquidityAt)} {getTimeDiff(now,token.pair.liquidityAt) >= 1/60 && "ago"}
                                    <span className="small mute">&nbsp;&nbsp; :: &nbsp;&nbsp;</span>
                                </>
                            )}
                            { pairData.pairCreatedAt && (
                                <><span style={{marginRight:4}} className="mute">pair created</span> {getTimeDisplay(pairData.pairCreatedAt)} {getTimeDiff(now,pairData.pairCreatedAt) >= 1/60 && "ago"}</>
                            ) }

                        </div>
                    </>
                ):(
                    <div>
                        <span className="mute">no pair created data</span>
                    </div>
                )}

                { pairData.pairCreatedAt && pairData.pairCreatedAt !== undefined && (
                    <div style={{marginTop:4}}>
                        {moment(pairData.pairCreatedAt).format('MMM D')}<span className="mute">,&nbsp;</span>{moment(pairData.pairCreatedAt).format('YYYY')} <span className="mute"> &nbsp;&nbsp;|&nbsp;&nbsp; </span> {moment(pairData.pairCreatedAt).format('h:mm A')}
                    </div>
                )}


            </div>
        </>
    );
}
