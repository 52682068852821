import React from "react";

import { useDex } from "../../hooks/useDex";
import { useCore } from "../../hooks/useCore";

export function FeatureTable({features = []}) {


    const {
        handleTokenOrder,
        handleTokenFilter,
        tokenOrder,
        tokenFilter
    } = useDex();

    const {
        blueCheck
    } = useCore();

   
    // const selectcats = tokenCats.map((cat, i) => {
    //     return cat.name;
    // });
    // const selectcatsvalues = tokenCats.map((cat, i) => {
    //     return cat.id;
    // });

    const greenCheck = ( <div className="icon-button-check green"/>);
    

    return (
        <div className="feature-table-container">
           
            <table className="feature-table">
                <thead>
                <tr>
                    <th></th>
                    <th>Free<p>for plebs</p></th>
                    <th>Degen<p style={{margin:0}}><span style={{fontWeight:'600'}}>100k 0xdex</span> tokens</p></th>
                    <th>Degen Pro<p style={{margin:0}}><span style={{fontWeight:'600'}}>1 Million 0xdex</span> tokens</p></th>
                    <th>GigaChad Pro<span className="green">+</span><p style={{margin:0}}><span style={{fontWeight:'600'}}>5 Million 0xdex</span> tokens</p></th>
                </tr>
                </thead>
                <tbody>
                {features.map((feature, index) => (
                    <tr key={index}>
                    <td>{feature.name}</td>
                    <td>{feature.free == "check" ? greenCheck : feature.free == "bluecheck" ? blueCheck : feature.free ? feature.free : "✖️"}</td>
                    <td>{feature.degen == "check" ? greenCheck : feature.degen == "bluecheck" ? blueCheck : feature.degen ? feature.degen : "✖️"}</td>
                    <td>{feature.degenpro == "check" ? greenCheck : feature.degenpro == "bluecheck" ? blueCheck : feature.degenpro ? feature.degenpro : "✖️"}</td>
                    <td>{feature.gigachad == "check" ? greenCheck : feature.gigachad == "bluecheck" ? blueCheck : feature.gigachad ? feature.gigachad : "✖️"}</td>
                   
                    </tr>
                ))}
                </tbody>
            </table>
           
            
        </div>
    );
}
