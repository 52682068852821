// import { getUA } from "react-device-detect";

// var UAParser = require("ua-parser-js");

import { useAccount } from 'wagmi';

import { chainMeta } from './data';

var parse = require('html-react-parser');

export const emailre = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

export const phonere = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/i;
export const intlphonere = /^\+(?:[0-9] ?){6,14}[0-9]$/;

export const blankAddress = "0x0000000000000000000000000000000000000000";
export const deadAddress = "0x000000000000000000000000000000000000dEaD";
export const wethAddress = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
export const usdcAddress = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
export const usdtAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
export const daiAddress = "0x6B175474E89094C44Da98b954EedeAC495271d0F";
export const zeroxdexAddress = "0xB2Cfc9A054562BCfD854daD55a99E8BC8085A480";

export const usdcAddressSol = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v";
export const usdtAddressSol = "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB";
export const usdbcAddressBase = "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA";
export const usdcAddressBase = "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913";
export const daiAddressBase = "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb";



export const useWalletAddress = () => {
  const { address, isConnecting, isDisconnected } = useAccount();
  return { address, isConnecting, isDisconnected };
};

export const stableTokens = {
  "ethereum":{
    usdc: usdcAddress,
    usdt: usdtAddress,
    dai: daiAddress
  },
  "base":{
    usdc: usdcAddressBase,
    usdbc: usdbcAddressBase,
    dai: daiAddressBase
  },
  "solana":{
    usdc: usdcAddressSol,
    usdt: usdtAddressSol
  }
}

export const ethBurnAddresses = [
    "0x0000000000000000000000000000000000000000dead",
    "0x0000000000000000000000000000000000000000",
    "0x0000...0000",
    "0x0",
];

export const burnAddresses = [
    '0x000000000000000000000000000000000000dEaD',
    '0x0000000000000000000000000000000000000000'
]




export function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function unatomic(value, decimals) {
  value = value.padStart(decimals + 1, "0");
  let temp =
    value.substr(0, value.length - decimals) +
    "." +
    value.substr(value.length - decimals);
  while (temp[0] === "0") {
    temp = temp.substr(1);
  }
  while (temp.endsWith("0")) {
    temp = temp.slice(0, -1);
  }
  if (temp.endsWith(".")) {
    temp = temp.slice(0, -1);
  }
  if (temp.startsWith(".")) {
    temp = "0" + temp;
  }

  if (temp === "") {
    return "0";
  }
  return temp;
}




// export function getUniqueFingerprint(prefix = "") {
//     const ua = getUA;
//     let parser = new UAParser(ua);
//     parser.setUA(ua);
//     const uaParsed = parser.getResult();
//     let uastring = uaParsed.ua.replace(/\s+/g, "").replace(/\//g, "");
//
//     var canvas = document.createElement("canvas");
//     var context = canvas.getContext("2d");
//     context.fillText("Artemis", 20, 20);
//     var dataURL = canvas.toDataURL("image/png").split(",")[1];
//
//     let fingerprint =
//         prefix + "|" + uastring + "|" + dataURL.replace(/\//g, "");
//     return fingerprint;
// }

export function getParentURL() {
    const parentDomain =
        window.location !== window.parent.location
            ? document.referrer
            : document.location.href;

    const parentIframeURL = new URL(parentDomain);
    return parentIframeURL;
}

export function inIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export function isFunction(functionToCheck) {
    return (
        functionToCheck &&
        {}.toString.call(functionToCheck) === "[object Function]"
    );
}

export function lsTest() {
    var test = "test";
    try {
        localStorage.setItem(test, test);

        localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
}


export function toHex(s)
{
    if (s.substr(0,2).toLowerCase() == "0x") {
        return s;
    }

    var l = "0123456789ABCDEF";
    var o = "";

    if (typeof s != "string") {
        s = s.toString();
    }
    for (var i=0; i<s.length; i++) {
        var c = s.charCodeAt(i);

        o = o + l.substr((c>>4),1) + l.substr((c & 0x0f),1);
    }

    return "0x" + o;
}

/////////////////////////////////////////////////////////////////////////////
//  fromHex().  Convert a hex string to ASCII text.
/////////////////////////////////////////////////////////////////////////////
export function fromHex(s)
{
    var start = 0;
    var o = "";

    if (s.substr(0,2).toLowerCase() == "0x") {
        start = 2;
    }

    if (typeof s != "string") {
        s = s.toString();
    }
    for (var i=start; i<s.length; i+=2) {
        var c = s.substr(i, 2);

        o = o + String.fromCharCode(parseInt(c, 16));
    }

    return o;
}

// normalize coin linevalues
export function normalizePrices(prices, min=0, max=0){
    let normalPrices = [];
    // .time, .value
    // let max = 0;
    // let min = Number.POSITIVE_INFINITY;

    let tmp;
    for (let i=0;i<prices.length;i++) {
        tmp = prices[i].value;
        if (tmp < min) min = tmp;
        if (tmp > max) max = tmp;
    }
    // console.log(max,min)
    for (let i=0;i<prices.length;i++) {
        // let newvalue = (prices[i].value - min)/(max-min);
        let newvalue = prices[i].value/max;
        normalPrices.push({
            time: prices[i].time,
            value: newvalue
        })
    }

    return normalPrices;
}



export function processCapData(candles, current_price, cap){

    let data = [];
    // .time, .value
    // let max = 0;
    // let min = Number.POSITIVE_INFINITY;

    let capfactor = (cap/current_price) / 1000000000;

    for (let i=0;i<candles.length;i++) {
        if(candles[i].open !== undefined){
            data.push({
                time: candles[i].time,
                open: candles[i].open * capfactor,
                high: candles[i].high * capfactor,
                low: candles[i].low * capfactor,
                close: candles[i].close * capfactor,
            })
        }else{
            data.push({
                time: candles[i].time,
                value: candles[i].value * capfactor
            })
        }

    }

    return data;
}




// Colors

export function getRandomColor(alpha=1) {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgba(" + r + "," + g + "," + b + "," + alpha + ")";
};

export function adjustRGBAlpha(rgba, alpha = 0.7) {
  // Extract the rgba values using a regex pattern
  const match = rgba.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*(\d*\.?\d+)?\)/);

  if (!match) {
    throw new Error("Invalid RGBA color format");
  }

  const [_, r, g, b] = match;

  // Return the rgba color string with the new alpha
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function hexToRGBA(h,alpha,isPct) {
  let r = 0, g = 0, b = 0;
  isPct = isPct === true;

  if (h.length == 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

  } else if (h.length == 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }

  if (isPct) {
    r = +(r / 255 * 100).toFixed(1);
    g = +(g / 255 * 100).toFixed(1);
    b = +(b / 255 * 100).toFixed(1);
  }

  return "rgba(" + (isPct ? r + "%," + g + "%," + b + "%" : +r + "," + +g + "," + +b) + "," + alpha + ")";
}

export function rgbToHex(red, green, blue) {
  return "#" + ColorToHex(red) + ColorToHex(green) + ColorToHex(blue);
}

export function ColorToHex(rgb) {
  var hex = Number(rgb).toString(16);
  if (hex.length < 2) {
       hex = "0" + hex;
  }
  return hex;
};

// export function addCommas(numb) {
//     var str = numb.toString().split(".");
//     str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//     return str.join(".");
// }

export function addCommas(nStr) {
  nStr += '';
  let x = nStr.split('.');
  let x1 = x[0];
  let x2 = x.length > 1 ? '.' + x[1] : '';
  let rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
}

// export function sleep(time){
//     setTimeout(() => {
//         return true;
//     },time)
// }

export function sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export function getPosition(el) {

    var x = 0,
        y = 0;

    while (el != null && (el.tagName || '').toLowerCase() != 'html') {
        x += el.offsetLeft || 0;
        y += el.offsetTop || 0;
        el = el.parentElement;
    }

    return { x: parseInt(x, 10), y: parseInt(y, 10) };
}

export function removeHttp(url) {
  return url.
  
  replace(/https?.*?(?= |$)/g, "");
}

export function getTags(inputString) {
  // Regular expression to match HTML tags
  const tagRegex = /<\/?[^>]+(>|$)/g;
  
  // Find all tags
  const tags = inputString.match(tagRegex) || [];
  return tags;
  // // Remove all tags from the input string
  // const stringWithoutTags = inputString.replace(tagRegex, '');
  
  // return {
  //     tags: tags,
  //     cleanedString: stringWithoutTags
  // };
}

export function decodeHtml(input){
    var ret = input.replace(/&gt;/g, '>');
    ret = ret.replace(/&lt;/g, '<');
    ret = ret.replace(/&quot;/g, '"');
    ret = ret.replace(/&apos;/g, "'");
    ret = ret.replace(/&amp;/g, '&');
    return ret;
}

export function dateToTime(d){
    let date = new Date(d);
    return Math.floor(date.getTime() / 1000)
}

export function getTimeDiff(timea,timeb,scale="hours"){
    if(timea == undefined || timeb == undefined){
        return 0;
    }else{
        return (dateToTime(timea) - dateToTime(timeb)) / 3600;
    }
}


export function exponentToString(numIn) {
 numIn +="";                                            // To cater to numric entries
 var sign="";                                           // To remember the number sign
 if(numIn.charAt(0)=="-"){
     numIn =numIn.substring(1);
     sign="-";
 }

 var str = numIn.split(/[eE]/g);                        // Split numberic string at e or E
 if (str.length<2) return sign+numIn;                   // Not an Exponent Number? Exit with orginal Num back
 var power = str[1];                                    // Get Exponent (Power) (could be + or -)
 if (power ==0 || power ==-0) return sign+str[0];       // If 0 exponents (i.e. 0|-0|+0) then That's any easy one

 var deciSp = 1.1.toLocaleString().substring(1,2);  // Get Deciaml Separator
 str = str[0].split(deciSp);                        // Split the Base Number into LH and RH at the decimal point
 var baseRH = str[1] || "",                         // RH Base part. Make sure we have a RH fraction else ""
     baseLH = str[0];                               // LH base part.

  if (power>0) {   // ------- Positive Exponents (Process the RH Base Part)
     if (power> baseRH.length) baseRH +="0".repeat(power-baseRH.length); // Pad with "0" at RH
     baseRH = baseRH.slice(0,power) + deciSp + baseRH.slice(power);      // Insert decSep at the correct place into RH base
     if (baseRH.charAt(baseRH.length-1) ==deciSp) baseRH =baseRH.slice(0,-1); // If decSep at RH end? => remove it

  } else {         // ------- Negative Exponents (Process the LH Base Part)
     let num= Math.abs(power) - baseLH.length;                               // Delta necessary 0's
     if (num>0) baseLH = "0".repeat(num) + baseLH;                       // Pad with "0" at LH
     baseLH = baseLH.slice(0, power) + deciSp + baseLH.slice(power);     // Insert "." at the correct place into LH base
     if (baseLH.charAt(0) == deciSp) baseLH="0" + baseLH;                // If decSep at LH most? => add "0"
  }
 return sign + baseLH + baseRH;                                          // Return the long number (with sign)
 }


 export function findIndex(arr, str) {
    
  for (let i = 0; i < arr.length; i++) {
    // console.log(arr[i][0], str)
    // console.log(arr[i].indexOf(str))
    if (arr[i].indexOf(str) > -1) {
      return i;
    }
  }
  return -1;
}

export function isInArray(array, key, value) {
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] === value) {
      return true;
    }
  }
  return false;
}

export function ellipseText(str) {
  if (str.length <= 300) {
      return str; // Return the string unchanged if it's not longer than 300 characters
  }

  // Find the last space before the 300th character
  const lastSpaceIndex = str.lastIndexOf(' ', 300);

  // If no space found before the 300th character, just truncate the string
  if (lastSpaceIndex === -1) {
      return str.substring(0, 300) + '...';
  }

  // Otherwise, truncate at the last space before 300th character
  return str.substring(0, lastSpaceIndex) + '...';
}

export function createHyperlinks(str) {
  let stemp = str.replace(/\n/g, " <br/> ")
  .replace(/\\u2028/g, " <br/> ")
  .replace(/\r/g, " <br/> ")
  .replace(/(http|https):\/\/[\S]+/gm, '<a target="_blank" href="$&">$&</a>')
  .replace(/@(\w+)/g, '<a target="_blank" href="https://t.me/$1">$&</a>')
  .replace(/([^\n]*)Buy!/m, '<h6>$&</h6>')
  .replace(/\🫶/g, "");

  function wrapLineBeforeLink(inputString) {
    // Match the substring starting from the previous \n until LINK
    const regex = /([^\n]*)LINK/m;
    const match = regex.exec(inputString);
  
    if (match) {
      const wrappedString = inputString.replace(match[1], `<span>${match[1]}</span>`);
      return wrappedString;
    } else {
      return null; // Return null if "LINK" is not found
    }
  }

  const urlRegexes = {
    tme: /(?:^|\s)(t\.me\/\S+)/ig,
    youtube: /(?:^|\s)(youtube\.com\/\S+)/ig,
    twitter: /(?:^|\s)(twitter\.com\/\S+)/ig,
    x: /(?:^|\s)(x\.com\/\S+)/ig,
    d1: /(?:^|\s)(discord\.com\/\S+)/ig,
    d1: /(?:^|\s)(discord\.gg\/\S+)/ig,
    medium: /(?:^|\s)(medium\.com\/\S+)/ig,
    lmedium: /(?:^|\s)(link\.medium\.com\/\S+)/ig,
  };

  const sregex = /(?<!\.)(t\.me\/|T\.me\/|twitter\.com\/|x\.com\/|discord\.com\/|discord\.gg\/|link\.medium\.com\/|medium\.com\/|www\.\w+\.\w+\/?)([\w@-]+)(\/?)+(?=\s+|$)/g;


  Object.keys(urlRegexes).forEach((key) => {
      const regex = urlRegexes[key];
      stemp = stemp.replace(regex, (match) => {
        
          const url = match.startsWith('http') ? match : `https://${match}`;
          return `<a href="${url.replace(" ","")}" target="_blank" rel="noopener noreferrer">${match}</a>`;
      });
  });

  return stemp;

}

export function convertUnicode(str) {
  let result = '';
  let hex = '';
  for (let i = 0; i < str.length; i++) {
    if (str[i] === '\\' && str[i + 1] === 'x') {
      hex = str.substring(i + 2, i + 4);
      result += String.fromCharCode(parseInt(hex, 16));
      i += 3;
    } else {
      result += str[i];
    }
  }
  return result;
}



export const replaceSubDigits = (num) => {
    let numZeros = num.match(/\.0*/)[0].length - 1;

    // Construct the subscript character for the number of zeros
    if(numZeros>2){
        if(numZeros > 9){
            let subscriptChar0 = `&#x2081;`;
            let subscriptChar1 = `&#x208${(numZeros-10).toString()};`;
            return num.replace(/\.0*/, `.0${subscriptChar0}${subscriptChar1}`);
        }else{
            let subscriptChar = `&#x208${numZeros.toString()};`;
            return num.replace(/\.0*/, `.0${subscriptChar}`);
        }

    }else{
        return num;
    }

}




export function getTimeDisplay(timestamp,includeAgo=true) {
  const minute = 60; // in seconds
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;
  const month = 30 * day;
  const year = 365 * day;

  const diff = Math.floor((new Date() - new Date(timestamp)) / 1000); // in seconds

  if (diff < minute) {
    return `now`;
    // return `${diff}s`;
  } else if (diff < hour) {
    const minutes = Math.floor(diff / minute);
    return parse(`${minutes}<span className="mute">m</span>`);
  } else if (diff < day) {
    const hours = Math.floor(diff / hour);
    const minutes = Math.floor((diff % hour) / minute);
    if(minutes > 0){
        return parse(`${hours}<span className="mute">h</span> ${minutes}<span className="mute">m</span>`);
    }else{
        return parse(`${hours}<span className="mute">h</span>`);
    }
  } else if (diff < week) {
    const days = Math.floor(diff / day);
    const hours = Math.floor((diff % day) / hour);
    if(hours > 0){
        return parse(`${days}<span className="mute">d</span> ${hours}<span className="mute">h</span>`);
    }else{
        return parse(`${days}<span className="mute">d</span>`);
    }
  } else if (diff < month) {
    const weeks = Math.floor(diff / week);
    const days = Math.floor((diff % week) / day);
    if(days > 0){
        return parse(`${weeks}<span className="mute">w</span> ${days}<span className="mute">d</span>`);
    }else{
        return parse(`${weeks}<span className="mute">w</span>`);
    }
  } else if (diff < year) {
    const months = Math.floor(diff / month);
    const weeks = Math.floor((diff % month) / week);
    if(weeks > 0 && weeks < 4){
        return parse(`${months}<span className="mute">m</span> ${weeks}<span className="mute">w</span>`);
    }else{
        let m = weeks > 3 ? months+1 : months;
        if(m < 12){
            return parse(`${m}<span className="mute">mo</span>`);
        }else{
            return parse(`1<span className="mute">y</span>`);
        }

    }
  } else {
    const years = Math.floor(diff / year);
    const months = Math.floor((diff % year) / month);
    if(months > 0 && months < 12){
        return parse(`${years}<span className="mute">y</span> ${months}<span className="mute">mo</span>`);
    }else{
        return parse(`${years}<span className="mute">y</span>`);
    }

  }
}


export function getCautionColor(value) {
  const percent = value / 50;
  let gas = value;
  let opacity = .73;
  if (gas <= 12) {
    // Blue to yellow
    const red = 0;
    const green = Math.round((percent / 0.25) * 255);
    const blue = 255;
    return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
} else if (gas <= 25) {
    // Yellow to orange
    const red = Math.round(((percent - 0.25) / 0.25) * 255);
    const green = 255;
    const blue = 0;
    return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
} else if (gas <= 100) {
    // Orange to red
    const red = 255;
    const green = Math.round(255 - ((percent - 0.5) / 0.25) * 255);
    const blue = 0;
    return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
  } else {
    // Red
    return `rgba(255, 0, 0, ${opacity})`;
  }

}

export function calculateOriginalFromPercent(value, percent) {
  if (percent === 0) {
    return value;
  }

  const oValue = value / (1 + percent / 100);
  return oValue;
}



export const openPage = (token=null, p) => {
    let purl;

    switch(p){
        
        case "bubblemaps":
            purl = `https://app.bubblemaps.io/eth/token/${token.pair.data.baseToken.address}`;
            break;
        case "google":
            let q = token.pair.data.baseToken.name;
            q.replace(/[ ]/g, "+");
            purl = `https://www.google.com/search?q=${q}`;
            break;
        case "sniffer":
          
            purl = `${chainMeta[token.pair.chainId].scan}${token.address}`;
            break;
        case "wiki":
            let wq = token.pair.data.baseToken.name;
            wq.replace(/[ ]/g, "+");
            purl = `https://en.wikipedia.org/wiki/Special:Search?go=Go&search=${wq}&ns0=1`;
            break;
        case "twitter":
            purl = `https://twitter.com/search?q=%24${token.pair.data.baseToken.symbol}&f=live`;
            break;
        case "x":
            purl = `https://x.com/search?q=%24${token.pair.data.baseToken.symbol}&f=live`;
            break;
        case "liquiditylock":

            if(token.pair.liquidityLock.lockedIn=="unicrypt"){
                purl = `https://app.unicrypt.network/amm/uni-v2/token/${token.address}`;
            }else if(token.pair.liquidityLock.lockedIn=="pinklock"){
                purl = null;
            }

            break;
        case "liquiditypool":

            purl = `https://etherscan.io/address/${token.pair.address}`;
            break;
        case "holders":

            purl = `https://etherscan.io/token/${token.address}#balances`;
            break;
        case "liquidityburnd":

            purl = `https://etherscan.io/token/${token.pair.address}?a=${burnAddresses[0]}`;

            break;
        case "liquidityburn0":

            purl = `https://etherscan.io/token/${token.pair.address}?a=${burnAddresses[1]}`;

            break;
        case "gastracker":
            purl = `https://etherscan.io/gastracker`;
        default:
            break;
    }

    if(purl){
        window.open(
            purl,
            "_blank"
            // 'toolbar=0,status=0,width=600,height=700,left=0,top=100'
        );
    }



}

export const buildToken = (t,p) => {
    let ptemp = p ? {...p} : {};
    delete ptemp.token;
    let tp = p ? {
        ...p.token,
        pair: {...ptemp}
    } : t ? {...t, updated: Date.now(),image:null} : null;
    return tp;
}

export function wrapLineBeforeBuy(inputString) {
  // Match the substring starting from the previous \n until LINK
  const regex = /([^\n]*)BUY!/m;
  const match = regex.exec(inputString);

  if (match) {
    const wrappedString = inputString.replace(match[1], `<span className="title">${match[1]}</span>`);
    return wrappedString;
  } else {
    return null; // Return null if "LINK" is not found
  }
}

export const dataTimeLabels = {
  "m5": "5m",
  "h1": "1h",
  "h6": "6h",
  "h24": "24h"
}

export function getRandomLoudColorHex() {
  // Function to generate a random number within a range
  const randomInRange = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

  // Generate random values for hue, saturation, and lightness
  const hue = randomInRange(0, 360); // Full range of hues
  const saturation = randomInRange(80, 100); // High saturation for loud colors
  const lightness = randomInRange(50, 70); // Bright but not too light

  // Convert HSL to RGB
  const hslToRgb = (h, s, l) => {
    s /= 100;
    l /= 100;

    let c = (1 - Math.abs(2 * l - 1)) * s;
    let x = c * (1 - Math.abs((h / 60) % 2 - 1));
    let m = l - c / 2;
    let r = 0, g = 0, b = 0;

    if (0 <= h && h < 60) {
      r = c; g = x; b = 0;
    } else if (60 <= h && h < 120) {
      r = x; g = c; b = 0;
    } else if (120 <= h && h < 180) {
      r = 0; g = c; b = x;
    } else if (180 <= h && h < 240) {
      r = 0; g = x; b = c;
    } else if (240 <= h && h < 300) {
      r = x; g = 0; b = c;
    } else if (300 <= h && h < 360) {
      r = c; g = 0; b = x;
    }

    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);

    return { r, g, b };
  };

  const rgbToHex = (r, g, b) => {
    const toHex = (n) => n.toString(16).padStart(2, '0');
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  const { r, g, b } = hslToRgb(hue, saturation, lightness);
  return rgbToHex(r, g, b);
}

export function getRandomLoudColorHsl() {
  // Function to generate a random number within a range
  const randomInRange = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

  // Generate random values for hue, saturation, and lightness
  const hue = randomInRange(0, 360); // Full range of hues
  const saturation = randomInRange(80, 100); // High saturation for loud colors
  const lightness = randomInRange(50, 70); // Bright but not too light

  // Convert to HSL string
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

export function getRandomLoudColorRgba(alpha = 1) {
  // Function to generate a random number within a range
  const randomInRange = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

  // Generate random values for hue, saturation, and lightness
  const hue = randomInRange(0, 360); // Full range of hues
  const saturation = randomInRange(80, 100); // High saturation for loud colors
  const lightness = randomInRange(50, 70); // Bright but not too light

  // Convert HSL to RGB
  const hslToRgb = (h, s, l) => {
    s /= 100;
    l /= 100;

    let c = (1 - Math.abs(2 * l - 1)) * s;
    let x = c * (1 - Math.abs((h / 60) % 2 - 1));
    let m = l - c / 2;
    let r = 0, g = 0, b = 0;

    if (0 <= h && h < 60) {
      r = c; g = x; b = 0;
    } else if (60 <= h && h < 120) {
      r = x; g = c; b = 0;
    } else if (120 <= h && h < 180) {
      r = 0; g = c; b = x;
    } else if (180 <= h && h < 240) {
      r = 0; g = x; b = c;
    } else if (240 <= h && h < 300) {
      r = x; g = 0; b = c;
    } else if (300 <= h && h < 360) {
      r = c; g = 0; b = x;
    }

    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);

    return { r, g, b };
  };

  const { r, g, b } = hslToRgb(hue, saturation, lightness);

  // Return the color in RGBA format
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}


// export function getRandomColor() {
//     var letters = '0123456789ABCDEF';
//     var color = '#';
//     for (var i = 0; i < 6; i++) {
//         color += letters[Math.floor(Math.random() * 16)];
//     }
//     return color;
// }


// export function getPosition(el) {
//   var xPosition = 0;
//   var yPosition = 0;
//
//   while (el) {
//     if (el.tagName == "BODY") {
//       // deal with browser quirks with body/window/document and page scroll
//       var xScrollPos = el.scrollLeft || document.documentElement.scrollLeft;
//       var yScrollPos = el.scrollTop || document.documentElement.scrollTop;
//
//       xPosition += (el.offsetLeft - xScrollPos + el.clientLeft);
//       yPosition += (el.offsetTop - yScrollPos + el.clientTop);
//     } else {
//       xPosition += (el.offsetLeft - el.scrollLeft + el.clientLeft);
//       yPosition += (el.offsetTop - el.scrollTop + el.clientTop);
//     }
//
//     el = el.offsetParent;
//   }
//   return {
//     x: xPosition,
//     y: yPosition
//   };
// }
