import React, { useState, useEffect, useCallback, useRef} from "react";

import { TokenSymbolIcon } from "../Dex/TokenSymbolIcon";

import { useNavigator } from "../../hooks/useNavigator";
import { useDex } from "../../hooks/useDex";
import { useCore } from "../../hooks/useCore";
import classNames from "classnames";

import { createHyperlinks, ellipseText, getTimeDisplay } from "../../utils/tools";

import { ReactComponent as TgLogo} from "../../icons/svgs/tg.svg";

import { YoutubeEmbed } from "./YoutubeEmbed";
import { supportedChains } from "../../utils/data";

var parse = require('html-react-parser');

export function CallItem({ item, addClass, addStyle }) {

	const {
        handleDexSearchModal,
        handleModalVisible,
		defaultImage,
		handlePhotoModalData,
    } = useCore();

	const navigator = useNavigator();

	const {
		token,
		handleDexSearch,
		searchDex,
		handleTokenPair,
		handleLoading
	} = useDex();

	const openLink = (l0, l1) => {

        let u = l0 && l0.url !== undefined ? l0.url : l1.url;
        window.open(
            u,
            "news"
            // 'toolbar=0,status=0,width=600,height=700,left=0,top=100'
        );

    }

	const openWebPage = () => {

        window.open(
            item.webpage.url,
            `${item.webpage.siteName}`
            // 'toolbar=0,status=0,width=600,height=700,left=0,top=100'
        );

    }


	const placeholderImage = require(`../../media/media_default_image.jpg`);

	const onImageError = (e) => {
		e.target.src = placeholderImage
	}

	const openToken = () => {
		handleTokenPair(null);
		navigator.push(`/${item.chainId}/${item.pa}?view=calls`);
	}

	const searchClick = (s) => {
		let st = s.replace("$","");
		handleDexSearch(st);
        searchDex(st);
		handleDexSearchModal(true);
        handleModalVisible(true);
	}

	const replaceImage = (error) => {
        //replacement of broken Image
		console.log('image error')
        error.target.src = require(defaultImage);
    }

	const openPhotoModal = () => {
		handlePhotoModalData({
			photo: `${process.env.REACT_APP_MEDIA_URL}${item.photo}`,
			username: item.username,
			channelTitle: item.channelTitle,
			channelType: "kols"
		});
	}

	
	
	const cTagsToLinks = (text) => {
		const parts = text.split(/(\$(?![0-9])[A-Za-z0-9]+(?=\s|$))/g); // Use capturing groups to preserve cashtags
		// Convert parts into JSX elements
		const elements = parts.map((part, index) => {
			if (index % 2 === 0) {
				// Even index: plain text or space
				// return part;
				return <span key={index} dangerouslySetInnerHTML={{__html: part}} />;
			} else {
				// Odd index: cashtag, replace with link
				return (
					<a
						href="#"
						onClick={(e) => {
							e.preventDefault();
							searchClick(part);
						}}
						key={index} // Ensure unique key for React elements in array
					>
						{part}
					</a>
				);
			}
		});
		
		return elements;
	};

	const iMessage = createHyperlinks(item.message.replace(/\uFFFD/g, '⚪').replace(/\nChart.*\n/i, '\n')).replace(/\ndextools.*\n/i, '\n');
	
	const webpageLogo = item.webpage?.siteName == "YouTube" ? "youtube" : item.webpage?.siteName?.charAt(0) == "X" ? "x" : null;

    return (

		<div style={ addStyle ? addStyle : {}} className={classNames("call-item", token && token.pair.address == item.pa && "active", addClass && addClass)}>
			<div className="item-header">
				<div className="data-panel transparent flex-row align center">
					{item.username.length ? (
						<img className="profile-image main" src={require(`../../icons/kols/logo_${item.username.toLowerCase()}.jpg`)}/>
					):null}
					<p style={{marginLeft:7}}>{item.channelTitle}</p>
				</div>
				<div className="data-value">
				{getTimeDisplay(item.created)}
				{/*{getTimeDiff(now,item.created) >= 1/60 ? " ago" : ""}*/}
				</div>
			</div>

			{item.photo !== undefined && item.photo ? (
				<div onClick={openPhotoModal} className="call-item-photo">
					<img src={`${process.env.REACT_APP_MEDIA_URL}${item.photo}`}/>
				</div>
			):null}

			{ item.webpage?.siteName == "YouTube" ? (
				<YoutubeEmbed videoId={item.webpage.url.split('?v=')[1]} title={item.webpage.title}/>
			):null}
			
			
			<p style={{marginTop:7,padding: item.og && item.og.ogDescription !== undefined ? '3px 11px 3px 11px' : '3px 11px 14px 11px'}}>{cTagsToLinks(iMessage)}</p>
			
			{item.webpage && item.webpage.url.indexOf("dexscreener.com") < 0 ? (
				<div onClick={openWebPage} style={{width:'80%',maxWidth:333,marginLeft:7,marginBottom:7}} className="data-panel interactive">
					<div className="web-page">
						{webpageLogo ? (
							<div className="flex-row">
								<img style={{width:22,opacity:.73}} src={require(`../../icons/news/logo_${webpageLogo}.png`)}/>
							</div>
						):null}
						
						{ item.webpage.siteName.indexOf("formerly Twitter") < 0 ? (
							<p style={{fontSize:14}} className="data-title">{item.webpage.siteName}</p>
						):null}
						
						<p style={{fontSize:12, fontWeight:600}} className="data-value medium">{item.webpage.title}</p>
						{ item.webpage?.description?.length ? (
							<p style={{fontSize:13}} className="small">{ellipseText(item.webpage.description)}</p>
						):null}
						

					</div>
				</div>
				
			):null}

			{item.og && item.og.ogDescription !== undefined ? (
				<p style={{fontSize:14, opacity:.8,padding: '3px 11px 33px 11px'}}>{item.og.ogDescription}</p>
			):null}

			{ item.chainId && supportedChains.includes(item.chainId) ? (
				<div onClick={openToken}
					className={classNames("data-panel interactive item-border", token && token.pair.address == item.pa && "active")}
					// className="data-panel interactive"
					style={{margin:4,flex:1,maxWidth:288, alignItems:'center'}}
				>
					<img style={{margin:0}}
					className="chain-icon"
					src={require(`../../icons/logo_chain_${item.chainId}.png`)}
					// onError={replaceImage}
					/>
					<TokenSymbolIcon
                      token={item.token ? item.token : null}
                      ca={item.ta || item.pa}
                      symbol={item.symbol}
                      name={item.name}
                      // addClass="large"
                      />
				</div>
			):null}

			<div className="web-link">
				<div className="svg-icon">
					{item.platform=="telegram" ? (
						<TgLogo/>
					):null}

				</div>
			</div>

			{/* { item.linkUrls.length > 0 || (item.webpage && item.webpage.url !== undefined) ? (
				<div className="web-link">
					<div className="coin-links">
						{ item.linkUrls.map((l, i) => (
							<div key={`nl-${i}`} onClick={() => openLink(l, item.webpage)} style={{maxWidth:200}} className="coin-link">
								{ l.source && l.source !== "null" ? (
									<img onError={onImageError} className="profile-image" style={{marginRight:7,maxHeight:22}} src={require(`../../icons/news/logo_${l.source}.png`)}/>
								):null}

								<div className="svg-icon">
									<div className="icon-button-link"/>
								</div>
							</div>
						))}

					</div>
				</div>
			):null} */}

		</div>




    )
}
