import React, { useEffect, useState } from "react";

import Blockies from 'react-blockies';

import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useAccount } from "wagmi";


import { useAuth } from "../../hooks/useAuth";
import { useCore } from "../../hooks/useCore";
import { useWallet } from "../../hooks/useWallet";
import { TokenSymbolIcon } from "../Dex/TokenSymbolIcon";
import { TokenListItemSymbol } from "../Dex/TokenListItemSymbol";
import { blockieSeed } from "../../utils/data";

import classNames from "classnames";

export function WalletButton() {

	const { openConnectModal } = useConnectModal();
	const { address, isConnected } = useAccount();
	
	const { walletModalVisible, handleWalletModalVisible } = useCore();
	let { walletAddress, user } = useWallet();

    let { loading, isAuthenticated } = useAuth();

	const [ hovering, setHovering ] = useState(false);

	const walletClick = () => {
		if(!address || address == undefined){
			openConnectModal();
		}else{
			handleWalletModalVisible(!walletModalVisible);
		}
    }

	const onOver = () => {
		setHovering(true);
	}

	const onLeave = () => {
		setHovering(false);
	}


	// const walletTruncated = account?.length ? account.substring(0,5) + '...' + account.substring(account.length - 4) : '';

	const wa = String(walletAddress);
	const walletTruncated = walletAddress ? wa.substring(0,5) + '...' + wa.substring(wa.length - 4) : '';

	// .replace("606","237").replace("7bde","l0v3")

	const userImageUrl = user && user.avatarFile ? `${process.env.REACT_APP_MEDIA_URL}${user.avatarFile}?t=${Math.floor(new Date().getTime() / (1000 * 60 * 60))}` : null;

    return (
        <>
			

			<button onClick={walletClick} onMouseEnter={onOver} onMouseLeave={onLeave} style={{ maxHeight: 27,minWidth: '125px', paddingRight: walletAddress ? '16px' : '', paddingLeft: walletAddress ? '16px' : '32px' }} className={classNames("currency address wallet-button panel slim", user?.username.length > 2  ? "" : "wallet-address")}>
				
				{ user && user.avatarFile ? (
					<div style={{width:21,position:'absolute',top:2,left:4}} className="user-avatar">
						<img src={userImageUrl}/>
					</div>
					
				): walletAddress ? (
					<div className="wallet-profile-image">
						<Blockies
							seed={walletAddress !== undefined ? walletAddress + blockieSeed : "love"}
							size={7}
							scale={5}
							// color="#dfe"
							// bgColor="#ffe"
							// spotColor="#abc"
							className="identicon"
						/>
					</div>
				):(
					<div style={{filter:'saturate(0) brightness(.55)',marginRight:11}} className="wallet-profile-image">
						<Blockies
							seed={blockieSeed}
							size={7}
							scale={5}
							// color="#dfe"
							// bgColor="#ffe"
							// spotColor="#abc"
							className="identicon"
						/>
					</div>
				)}
				{ walletAddress && hovering ? (
					<p style={{margin:0,fontSize:11}} >Disconnect</p>
				) : user?.username.length > 2 ? (
					<p style={{margin:0,fontSize:11}} >{user.username}</p>
				) : walletAddress ? (
					<p style={{margin:0,fontSize:11}} >{walletTruncated}</p>
				) : (
					<p style={{margin:0}} >{ !isConnected ? "Connect Wallet" : "Authenticate Wallet"}</p>
				) }
				{ walletAddress && (
					<div className="active-green-circle"/>
				)}
			</button>


        </>
    );
}
