import React, { useEffect, useState, useRef, useMemo } from "react";

import { formatCurrency, condenseZeros, formatPercent, formatCurrencyScale, formatNumberScale } from "../../utils/currency";

import { useDex } from "../../hooks/useDex";
import { useNavigator } from "../../hooks/useNavigator";
import { useCore } from "../../hooks/useCore";
import { useWallet } from "../../hooks/useWallet";
import { useMarket } from "../../hooks/useMarket";

import logo0x from "../../icons/logo_0xdex_faded.png";

import { addCommas, blankAddress, deadAddress, getTimeDisplay, getTimeDiff, buildToken } from "../../utils/tools";

import classNames from "classnames";

import { SwapIcon } from "./SwapIcon";
import { TokenSymbolIcon } from "./TokenSymbolIcon";
import { TokenDataTabs } from "./TokenDataTabs";
import { StatusLight } from "./StatusLight";
import { ContractAddressToolbar } from "./ContractAddressToolbar";
import { TokenToolbar } from "./TokenToolbar";
import { IconActionButton } from "../IconActionButton";
import { BuyDexOption } from "./BuyDexOption";

import ReactTooltip from 'react-tooltip';

import TimeAgo from 'react-timeago';
import timeFormat from '../../utils/timeago_format';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import { dateToTime } from "../../utils/tools";

import { ReactComponent as RocketIcon } from '../../icons/svgs/rocket.svg';
import { ReactComponent as Seedling } from "../../icons/svgs/seedling.svg";
import { ReactComponent as WalletIcon } from '../../icons/svgs/wallet.svg';
import { ReactComponent as Crown } from '../../icons/svgs/crown.svg';
import { ReactComponent as DragHandle } from '../../icons/svgs/drag.svg';



var parse = require('html-react-parser');


export function DexPairItem({
        i=-1,
        view="trending",
        hideOptions=false,
        hideRank=false,
        rankAnimation=false,
        pair=null,
        pairData=null,
        token=null,
        tokenStyle="default",
        list=null,
        coreToken = null,
        passClick,
        passMouseOver=null,
        addClass,
        addStyle={},
        removeOption=null,
        withSpace=null,
        dragHandleProps=null,
        draggableProps=null,
        innerRef=null,
        dragging=null,
        featuredSiteToken=null,
        activeColor=null
    }) {

    const optionsref = useRef(null);

    const tp = buildToken(token,pair);

    const formatter = buildFormatter(timeFormat);
    const navigator = useNavigator();

    const now = new Date();

    const { coins } = useMarket();

    const {
        walletAddress,
        balancesAlchemy,
        removeTokenFromPortfolio,
        ethPrice,
        solPrice,
        user,
        addTokenToPortfolio,
        adminWallet
    } = useWallet();

    const {
		handleScreenClickDims,
        loading,
        fetchTokenPair,
        handleTokenPair,
        caToken,
        
        ethTrendingKey,
        tokenLists,
        handleAddToTokenList,
        removeTokenFromList,
        editTokenList,
        fetchPortfolioTokens,
        ethFactor,
        listView,
        homeMeta,
        removeSiteFeaturedToken,
        handleDexView
	} = useDex();

    const {
        modalVisible,
        sidebarRight,
        handleSidebarRight,
        ethTrending,
        listPage,
        
    } = useCore();

    const [ optionsMenu, setOptionsMenu ] = useState(false);
    const [ priceUp, setPriceUp ] = useState(false);
    const [ priceDown, setPriceDown ] = useState(false);

    const [ inPortfolio, setInPortfolio ] = useState(null);
    const [ gettingActive, setGettingActive] = useState(false);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [] );

    useEffect(() => {
		const checkIfClickedOutside = e => {
            e.preventDefault();
            e.stopPropagation();
            if ( optionsMenu && optionsref.current && !optionsref.current.contains(e.target) && !e.target.classList.contains('icon-ellipsis-horizontal') ) {
                setOptionsMenu(false);
            }
        }

		document.addEventListener("mouseup", checkIfClickedOutside)

		return () => {
		  // Cleanup the event listener
		  document.removeEventListener("mouseup", checkIfClickedOutside)
		}

	}, [optionsMenu]);

    useMemo(() => {

		if((token || pair) && user && user.portfolioTokens !== undefined){
			let tempin = false;
			for(var i=0;i<user.portfolioTokens.length;i++){
                if(pair && pair.token){
                    if(user.portfolioTokens[i] == pair.token._id){
    					tempin=true;
    					setInPortfolio(true)
    				}
                }
                if(token){
                    if(user.portfolioTokens[i] == token._id){
    					tempin=true;
    					setInPortfolio(true)
    				}
                }

			}
			if(!tempin && inPortfolio){
				setInPortfolio(false);
			}
		}

	}, [ user, token, pair ]);

    const addToList = (c) => {
		// console.log(c)
		handleAddToTokenList(c,token ? token : pair.token);
	}

    const removeToken = () => {
        if(removeOption == "portfolio"){
            removeTokenFromPortfolio(token);
            setTimeout(() => {
                fetchPortfolioTokens(walletAddress);
            }, 500);
            setOptionsMenu(false);
        }
        if(removeOption == "list"){
            setTimeout(() => {
                removeTokenFromList(tokenLists[listPage], token);
            },100)
            
        }
    }

    const setTokenAsFeatured = () => {
		
		editTokenList({
            _id: list._id,
			featuredToken: token
		});
	}

    const removeFeaturedToken = () => {
        editTokenList({
            _id: list._id,
			featuredToken: null
        });
    }


    const hasLiquidity = pairData && pairData.liquidity && pairData.liquidity !== undefined && pairData.liquidity.usd > 500;
    let active = coreToken && pairData && coreToken.pair?.address == pairData.pairAddress;

    const pd = pairData ? pairData : pair?.data;
    const ptemp = pair;

    const handleMouseOver = async () => {
        // console.log(pd)
        if(passMouseOver){
            passMouseOver(pd.baseToken.address);
        }
    }

    const handleClick = async () => {
        // let ex = e.clientX;
        // let ey = e.clientY + 33;
        // console.log(ex,ey)
        // handleScreenClickDims(ex,ey);
        // console.log(t);

        if(!draggableProps || ( draggableProps && !dragging ) ){
            let v = view;

            if(view=="trending"){
                v += "&tc=" + ethTrendingKey;
            }

            if(token || pair){
                // setGettingActive(true);
                // setTimeout(() => {
                //     setGettingActive(false)
                // },2000);
                // await handleTokenPair(token,pair);
                if(!sidebarRight){
                    // handleSidebarRight(true);
                }
                // handleDexView("trending");
                let lp = listView == "list" ? listPage : "";
            
                setTimeout(() => {
                    navigator.push(`/${pd.chainId}/${pd.pairAddress}?view=${listView}${lp.length ? `&${lp}` : ""}`);
                },200)
            }else{
                
                setTimeout(() => {
                    navigator.push(`/${pd.chainId}/${pd.pairAddress}?view=${v}`);
                },50)
            }

            // handleSidebarRight(true);

            if(passClick){
                passClick();
            }
        }
        
        
        

        // fetchTokenPair(tpair.data.pairAddress);
    }

    const toggleOptionsMenu = () => {
        setOptionsMenu(!optionsMenu);
    }

    const addToPortfolio = async () => {
        let t = token ? token : pair.token;
		await addTokenToPortfolio(t);
		setTimeout(() => {
			fetchPortfolioTokens(walletAddress);
		},500)
	}

    

    const optionsClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }
    let td = token?.decimals ? token.decimals : 6;
    const tb = token && token.pair && token.pair.data && token.pair.data.baseToken !== undefined && balancesAlchemy[token.pair.data.baseToken.address] !== undefined ? parseInt(balancesAlchemy[token.pair.data.baseToken.address].balance) / (10**td) : 0;

    // console.log(td,tb)
    const percentSupplyOwned = !pair && !token ? 0 : pair && pair.token ? Math.round((tb/Number(pair.token.totalSupply))*100000).toFixed(7)/1000 : token ? Math.round((tb/Number(token.totalSupply))*100000).toFixed(7)/1000 : 0;
    // const percentSupplyOwned = !pair && !token ? 0 : pair && pair.token ? pair.token.totalSupply : token ? token.totalSupply : 0;
   
    // const ethValue = token == undefined ? 0 : token.data.quoteToken.symbol == "WETH" ? tb*token.data.priceNative : (tb*token.data.priceNative) / ethPrice;

    const ethValue = !token || !token.pair || !token.pair.data ? 0 : token.pair.data.quoteToken.symbol == "WETH" ? tb*token.pair.data.priceNative*ethFactor : (tb*token.pair.data.priceUsd*ethFactor) / (coins['ethereum'] !== undefined ?  coins['ethereum'].current_price : ethPrice);
    const solValue = !token || !token.pair || !token.pair.data ? 0 : ((tb * token.pair.data.priceUsd)/solPrice);

    const tokenca = token && token.pair && token.pair.data && token.pair.data.baseToken !== undefined ? token.pair.data.baseToken.address : null;

    // const pair = token.pair.data && token.pair.data !== undefined ? token.pair.data : null;

    const isEth = (token && token.pair && token.pair.data && token.pair.data.baseToken.symbol == "WETH") || (pair && pair.data && pair.data.baseToken.symbol == "WETH");

    const tok = token ? token : pair ? pair.token : null;

    let content = (
        <>

            <div onClick={handleClick} onMouseOver={handleMouseOver}
            style={{
                pointerEvents: walletAddress ? "all" : "all",
                position: rankAnimation ? "absolute" : "relative",
                transform: !rankAnimation ? 'none' : `translateY(${i*128}px)`,
                paddingBottom:17,borderColor: activeColor ? activeColor : '', ...addStyle}}
            className={classNames("coin-item data-panel interactive flex-row justify space-between", (active||gettingActive) && "active",rankAnimation && "rank-animation",addClass && addClass, withSpace && "space")}>
                
                { optionsMenu ? (
                    <div onClick={toggleOptionsMenu}
                    style={{

                        pointerEvents: walletAddress ? "fill" : "none",
                        width:'calc( 100% - 8px )',
                        height:'100%',
                        position:'absolute',
                        zIndex:1
                    }}/>
                ):(
                   <></>
                )}

                    {/* <button style={{pointerEvents: "none !important"}} onClick={handleClick}
                    style={{
                        opacity: 0,
                        pointerEvents: walletAddress ? "fill" : "none",
                        width:'calc( 100% - 8px )',
                        height:'100%',
                        position:'absolute',
                        zIndex:1
                    }}/> */}

                { !hideOptions ? (
                    <div style={{pointerEvents: walletAddress ? "all" : "all",position:'absolute',top:4,right:4, zIndex: optionsMenu ? 222 : 1}}>
                        <IconActionButton
                        addClass="transparentbg relative"
                        type={ !optionsMenu ? "ellipsis-vertical" : "ellipsis-horizontal" }
                        active={optionsMenu}
                        passClick={toggleOptionsMenu}/>
                    </div>
                ):null}
                

                { optionsMenu ? (
                    <>
                        { !walletAddress ? (
                            <div onClick={optionsClick} style={{right:0, top: 0}} ref={optionsref} className="options-menu">

                                <BuyDexOption/>
                            </div>
                            
                        ):(

                            <div onClick={optionsClick} style={{right:0, top: 0}} ref={optionsref} className="options-menu">

                                <div style={{marginTop:1,padding:'12px 9px'}} className="options-header">
                                    <i className="icon-button-plus"/>
                                    add to
                                </div>


                                { adminWallet && homeMeta && homeMeta.fmt && homeMeta.fmt?._id == tok._id ? (
                                    <div
                                        //style={{ backgroundColor: collections[key].color }}
                                        className={classNames("option")}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            // setTokenAsFeatured();
                                            removeSiteFeaturedToken();
                                        }}
                                    
                                    >
                                        {/* <div style={{
                                            backgroundColor: tokenLists[key].color,
                                            borderColor: tokenLists[key].color,
                                            marginRight: 7
                                        }} className="color-box"/> */}
                                        <span className="icon-button-settings"/>
                                        <p>remove featured token</p>

                                        <div className="plus-sign">
                                            <i className="icon-button-minus red"/>
                                        </div>
                                    </div>
                                ):null}

                                { list && list.walletAddress == walletAddress && tok && list.featuredToken?._id == tok._id ? (
                                    <div
                                        //style={{ backgroundColor: collections[key].color }}
                                        className={classNames("option")}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            // setTokenAsFeatured();
                                            removeFeaturedToken();
                                        }}
                                    
                                    >
                                        {/* <div style={{
                                            backgroundColor: tokenLists[key].color,
                                            borderColor: tokenLists[key].color,
                                            marginRight: 7
                                        }} className="color-box"/> */}
                                        <p>remove featured token</p>

                                        <div className="plus-sign">
                                            <i className="icon-button-minus red"/>
                                        </div>
                                    </div>
                                ):null}

                                { list && list.walletAddress == walletAddress && tok && list.featuredToken?._id !== tok._id ? (
                                    <div
                                        //style={{ backgroundColor: collections[key].color }}
                                        className={classNames("option", tok && list.featuredToken?._id == tok._id && "disabled")}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setTokenAsFeatured();
                                        }}
                                    
                                    >
                                        {/* <div style={{
                                            backgroundColor: tokenLists[key].color,
                                            borderColor: tokenLists[key].color,
                                            marginRight: 7
                                        }} className="color-box"/> */}
                                        <span className="icon-button-list"/>
                                        <p>set as featured token</p>

                                        <div className="plus-sign">
                                            <i className="icon-button-plus green"/>
                                        </div>
                                    </div>
                                ):null}

                                <div
                                    //style={{ backgroundColor: collections[key].color }}
                                    className={classNames("option", inPortfolio && "disabled")}
                                    onClick={() => addToPortfolio()}

                                >
                                    <div className="svg-icon">
                                        <WalletIcon/>
                                    </div>
                                    <p>portfolio</p>

                                    <div className="plus-sign">
                                        <i className="icon-button-plus"/>
                                    </div>
                                </div>

                            
                                <div style={{maxHeight:240,paddingBottom: 8, overflowY:'auto'}} className="flex-column">

                                

                                    { Object.keys(tokenLists).map(( key, i ) => {

                                        let inc = false;


                                        let ts = tokenLists[key].tokens;

                                        for(var k=0;k<ts.length;k++){

                                            // console.log(ts[k],tokenPair)
                                            if(ts[k]){
                                                if(ts[k].address == pd.baseToken.address){
                                                    inc = true;
                                                }
                                            }

                                        }


                                        return (
                                            <div
                                                //style={{ backgroundColor: collections[key].color }}
                                                className={classNames("option", inc && "disabled")}
                                                style={{justifyContent:"space-between"}}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    addToList(tokenLists[key]);
                                                }}
                                                key={`option${i}`}
                                            >
                                                <div className="flex-row align center">
                                                    <div style={{
                                                        backgroundColor: tokenLists[key].color,
                                                        borderColor: tokenLists[key].color,
                                                        marginRight: 7
                                                    }} className="color-box"/>
                                                    <p>{tokenLists[key].name}</p>
                                                </div>
                                                
                                                <div style={{marginLeft:7}} className="count-circle">
                                                    {tokenLists[key].tokens.length}
                                                </div>
                                                <div className="plus-sign">
                                                    <i className="icon-button-plus"/>
                                                </div>
                                            </div>
                                        )

                                    }
                                    )}
                                </div>


                            

                                { removeOption && ((list && list.walletAddress == walletAddress) || removeOption == "portfolio") ? (
                                    <div
                                        //style={{ backgroundColor: collections[key].color }}
                                        className={classNames("option")}
                                        onClick={removeToken}

                                    >
                                        {/*<div className="svg-icon">
                                            <WalletIcon/>
                                        </div>*/}
                                        <p className="red">remove</p>

                                        <div className="plus-sign">
                                            <i className="icon-button-minus"/>
                                        </div>
                                    </div>
                                ):null} 

                            


                            </div>

                        )}
                    </>
                    
                ):null}
                
                   
                {/* { dragHandleProps && walletAddress ? ( */}
                    <div style={{
                        pointerEvents: dragHandleProps && walletAddress ? "all" : "none",
                        opacity: dragHandleProps && walletAddress ? 1 : 0,
                        position:'absolute',
                        alignItems:'center',
                        display:'flex',
                        alignItems:'flex-end',
                        flexDirection:'row',bottom:5,
                        left:5

                    }}>
                        <span
                            {...dragHandleProps} 
                            style={{  }}
                        >
                            <div className="coin-links">
                                <div style={{cursor: !dragging ? 'grab' : 'grabbing',padding:"7px 5px", minWidth: "initial"}} className="coin-link">
                                    <div className="svg-icon">
                                        <DragHandle/>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </div>   
                {/* ):null} */}
                            

                { !hideRank && pair && pair.trending && pair.trending[ethTrendingKey] !== undefined ? (
                    <div style={{position:'absolute',alignItems:'center',display:'flex',alignItems:'flex-end',flexDirection:'row',bottom:7,left:9}}>
                        <div style={{marginRight:4}} className={`rank-number rank-${pair.trending[ethTrendingKey].rank+1}`}>{pair.trending[ethTrendingKey].rank+1}</div>
                        { pair.trending[ethTrendingKey].rank < 1 ? (
                            <div style={{top:-11}} className="rank-crown">
                                <Crown/>
                            </div>
                        ):null}
                        
                        { pair.trending[ethTrendingKey] !== undefined ? (
                            <div style={{lineHeight:'12px'}} className="data-value">
                                {getTimeDisplay(pair.trending[ethTrendingKey].startedAt)}
                                {/* {getTimeDiff(now,pair.trending[ethTrendingKey].startedAt) >= 1/60 ? " ago" : ""} */}
                            </div>
                        ):null}
                    </div>
                ):null}

                {/* { pair && pair.trending !== undefined && Object.keys(pair.trending).length ? (
                    <>
                        <div style={{position: "absolute",alignItems:"center", bottom: 3, transformOrigin: "left",left: 77,transform:"scale(.77)",filter:'saturate(0)'}} className="flex-row">
                            { Object.keys(pair.trending)
                            .sort((a,b) => pair.trending[a].rank < pair.trending[b].rank ? -1 : 1)
                            .map((k,i) => (
                                <div style={{marginRight:11}} className="flex-row">
                                    <img style={{width:24,marginRight:3}} src={require(`../../icons/trending/logo_${k}.png`)}/>
                                    <div className={`rank-number rank-${pair.trending[k].rank+1}`}>
                                        {pair.trending[k].rank+1}
                                    </div>
                                </div>
                            ))}

                        </div>
                    
                    </>
                ):null} */}


                { pairData && pairData.pairCreatedAt !== undefined ? (
                    <div style={{display:"flex",flexDirection:"row",alignItems:"flex-end",position:'absolute',minWidth: 33,bottom:5,right:5}}>
                        <div style={{lineHeight:'11px'}} className="data-value">
                            {getTimeDisplay(pair && pair.liquidityAt > 0 ? pair.liquidityAt : pairData.pairCreatedAt !== undefined ? pairData.pairCreatedAt : 0)}
                        </div>

                        { getTimeDiff(now,pd.pairCreatedAt) < 24 * 7 ? (
                            <div style={{width:19,height:19}} className="svg-icon">
                                <Seedling/>
                            </div>
                        ):(
                            <div style={{opacity:.7,marginRight:0,marginLeft:4}} className="icon-button-clock"/>
                        )}

                    </div>
                ):null}



                <div className="flex-column full">
                    {/*{tokenPair.renouncedAt && (
                            <p>{ dateToTime( new Date(tokenPair.renouncedAt))}-{dateToTime(new Date(tokenPair.pairCreatedAt))}-{dateToTime(new Date(tokenPair.liquidityAt))}</p>
                    )}

    */}
                    <div style={{justifyContent:'space-between'}} className="data-row">
                        <div className="flex-row">
                            <div style={{}} className="token-symbol data-column justify space-between">
                              
                                <TokenSymbolIcon
                                    token={{
                                        iconImage: token && token.iconImage ? token.iconImage : pair && pair.token ? pair.token.iconImage : null,
                                        cg: token && token.cg ? {...token.cg} : pair && pair.token ? {...pair.token.cg} : null,
                                        pair: { data: {...pd} }
                                    }}
                                    showCa={true}
                                    ca={pd ? pd.baseToken.address : token.address}
                                    symbol={pd ? pd.baseToken.symbol : token.symbol}
                                    name={pd ? pd.baseToken.name : token.name}
                                    includeQuote={true}
                                    boosts={pd.boosts}
                                    // addClass="large"
                                />

                                {/*<p>{pd.chainId}|{pd.dexId}</p>*/}

                                {pd && (
                                    <div style={{justifyContent: 'flex-start',marginLeft:49}} className="data-row align center">
                                        <img data-tip={`${pd.chainId}`} style={{marginLeft:7}} className="chain-icon" src={require(`../../icons/logo_chain_${pd.chainId}.png`)}/>
                                        
                                        { pd.dexId.length < 33 ? (
                                            <div data-tip={`${pd.dexId}`} style={{minWidth:22,height:22,marginTop:2,marginLeft:0}} className="svg-icon raw">
                                                <SwapIcon swap={pd.dexId}/>
                                            </div>
                                        ):(<p style={{marginTop:7}}>0x</p>)}
                                        
                                        { pd.baseToken.address.slice(-4) == "pump" ? (
                                            <img data-tip={`pump fun`} style={{marginLeft:7}} className="chain-icon" src={require(`../../icons/trending/logo_majorsolpf.png`)}/>
                                        ):null}
                                        
                                        
                                        {pd.labels !== undefined && pd.labels.length > 0 ? (
                                            <div style={{transform: "scale(.9)",marginTop:1}} className="data-value"><p className="data-label outline">{pd.labels[0]}</p></div>
                                        ):null}

                                    </div>
                                )}

                            
                                { token && tokenStyle == "featured" ? (
                                    <>
                                      
                                        <TokenToolbar addStyle={{marginLeft:7,marginTop:17}} tp={token}/>
                                    
                                    </>
                                ):null}

                               
                                


                            {/* { pd && (
                                <div style={{alignItems:'center',marginTop:7}} className="data-row"> */}

                                    {/*{ token ? (
                                        <div style={{maxWidth:33}} className="data-item">
                                            <div style={{lineHeight:'11px'}} className="data-title">score</div>
                                            <div className="data-value large">
                                              {!token.contractScore ? "0" : token.contractScore}
                                            </div>
                                        </div>
                                    ):null}*/}




                                {/* </div>
                            )} */}


                            </div>

                            {pd && pd.priceChange !== undefined && (

                                <div style={{ minWidth: 333, marginLeft: 11 }} className="data-column">




                                    <div style={{justifyContent: 'space-between'}} className="data-row tabs right bookend">
                                        {/*<div className="data-panel row">
                                            <div className="svg-icon">

                                            </div>
                                        </div>*/}


                                        {/*<RocketIcon/>*/}
                                        <div className="data-item">
                                            <div className="data-title">USD</div>
                                            <div className={classNames("price data-value", priceUp && "green")}>{parse(condenseZeros(formatCurrency(pd.priceUsd, "USD")))}</div>
                                        </div>

                                        <div className="data-item">
                                            <div className="data-title">fdv</div>
                                            { pd.fdv !== undefined && (
                                                <div className="data-value"><span className="small">$</span>{parse("" + formatCurrencyScale(pd.fdv))}</div>
                                            )}
                                        </div>


                                        <div className="data-item">
                                            <div className="data-title">liq</div>
                                                { pd.liquidity && pd.liquidity !== undefined && (
                                                    <>
                                                        {pd.liquidity.usd > 500 ? (
                                                            <div className="data-value">
                                                                <span className="small">$</span>{parse(formatCurrencyScale(pd.liquidity.usd))}
                                                            </div>
                                                        ): (
                                                            <div className="caution-tag" style={{marginTop: 4}}>
                                                                no liq : ${Math.round(pd.liquidity.usd)}
                                                            </div>
                                                        )}
                                                    </>
                                                )}


                                        </div>

                                        <div className="data-item">
                                            <div style={{minWidth:44}} className="data-title">24h vol</div>
                                            <div className="data-value"><span className="small">$</span>{parse(formatCurrencyScale(pd.volume.h24))}</div>
                                        </div>

                                    </div>

                                    <TokenDataTabs
                                        // addClass="active"
                                        title="price"
                                        bookend={true}
                                        values={[pd.priceChange.m5,
                                            pd.priceChange.h1,
                                            pd.priceChange.h6,
                                            pd.priceChange.h24]}
                                        valuesFormat={['percent','percent','percent','percent']}
                                        labels={['5m','1h','6h','24h']}
                                    />

                                </div>
                            )}
                        </div>



                     
                            <div style={{marginRight:22,minWidth:53}} className="data-column">

                                <div className="data-row">

                                    { token && token.pair?.priceChangedUpAt && token.pair?.priceChangedDownAt ? (
                                        <>
                                            { token.pair.priceChangedUpAt > token.pair.priceChangedDownAt ? (
                                            <p style={{margin:0,marginTop:-4,fontWeight: '600'}}><span style={{fontSize:14,fontWeight:'600'}} className="green">↑</span> {getTimeDisplay(token.pair.priceChangedUpAt)}<br/><span style={{fontSize:14,fontWeight:'600'}} className="red">↓</span> {getTimeDisplay(token.pair.priceChangedDownAt)}</p>
                                            ):(
                                            <p style={{margin:0,marginTop:-4,fontWeight: '600'}}><span style={{fontSize:14,fontWeight:'600'}} className="red">↓</span> {getTimeDisplay(token.pair.priceChangedDownAt)}<br/><span style={{fontSize:14,fontWeight:'600'}} className="green">↑</span> {getTimeDisplay(token.pair.priceChangedUpAt)} </p>
                                            )}
                                            {/*{getTimeDiff(now,token.pair.dataUpdatedAt)}*/}
                                        </>
                                    ): pair && pair.priceChangedUpAt && pair.priceChangedDownAt ? (

                                        <>
                                            { pair.priceChangedUpAt > pair.priceChangedDownAt ? (
                                            <p style={{margin:0,marginTop:-4,fontWeight: '600'}}><span style={{fontSize:14,fontWeight:'600'}} className="green">↑</span> {getTimeDisplay(pair.priceChangedUpAt)}<br/><span style={{fontSize:14,fontWeight:'600'}} className="red">↓</span> {getTimeDisplay(pair.priceChangedDownAt)}</p>
                                            ):(
                                            <p style={{margin:0,marginTop:-4,fontWeight: '600'}}><span style={{fontSize:14,fontWeight:'600'}} className="red">↓</span> {getTimeDisplay(pair.priceChangedDownAt)}<br/><span style={{fontSize:14,fontWeight:'600'}} className="green">↑</span> {getTimeDisplay(pair.priceChangedUpAt)} </p>
                                            )}
                                            {/*{getTimeDiff(now,pair.dataUpdatedAt)}*/}
                                        </>

                                    ):null}

                                
                                </div>

                            
                            </div>





                    </div>


    {/*

                    { tokenPair.tokenSniffer && tokenPair.tokenSniffer !== undefined && tokenPair.tokenSniffer.exploits !== undefined && (
                        <>

                            <p>{tokenPair.tokenSniffer.status}:{tokenPair.tokenSniffer.score}</p>
                            <p>{getTimeDisplay(tokenPair.tokenSnifferUpdatedAt)} ago</p>

                            {tokenPair.tokenSniffer.exploits.map((e, i) => (
                                <p style={{color:'red'}}>{e}</p>
                            ))}
                        </>
                    )}

                    <p>data {getTimeDisplay(tokenPair.dataUpdatedAt)} ago</p>

                    */}


                    {/*{token && (
                        <div style={{margin:'2px 11px',width: "calc( 100% - 14px )", padding: '11px 16px 6px 11px',alignItems:'flex-start' }} className="data-panel column">

                            <ContractAddressToolbar
                                label="owner"
                                type="address"
                                ca={`${token.owner}`}/>

                            <ContractAddressToolbar
                                label={`${token.symbol}`}
                                type="token"
                                ca={`${token.address}`}/>

                        </div>
                    )}*/}





                    {/*<div className="data-item" style={{marginTop:12,marginLeft: 12}}>
                        <div className="data-title">USD</div>
                        <div style={{color: "#ddd"}} className="price data-value large">{parse(condenseZeros(formatCurrency(pair.priceUsd, "USD")))}</div>
                    </div>*/}


                </div>

                {/*{ getTimeDiff(now,tokenPair.liquidityAt)<.5 && (
                    <div style={{position:'absolute',right:11,bottom:11, transform:'scale(.8)'}} className="new-pair-icon">
                        <div className="svg-icon">

                            <svg style={{fill:'green'}} width="800px" height="800px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" >
                                <path style={{fill:'green'}} d="M60.352 30.213c-.867-.744-8.626-7.27-14.026-7.27c-6.732 0-9.982 5.871-10.762 8.976l-.118.475a16.762 16.762 0 0 0-2.812 2.015a15.544 15.544 0 0 0-2.447 2.772c-.056-.267-.111-.534-.179-.792c-.794-2.901-2.068-5.467-3.441-7.961c-1.11-1.971-2.336-3.856-3.62-5.695l.063-.127c1.344-2.712 2.531-9.261-2.588-14.628C16.253 3.605 5.39 2.354 4.166 2.228L2 2l.213 2.236c.122 1.284 1.311 12.653 5.473 17.015c2.317 2.429 5.215 3.714 8.382 3.714c2.602 0 4.747-.862 5.703-1.32a68.516 68.516 0 0 1 3.157 5.697c1.19 2.469 2.271 5.043 2.816 7.614c.131.639-.77 25.044-.77 25.044h4.454s-.696-20.141-.573-20.625c.52-2.013 1.652-3.906 3.144-5.448a14.7 14.7 0 0 1 2.094-1.774c1.303 2.616 4.515 6.158 10.233 6.158c5.4 0 13.159-6.525 14.026-7.269L62 31.627l-1.648-1.414" fill="#000000">
                                </path>
                            </svg>

                        </div>

                    </div>
                )}*/}



                { tp && tp.pair && tp.pair !== undefined && tp.pair.isNewPair !== undefined && tp.pair.isNewPair && (
                    <div className="status-lights flex-row">
                        <StatusLight data-tip="renounced" status={tp.renouncedAt || tp.owner == blankAddress || tp.owner == deadAddress ? 1 : 0}/>
                        <StatusLight data-tip="honeypot" status={tp.pair.health?.isHoneypot ? 0 : 1}/>
                        <StatusLight data-tip="liquidity" status={hasLiquidity ? 1 : 0}/>

                        <StatusLight data-tip="liquidity lock" status={tp.pair.liquidityLockedAt ? 1 : 0}/>
                        <StatusLight data-tip="proxy check" status={tp.proxy && tp.proxy == "none" ? 1 : 0.5}/>


                        {/*<p>{tokenPair.health?.buyTax}/{tokenPair.health?.sellTax}</p>*/}
                        <StatusLight data-tip="tax" status={tp.pair.health?.sellTax < 5 && tp.pair.health?.buyTax < 5 ? 1 : tp.pair.health?.sellTax < 10 && tp.pair.health?.buyTax < 10 ? .5 : 0}/>
                        <StatusLight data-tip="renounced when launched" status={!tp.ownerOg && tp.renouncedAt ? 0 : 1}/>
                        {/*<StatusLight data-tip="assembly in contract" status={token.assemblyInContract ? 0 : 1}/>*/}
                        <StatusLight data-tip="verified" status={tp.verified ? 1 : 0}/>

                    </div>
                )}


            </div>


            <div onClick={handleClick} style={{pointerEvents: walletAddress ? "all" : "all",cursor:'pointer',alignItems:'flex-end'}} className={classNames("flex-row justify space-between dex-pair-balance", withSpace && 'space', tokenStyle == "featured" && "featured")}>
              
                { pd && balancesAlchemy[pd.baseToken.address] !== undefined && token && token.pair && ( Number(balancesAlchemy[pd.baseToken.address].balance) > 0 || view=="portfolio" ) &&  (
                    <div style={{
                        // borderBottomRightRadius: 5,
                        // borderBottomLeftRadius: 5,
                        width:'100%',
                        padding: '14px 9px 14px 14px',
                        maxHeight: 'initial'
                    }} className="token-balance-dex data-panel small">

                        <div style={{justifyContent:'flex-start'}} className="data-item row full">

                            <div style={{textAlign:'left'}} className="data-value currency">
                                <p className="token-balance">
                                
                                    {tb > 100 ? (
                                        <>
                                            {parse(formatCurrencyScale(parseInt(Number(tb))))}
                                        </>
                                    ):(
                                        <>
                                            {tb.toFixed(2)}
                                        </>
                                    )}
                                    <span style={{marginLeft:7}} className="small">{isEth ? "ETH" : pd.baseToken.symbol}</span>

                                    {((token && token.totalSupply && percentSupplyOwned > .01) || (pair && pair.token?.totalSupply && percentSupplyOwned > .01) ) && (
                                        <span style={{fontSize:'.73em',marginLeft:11}} className="small">{percentSupplyOwned}% &nbsp;</span>
                                    )}
                                    {/*{parse(' ' + formatCurrencyScale(parseInt(Number(tb))))} <span className="small">{token.data.baseToken.symbol}</span>*/}

                                </p>
                                {/* <p>{percentSupplyOwned}</p> */}

                                <div style={{marginTop:4,justifyContent:"flex-start"}} className="flex-row justify">
                                    <p style={{fontSize:'.88rem'}} className={classNames("usd-balance", token.pair.chainId == "solana" && "solana no-prefix")}>
                                        {isEth ? (
                                            <>
                                                Ξ{tb.toFixed(2)}
                                            </>
                                        ): token.pair.chainId == "ethereum" || token.pair.chainId == "base" ? (
                                            <>
                                                {parse(condenseZeros(formatCurrency(ethValue > 1 ? (ethValue).toFixed(3) : (ethValue).toFixed(4), "ETH")))}
                                            </>
                                        ): token.pair.chainId == "solana" ? (
                                            <>
                                                <img style={{marginTop:-1,filter:'grayscale(0)',transform:'scale(.88)',opacity:.8}} className="chain-icon" src={require(`../../icons/logo_chain_solana.png`)}/>
                                                {parse(condenseZeros(formatNumberScale(solValue)))}
                                            </>
                                        ):null}

                                    </p>
                                    <p style={{fontSize:11,opacity:.4,marginLeft:17}} >::</p>
                                    <p style={{marginLeft:17,fontSize:'.88rem'}} className="usd-balance">
                                        { token.pair.chainId == "solana" ? (
                                            <>
                                                {parse(formatCurrency((solValue*solPrice).toFixed(2), "USD"))}
                                            </>
                                        ):(
                                            <>
                                                {parse(formatCurrency((ethValue*ethPrice).toFixed(2), "USD"))}
                                            </>
                                        )}
                                        
                                    </p>
                                    {/*<p style={{marginLeft:17,fontSize:'1rem'}} className="usd-balance small"> {parse(formatCurrency((tb*token.data.priceUsd).toFixed(2), "USD"))}</p>*/}


                                </div>
                                {/*<div style={{marginTop:4,justifyContent:"flex-end"}} className="flex-row justify">
                                    <p style={{marginRight:11}} className="usd-balance small"> {parse(condenseZeros(formatCurrency((tb*token.data.priceUsd), "USD")))}</p>
                                    <p className="usd-balance small">{parse(condenseZeros(formatCurrency((ethValue).toFixed(4), "ETH")))}</p>

                                </div>*/}
                            </div>
                        </div>

                    </div>
                )}

            </div>

        </>
    );
    
    if(draggableProps && walletAddress){
        return (
            <div
                ref={innerRef}
                {...draggableProps}
                
            >
                {content}
            </div>
        )
    }else{
        return content;

    }
    
}
