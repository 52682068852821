import React, { createContext, useEffect, useMemo, useRef } from 'react';
import EventEmitter from 'eventemitter3';

import { useCore } from "../../hooks/useCore";

export const SocketContext = createContext();

export default function SocketProvider({ client, uri, ...props }) {
  const socketRef = useRef();
  const currentNamespace = useRef();
  const eventEmitterRef = useRef(new EventEmitter());
  const queuedEvents = useRef([]);

  const { inApp } = useCore();

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  const value = useMemo(() => {

    return {
      init: (namespace) => {

        // console.log(currentNamespace.current,namespace);

        if (currentNamespace.current === namespace && socketRef.current) {
          // console.log('resolving??');
          return Promise.resolve();
        }
        //
        // console.log('socket init lets go', uri, namespace)

        return new Promise((resolve, reject) => {
          const socketu = `${uri}/${namespace || ''}`;
          let su = uri;
          
          const socket = client(socketu, {
            transports: ['websocket','polling'],
            withCredentials: true,
            reconnection: true,
            reconnectionAttempts: 5, // Limit reconnection attempts
            reconnectionDelay: 2000, // Delay between reconnections
            // forceNew: true
          });
          // const socket = client(`${uri}/${namespace || ''}`);
          currentNamespace.current = namespace;
          socketRef.current = socket;


          socket.on('dextunnel', (msg) => {
              eventEmitterRef.current.emit(msg.type, msg);
          });

          socket.on('connect', () => {
            // console.log('🧠🧠🧠🧠🧠🧠🧠 connect');
            resolve();

            // eventEmitterRef.current.emit("RECONNECT_ROOMS", {});

            // queuedEvents.current.forEach((evt) => {
            //   socket.emit(...evt);
            // });
            // queuedEvents.current = [];
          });

         
          socket.on('connect_error', (err) => {
            // console.log('🧠 error connect');
            console.log(err.message);
          
            // some additional description, for example the status code of the initial HTTP response
            console.log(err.description);
          
            // some additional context, for example the XMLHttpRequest object
            console.log(err.context);
            // reject();
          });

          socket.on('reconnect', () => {
            // console.log('🧠 reconnect');
              // console.log('reconnect')
          });

          socket.on('disconnect', (e) => {
            // console.log('🧠 disconnect');
              // console.log('disconnect?!')
          });

          socket.on('session', ({ sessionId }) => {
            // console.log('🧠 session');
              socket.auth = { sessionId };
          });
        });
      },

      on: (...args) => {
        eventEmitterRef.current.on(...args);
      },

      off: (...args) => {
        eventEmitterRef.current.off(...args);
      },

      emit: (...args) => {
        if (!socketRef.current) {
          queuedEvents.current.push(args);
          return;
        }
        socketRef.current.emit(...args);
      },
    };



}, [client, uri, inApp]);

  return <SocketContext.Provider value={value} {...props} />;
}
