import {
  http,
  createConfig,
  useAccount,
  configureChains,
  createClient,
  goerli,
  WagmiConfig
} from 'wagmi';



import { client } from "../services/api";

import { walletSignMessage } from './data';

import { useWalletAddress } from './tools';


import {
  injected,
  // coinbaseWallet
} from 'wagmi/connectors'

import '@rainbow-me/rainbowkit/styles.css';
import {
    createAuthenticationAdapter,
    connectorsForWallets
} from '@rainbow-me/rainbowkit';

import {
  walletConnectWallet,
  coinbaseWallet,
  phantomWallet,
  ledgerWallet,
  metaMaskWallet,
  injectedWallet,
  okxWallet,
  trustWallet,
  uniswapWallet,
  gateWallet,
  // binanceWallet,
  braveWallet
} from '@rainbow-me/rainbowkit/wallets';


import {
  getDefaultConfig
} from '@rainbow-me/rainbowkit';

import {
  mainnet,
  base,
  // polygon,
  // avalanche,
  // bsc,
  // arbitrum,
} from 'wagmi/chains';

// coinbaseWallet.preference = 'smartWalletOnly';
coinbaseWallet.appLogoUrl = 'https://www.0xdex.ai/logo192.png';
const connectors = connectorsForWallets(
    [
      {
        groupName: 'Recommended',
        // wallets: [
        //   metaMaskWallet({ chains }),
        //   coinbaseWallet({ chains, appName: '0xdex' }),
        //   phantomWallet({ chains }),
        // ],
        wallets: [
            // metaMaskWallet,
            coinbaseWallet,
            
            phantomWallet,
            walletConnectWallet,
            trustWallet,
            uniswapWallet,
            // binanceWallet,
            // braveWallet,
            okxWallet,
            ledgerWallet,
            injectedWallet,
            // gateWallet,
            // rainbowWallet,
        ],
      },
    ],
    {
      appName: '0xdex',
      projectId: process.env.REACT_APP_REOWN_PROJECT_ID,
      appIcon: 'https://www.0xdex.ai/logo192.png',
      appUrl: 'https://app.0xdex.ai',
      appDescription: "0xdex is a collaborative dashboard for signals, news, analyzing and transacting in Decentralized Finance (DeFi)."
    }
);

export const wagmiConfig = createConfig({
  connectors,
  multiInjectedProviderDiscovery: true,
  chains: [
    mainnet,
    base,
    // polygon,
    // avalanche,
    // bsc,
    // arbitrum
  ],
  transports: {
    [mainnet.id]: http(),
    [base.id]: http(),
    // [polygon.id]: http(),
    // [avalanche.id]: http(),
    // [bsc.id]: http(),
    // [arbitrum.id]: http(),
  },
});

export const wagmiConfigLean = createConfig({
  chains: [mainnet, base],
  transports: {
    [mainnet.id]: http(),
    [base.id]: http(),
  },
});

// export const wagmiConfig = getDefaultConfig({
//   appName: '0xdex',
//   projectId: process.env.REACT_APP_REOWN_PROJECT_ID,
//   chains: [mainnet],
//   wallets: [
//     metaMaskWallet,
//     phantomWallet
//   ],
//   transports: {
//     [mainnet.id]: http(),
//   },
// })




export const authAdapter = {

    getNonce: async () => {
       
        const response = await client.getNonce();
        console.log(response)
        
        const { nonce } = await response.data;
        console.log('Nonce received:', nonce);
        return nonce;
    },
  
    createMessage: ({ nonce, address }) => {
      console.log('Creating message with nonce:', nonce);

      let me = walletSignMessage.replace('[wa]',address).replace('[nonce]', nonce);
      return me;
    },
  
    verify: async ({ message, signature, wa }) => {
        console.log('Verifying message and signature...');

        let nd = {
            message,
            signature,
            wa
        }

        let signv = await client.validateSign(nd);
       
        if(signv.ok && signv.data?.success){
            
            return signv;
        }else{
            return false;
        }

    },
};
  
  


export const rainbowConfig = getDefaultConfig({
    appName: '0xdex',
    projectId: process.env.REACT_APP_REOWN_PROJECT_ID,
    chains: [mainnet, base],
    ssr: true, // If your dApp uses server side rendering (SSR)
});