import React from "react";

export function LoaderSpinner({ token }) {

    return (
        <div className="loader-spinner">

			<div className="loader-spinner-element">

			</div>


        </div>
    );
}
